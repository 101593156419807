import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

function ReadExpenses() {

    const [carlist, setCarList] = useState([])
    const [date, setDate] = useState('')
    // const [customerList, setCustomerList] = useState([])
    const [customerId, setCustomerId] = useState("")
    const [ID, setID] = useState("")
    // const [newUsed, setnewUsed] = useState("")
    const [newNote, setnewNote] = useState("")
    const [newUsd, setnewUsd] = useState("")
    const [newIqd, setnewIqd] = useState("")
    const [fdate, setfdate] = useState("")
    const [ldate, setldate] = useState("")


    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/allcustomer").then((response) => {
    //         setCustomerList(response.data)
    //     });
    // }, []);

    const filterDate = () => {

        let newArr = []
        newArr = carlist.filter(e => (e.datePicked >= fdate && e.datePicked <= ldate))
        setCarList(newArr)
    }



    useEffect(() => {
        axios.get("https://car-api.redcode.app/expense/allexpenses/").then((response) => {
            setCarList(response.data)

        })

    }, [])



    const [option, setOptions] = useState([]);
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const filterRows = () => {

        if (date !== "" || customerId === "") {
            axios.get(`https://car-api.redcode.app/expense/dateselect/${date}/`).then((response) => {
                setCarList(response.data)
            })
        }
        if (date === "" || customerId !== "") {
            axios.get(`https://car-api.redcode.app/expense/usedfor/${customerId}/`).then((response) => {
                setCarList(response.data)
            })
        }
        if (date !== "" || customerId !== "") {
            axios.get(`https://car-api.redcode.app/expense/bothselect/${customerId}/${date}/`).then((response) => {
                setCarList(response.data)
            })
        }
        else if (date === "" || customerId === "") {
            alert("تکایە کات یاخود جۆر هەڵبژێرە")
        }
        // axios.delete(`https://car-api.redcode.app/deletecustomer/${e.customerId}`)
        console.log(date, customerId)
    }


    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/expense/updateexpense/", {
            // newUsed: newUsed,
            newNote: newNote,
            newUsd: newUsd,
            newIqd: newIqd,
            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }

    const updateExpens = (id) => {
        // if (newUsed === "") {
        //     carlist.filter(e => e.ID === ID).map(e => (setnewUsed(e.OPvalue)))
        // }
        if (newNote === "") {
            carlist.filter(e => e.ID === ID).map(e => (setnewNote(e.description)))
        }
        if (newUsd === "") {
            carlist.filter(e => e.ID === ID).map(e => (setnewUsd(e.usd)))
        }
        if (newIqd === "") {
            carlist.filter(e => e.ID === ID).map(e => (setnewIqd(e.iqd)))
        }

    }



    return (
        <div >
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h4 className="mb-1">خەرجی</h4>
                        <div style={{ display: "flex", flexDirection: "row" }}>


                            <div className="col-md-8">
                                <label htmlFor="address" className="form-label"> کات</label>
                                <input type="date" onChange={e => setfdate(e.target.value)} className="form-control" id="address" required />
                            </div>
                            <div className="col-md-2" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                                <h3>بۆ</h3>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => setldate(e.target.value)} className="form-control" id="address" required />
                            </div>

                            <div style={{ width: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "2vw" }}>
                                <button type="button" onClick={filterDate} className="btn btn-dark">فلتەر</button>

                            </div>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>


                            <div className="col-md-5">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => setDate(e.target.value)} className="form-control" id="address" placeholder="1234 الشارع الأول" required />
                            </div>
                            <div className="">
                                <label htmlFor="address" className="form-label">بەکارهاتووە بۆ</label>
                                <div >
                                    <select onChange={e => setCustomerId(e.target.value)} required>
                                        <option value="">تکایە هەڵبژێرە</option>
                                        {option.filter(e => e.OPkey === 4).map((e) => {
                                            return <option value={e.ID}>{e.OPvalue}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div style={{ width: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "2vw" }}>
                                <button type="button" onClick={filterRows} className="btn btn-dark">فلتەر</button>
                            </div>

                        </div>
                    </div>

                    <table className="table table-striped print-container">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">بەکارهاتووە بۆ</th>
                                <th scope="col">تێبینی</th>
                                <th scope="col">نرخ دۆلار</th>
                                <th scope="col">نرخ دینار</th>
                                <th scope="col">کات</th>
                                <th scope="col" className="notPrint"></th>

                            </tr>
                        </thead>
                        <tbody>
                            {carlist.map(e => {
                                return <tr key={e.ID}>
                                    <th scope="row">{e.ID}</th>
                                    <td>{e.OPvalue}</td>
                                    <td>{e.description}</td>
                                    <td>{e.usd}</td>
                                    <td>{e.iqd}</td>
                                    <td>{e.datePicked}</td>
                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.ID)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.ID)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">خەرجی دەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            خەرجی ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/expense/deleteexpense/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            })}

                        </tbody>

                    </table>

                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}
                        <div className="row">

                            <h6 className="">کۆی دۆلار </h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {carlist.reduce((a, v) => a = a + v.usd, 0)}</span>
                        </div>


                        <div className="row">


                            <h6 className="">کۆی دینار</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className=""> {carlist.reduce((a, v) => a = a + v.iqd, 0)}</span>
                        </div>


                        {/* <div className="row">

                            <h6 className="">کۆی گشتی دینار</h6>
                            <small>دينار</small>

                            <span className="text-success">
                                {localStorage.getItem("webinter") === "1" ? (customer.reduce((a, v) => a = a + v.priceUSD + v.coc + v.naql, 0) * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"}
                            </span>
                        </div>

                        <div className="row">
                            <span>مجموع (USD)</span>
                            <strong>$20</strong>
                        </div> */}


                        {/* <form className="card p-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="رمز ترويجي" />
                                        <button type="submit" className="btn btn-secondary">تحقق</button>
                                    </div>
                                </form> */}
                    </div>


                    <button type="button" onClick={() => window.print()} className="btn btn-secondary">print</button>

                    {/* <div className="col-md-5 col-lg-4 order-md-last">

                        <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Price Dollar</h6>
                                    <small className="text-muted">وصف مختصر</small>
                                </div>
                                <span className="text-muted">{carlist.reduce((a, v) => a = a + v.priceUSD, 0)}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Price Dinar</h6>
                                    <small className="text-muted"></small>
                                </div>
                                <span className="text-muted">{carlist.reduce((a, v) => a = a + v.priceIQD, 0)}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">البند الثالث</h6>
                                    <small className="text-muted">وصف مختصر</small>
                                </div>
                                <span className="text-muted">$5</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between bg-light">
                                <div className="text-success">
                                    <h6 className="my-0">رمز ترويجي</h6>
                                    <small>EXAMPLECODE</small>
                                </div>
                                <span className="text-success">-$5</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span>مجموع (USD)</span>
                                <strong>$20</strong>
                            </li>
                        </ul>

                        <form className="card p-2">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="رمز ترويجي" />
                            </div>
                        </form>
                    </div> */}




                    <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>خەرجی تازە کردنەوە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        {carlist.filter(e => e.ID === ID).map(e =>
                                        (
                                            <div key={e.ID}>
                                                <form>
                                                    {/* <div className="">
                                                        <label htmlFor="address" className="form-label">بەکارهاتووە بۆ</label>
                                                        <div >
                                                            <select onChange={e => setnewUsed(e.target.value)} required>
                                                                <option value={e.OPkey}> {e.OPvalue}</option>
                                                                {carlist.map((e) => {
                                                                    return <option key={e.ID} value={e.OPvalue}>{e.OPvalue}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.description} onChange={e => setnewNote(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">نرخ دۆلار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.usd} onChange={e => setnewUsd(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">نرخ دینار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.iqd} onChange={e => setnewIqd(e.target.value)} />
                                                    </div>

                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateExpens} >تازە کردنەوە</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                </div>
                                <div className="modal-body">
                                    {carlist.filter(e => e.ID === ID).map(e =>
                                    (
                                        <div key={e.ID}>
                                            <form style={{ color: "black" }}>
                                                {/* <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">بەکارهاتووە بۆ</label>
                                                    <h2>{newUsed}</h2>

                                                </div> */}
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                    <h2>{newNote}</h2>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">نرخ دۆلار</label>
                                                    <h2>{newUsd}</h2>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">نرخ دینار</label>
                                                    <h2>{newIqd}</h2>
                                                </div>

                                            </form>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default ReadExpenses




















