import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { GrDocumentDownload } from "react-icons/gr";
import { Link } from 'react-router-dom'


function Readwakalat() {
    const [filterSearch, setFilterSearch] = useState("")
    const [filtertemp, setFiltertemp] = useState("")
    const [filternum, setFilternum] = useState("")
    const [carlist, setCarList] = useState([])
    // const [date, setDate] = useState('')
    // const [customerList, setCustomerList] = useState([])
    // const [customerId, setCustomerId] = useState("")
    const [ID, setID] = useState("")
    // const [pays, setpays] = useState([1]);
    const [option, setOptions] = useState([]);

    const [carPick, setCarPick] = useState("");
    // const [waslnum, setwaslnum] = useState("");
    const [enginePick, setenginePick] = useState("");
    const [vinNumber, setvinNumber] = useState("");
    const [tempNum, settempNum] = useState("");
    const [qazanj, setqazanj] = useState("");
    const [coc, setcoc] = useState("");

    const [baqi, setbaqi] = useState("");
    const [datePick, setDatePick] = useState("");
    const [priceUS, setpriceUS] = useState("");

    const [customerID, setCustomerID] = useState("");
    const [customerphone, setCustomerphone] = useState("");
    const [customerloc, setCustomerloc] = useState("");
    const [colorPick, setcolorPick] = useState("");
    const [modelPick, setmodelPick] = useState("");
    const [makinaPick, setmakinaPick] = useState("");
    const [gwmrgPick, setgwmrgPick] = useState("");
    const [gwmrgNum, setgwmrgNum] = useState("");
    const [gwmrgDate, setgwmrgDate] = useState("");



    // const [RAND, setRAND] = useState("");
    const [newmoney, setnewmoney] = useState("");
    // const [madfw3, setmadfw3] = useState("");
    const [gumrgPick, setgumrgPick] = useState([1, 2]);

    const [paydate, setpaydate] = useState("");

    const [paylist, setpaylist] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const [trans, settrans] = useState([]);

    useEffect(() => {
        axios.get("https://car-api.redcode.app/trans/getwakala/").then((response) => {
            settrans(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const updateQabz = (id) => {
        if (carPick === "") {
            carlist.filter(e => e.id === ID).map(e => (setCarPick(e.carPick)))
        }
        if (baqi === "") {
            carlist.filter(e => e.id === ID).map(e => (setbaqi(e.baqi)))
        }
        if (enginePick === "") {
            carlist.filter(e => e.id === ID).map(e => (setenginePick(e.wakalattype)))
        }
        if (priceUS === "") {
            carlist.filter(e => e.id === ID).map(e => (setpriceUS(e.totalall)))
        }
        if (datePick === "") {
            carlist.filter(e => e.id === ID).map(e => (setDatePick(e.datePick)))
        }
        if (vinNumber === "") {
            carlist.filter(e => e.id === ID).map(e => (setvinNumber(e.vinNum)))
        }
        if (tempNum === "") {
            carlist.filter(e => e.id === ID).map(e => (settempNum(e.carNum)))
        }
        if (qazanj === "") {
            carlist.filter(e => e.id === ID).map(e => (setqazanj(e.tebini)))
        }

        if (coc === "") {
            carlist.filter(e => e.id === ID).map(e => (setcoc(e.madfw3)))
        }
        if (customerID === "") {
            carlist.filter(e => e.id === ID).map(e => (setCustomerID(e.cname)))
        }
        if (customerphone === "") {
            carlist.filter(e => e.id === ID).map(e => (setCustomerphone(e.cphone)))
        }
        if (customerloc === "") {
            carlist.filter(e => e.id === ID).map(e => (setCustomerloc(e.cloc)))
        }
        if (colorPick === "") {
            carlist.filter(e => e.id === ID).map(e => (setcolorPick(e.colorPick)))
        }
        if (modelPick === "") {
            carlist.filter(e => e.id === ID).map(e => (setmodelPick(e.modelPick)))
        }
        if (makinaPick === "") {
            carlist.filter(e => e.id === ID).map(e => (setmakinaPick(e.enginePick)))
        }
        if (gwmrgPick === "") {
            carlist.filter(e => e.id === ID).map(e => (setgwmrgPick(e.gwmrgPick)))
        }
        if (gwmrgNum === "") {
            carlist.filter(e => e.id === ID).map(e => (setgwmrgNum(e.gwmrgNum)))
        }
        if (gwmrgDate === "") {
            carlist.filter(e => e.id === ID).map(e => (setgwmrgDate(e.gwmrgDate)))
        }

    }

    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/wakalat/updatewakalat/", {
            carPick: carPick,

            enginePick: enginePick,
            priceUS: priceUS,
            datePick: datePick,
            vinNumber: vinNumber,
            tempNum: tempNum,
            tebini: qazanj,
            baqi: baqi,
            madfw3: coc,

            customerID: customerID,
            customerphone: customerphone,
            customerloc: customerloc,
            colorPick: colorPick,
            modelPick: modelPick,
            makinaPick: makinaPick,
            gwmrgPick: gwmrgPick,
            gwmrgNum: gwmrgNum,
            gwmrgDate: gwmrgDate,

            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }




    useEffect(() => {
        axios.get("https://car-api.redcode.app/wakalat/allpshtgiri/").then((response) => {
            setCarList(response.data)



        })

    }, [])



    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "5px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h4 className="mb-1">ڕاپۆرت پشتگیری هاتووچۆ</h4>




                        <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label "> گەڕان بەدوای ناو</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای مەنەفێست </label>
                            <input type="text" className="form-control" onChange={e => setFiltertemp(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای ژمارە سەیارە </label>
                            <input type="text" className="form-control" onChange={e => setFilternum(e.target.value)} id="cc-number" placeholder="" />
                        </div>

                    </div>

                    <table className="table table-striped print-container">
                        <thead>
                            <tr>
                                <th scope="col">#</th>


                                <th scope="col">ناو</th>

                                <th scope="col">ژمارە تەلەفۆن</th>

                                <th scope="col">ناونیشان</th>
                                <th scope="col">جۆری سەیارە</th>
                                <th scope="col">مۆدێل</th>
                                <th scope="col">پستۆن</th>
                                <th scope="col">ڕەنگ</th>

                                <th scope="col">ژمارە سەیارە</th>
                                <th scope="col">رقم شانصی</th>
                                <th scope="col">گومرگ</th>
                                <th scope="col">ژمارە گومرگ</th>
                                <th scope="col">كات گومرگ</th>

                                <th scope="col"> تێبینی</th>

                                <th scope="col">کۆی دۆلار</th>

                                <th scope="col">کاتی دەرچوون</th>
                                <th scope="col" className="notPrint"></th>
                                <th scope="col" className="notPrint"></th>

                                <th scope="col" className="notPrint">دۆکیومێنت</th>

                                {/* <th scope="col" className="notPrint"></th>
                                <th scope="col" className="notPrint">دۆکیومێنت</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {carlist.filter((e => {
                                if (filterSearch === "" && filtertemp === "" && filternum === "") {
                                    return e
                                } if (e.cname.includes(filterSearch) && filtertemp === "") {
                                    return e
                                } if (e.vinNum.includes(filtertemp) && filterSearch === "") {
                                    return e
                                } if (e.carNum.includes(filternum) && filterSearch === "" && filtertemp === "") {
                                    return e
                                }
                                if (e.vinNum.includes(filtertemp) && e.cname.includes(filterSearch) && e.carNum.includes(filternum)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr className={

                                    trans.filter((eve => {
                                        if (eve.RANDID === "") {
                                            return 0
                                        } else if (eve.RANDID.includes(e.id)) {
                                            return eve
                                        }
                                    })).reduce((a, v) => a = a + v.amount, 0) === e.totalall ? "table-success" : trans.filter((eve => {
                                        if (eve.RANDID === "") {
                                            return 0
                                        } else if (eve.RANDID.includes(e.id)) {
                                            return eve
                                        }
                                    })).reduce((a, v) => a = a + v.amount, 0) > 0 ? "table-warning" : "table-danger"

                                }>
                                    <th scope="row">{e.id}</th>

                                    <td>{e.cname}</td>

                                    <td>{e.cphone}</td>
                                    <td>{e.cloc}</td>
                                    <td>{e.carPick}</td>
                                    <td>{e.modelPick}</td>
                                    <td>{e.enginePick}</td>
                                    <td>{e.colorPick}</td>

                                    <td>{e.carNum}</td>
                                    <td>{e.vinNum}</td>
                                    <td>{e.gwmrgPick}</td>
                                    <td>{e.gwmrgNum}</td>
                                    <td>{e.gwmrgDate}</td>

                                    <td>{e.tebini}</td>
                                    <td>{e.totalall}</td>


                                    <td>{e.datePick}</td>

                                    <td className="notPrint">
                                        {/* // {e.madfw3 === e.totalall ? null : */}
                                        <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                            onClick={() =>
                                                axios.get(`https://car-api.redcode.app/wakalat/payselect/${e.id}/`).then((response) => {
                                                    setgumrgPick(response.data)
                                                })
                                            }>پارە بدە</button>


                                    </td>
                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">پشتگیری هاتووچۆ دەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            پشتگیری هاتووچۆ ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/wakalat/deletewakalat/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    <td className="notPrint" style={{ textAlign: "center" }}>

                                        <Link to={{
                                            pathname: "/printdoc",
                                            state: {
                                                datas: e
                                            }
                                        }}>
                                            <GrDocumentDownload />
                                        </Link>

                                        {/* <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.ID)} >
                                            <GrDocumentDownload />
                                        </button> */}

                                    </td>


                                </tr>
                            })}

                        </tbody>
                    </table>



                    <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پشتگیری هاتووچۆ تازە کردنەوە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        {carlist.filter(e => e.id === ID).map(e =>
                                        (
                                            <div key={e.id}>
                                                <form>
                                                    <td>
                                                        <select name="car" onChange={e => setenginePick(e.target.value)} >
                                                            <option value="">جۆری پشتگیری بگۆڕە</option>
                                                            {option.filter(e => e.OPkey === 7).map((e) => {
                                                                return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                            })}
                                                        </select>
                                                    </td>


                                                    <td>
                                                        <select name="car" onChange={e => setmakinaPick(e.target.value)} >
                                                            <option value="">پستۆن</option>
                                                            {option.filter(e => e.OPkey === 6).map((e) => {
                                                                return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                    <br />
                                                    <td>
                                                        <select name="car" onChange={e => setmodelPick(e.target.value)} >
                                                            <option value="">مۆدێل</option>
                                                            {option.filter(e => e.OPkey === 3).map((e) => {
                                                                return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                            }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <select name="car" onChange={e => setgwmrgPick(e.target.value)} >
                                                            <option value="">گومرگ</option>
                                                            {option.filter(e => e.OPkey === 5).map((e) => {
                                                                return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                            })}
                                                        </select>
                                                    </td>



                                                    {/* <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1"> ژمارەی وەصڵ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.waslID} onChange={e => setwaslnum(e.target.value)} />
                                                    </div> */}


                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ناوی کڕیار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.cname} onChange={e => setCustomerID(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ژمارە مۆبایل</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.cphone} onChange={e => setCustomerphone(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ناونیشان</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.cloc} onChange={e => setCustomerloc(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            جۆری سەیارە</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.carPick} onChange={e => setCarPick(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ڕەنگ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.colorPick} onChange={e => setcolorPick(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ژمارە شانصی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.vinNum} onChange={e => setvinNumber(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ژمارە سەیارە</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.carNum} onChange={e => settempNum(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ژمارە گومرگ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.gwmrgNum} onChange={e => setgwmrgNum(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            كات گومرگ</label>
                                                        <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.gwmrgDate} onChange={e => setgwmrgDate(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            تێبینی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => setqazanj(e.target.value)} />
                                                    </div>


                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.totalall} onChange={e => setpriceUS(e.target.value)} />
                                                    </div>


                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کاتی دەرچوون</label>
                                                        <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => setDatePick(e.target.value)} />
                                                    </div>




                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                </div>
                                <div className="modal-body">
                                    {carlist.filter(e => e.id === ID).map(e =>
                                    (
                                        <div key={e.id}>
                                            <form style={{ color: "black" }}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">جۆری پشتگیری </label>
                                                    <h2>{enginePick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ناو</label>
                                                    <h2>{customerID}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ژمارە تەلەفۆن</label>
                                                    <h2>{customerphone}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ناونیشان</label>
                                                    <h2>{customerloc}</h2>                                                        </div>




                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">جۆری سەیارە</label>
                                                    <h2>{carPick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">مۆدێل</label>
                                                    <h2>{modelPick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">پستۆن</label>
                                                    <h2>{makinaPick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ڕەنگ</label>
                                                    <h2>{colorPick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">
                                                        ژمارە شانصی</label>
                                                    <h2>{vinNumber}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1"> ژمارە سەیارە</label>
                                                    <h2>{tempNum}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">گومرگ</label>
                                                    <h2>{gwmrgPick}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ژمارە گومرگ</label>
                                                    <h2>{gwmrgNum}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">كات گومرگ</label>
                                                    <h2>{gwmrgDate}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                    <h2>{qazanj}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                    <h2>{priceUS}</h2>                                                        </div>

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">کاتی دەرچوون</label>
                                                    <h2>{datePick}</h2>                                                        </div>





                                            </form>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="modal fade" id="staticBackdroppay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            {/* <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label> */}

                                            {/* <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label> */}
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                                $  {gumrgPick.map(e => e.aa)}
                                            </label>
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                                $ {gumrgPick.map(e => e.bb)}
                                            </label>



                                        </div>
                                        <hr className="my-4" />

                                        <div >
                                            <form>


                                                {/* {(newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null)} <p></p>
                                                        {(pays !== [1] ? pays[0].paraLeft : null) - (newmoney)
                                                        } */}

                                                <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>

                                                    <div className='pb-3' style={{ flex: "2", display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> بڕی پارە</label>

                                                        <input style={{ width: "80%" }} type="number"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> کات</label>

                                                        <input style={{ width: "80%" }} type="date"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setpaydate(e.target.value)} />



                                                    </div>
                                                    <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                        onClick={() => {
                                                            axios.post("https://car-api.redcode.app/trans/gwmrg/", {
                                                                transtype: 5,
                                                                amount: newmoney,
                                                                RANDID: gumrgPick.map(e => e.id),
                                                                datePick: paydate

                                                            }).then((res) => {
                                                                window.location.reload(false)
                                                            })
                                                        }}

                                                    >پارە بدە</button>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + (newmoney !== "" ? JSON.parse(gumrgPick.map(e => e.bb)) : null)}
                                                </h5>
                                                <h5>پارەی ماوە تازە  :   {gumrgPick.map(e => e.aa) - gumrgPick.map(e => e.bb) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    <button type='button' class="btn btn-success" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" onClick={() => axios.get(`https://car-api.redcode.app/trans/wakalatlist/${gumrgPick.map(e => e.id)}/`).then((response) => {
                                                        setpaylist(response.data)
                                                    })}>لیستی پارەدان ببینە</button>




                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalToggleLabel2">لیستی پارەدان </h5>

                                </div>
                                <div class="modal-body">
                                    <table className="table table-striped print-container">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>

                                                <th scope="col">کۆی گشتی دۆلار</th>

                                                <th scope="col">کات</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paylist.map(e => {
                                                return <tr key={e.id}>
                                                    <th scope="row"></th>
                                                    <td>{e.amount} $</td>


                                                    <td>{e.datePick}</td>
                                                    <td className="notPrint"><button type="button" className="btn btn-danger btn-sm p-1 " data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={() => setID(e.id)}><AiFillDelete /></button>


                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>

                                    </table>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" data-bs-target="#staticBackdroppayall" data-bs-toggle="modal">گەڕانەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalToggleLabel23" aria-hidden="true">
                        <div className="modal-dialog modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalToggleLabel23">بڕی پارەدان دەسڕیتەوە؟</h5>

                                </div>
                                <div className="modal-body">
                                    <h5>

                                        بڕی پارەدان  دەسڕێتەوە
                                    </h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/trans/deletetrans/${ID}`).then((res) => {
                                        window.location.reload(false)
                                    })}
                                    >سڕینەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}


                        <div className="row">

                            <h6 className="">کۆی گشتی مدفوع</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {trans.reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی گشتی ماوە</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$
                                {carlist.reduce((a, v) => a = a + v.totalall, 0) - trans.reduce((a, v) => a = a + v.amount, 0)}
                            </span>
                        </div>

                        <div className="row">

                            <h6 className="">کۆی دۆلار </h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {carlist.reduce((a, v) => a = a + v.totalall, 0)}</span>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Readwakalat




