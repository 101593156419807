import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
// import { GrDocumentDownload } from "react-icons/gr";


function Readcoc() {

    const [filterSearch, setFilterSearch] = useState("")
    const [filtertemp, setFiltertemp] = useState("")
    const [customer, SetCustomer] = useState([])
    const [ID, setID] = useState("")

    const [carPick, setCarPick] = useState("");

    const [daf3date, setdaf3date] = useState("");



    const [driver, setdriver] = useState("");


    const [coc, setcoc] = useState("");


    // const [pays, setpays] = useState([1]);
    // const [RAND, setRAND] = useState("");
    const [newmoney, setnewmoney] = useState("");

    const [trans, settrans] = useState([]);
    const [paydate, setpaydate] = useState("");



    const updateQabz = (id) => {
        if (carPick === "") {
            customer.filter(e => e.id === ID).map(e => (setCarPick(e.carqty)))
        }

        if (daf3date === "") {
            customer.filter(e => e.id === ID).map(e => (setdaf3date(e.datePick)))
        }
        if (driver === "") {
            customer.filter(e => e.id === ID).map(e => (setdriver(e.total)))
        }
        if (coc === "") {
            customer.filter(e => e.id === ID).map(e => (setcoc(e.tebini)))
        }

    }
    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/coc/updatecoc/", {
            carPick: carPick,
            daf3date: daf3date,
            coc: coc,
            driver: driver,
            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }


    useEffect(() => {
        axios.get("https://car-api.redcode.app/trans/getcoc/").then((response) => {
            settrans(response.data)
            // setCustomid(props.asman)
        });
    }, []);




    useEffect(() => {
        axios.get("https://car-api.redcode.app/coc/allcoc/").then((response) => {
            SetCustomer(response.data)
        })

    }, [])


    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h4 className="mb-1">COC</h4>
                        {/* <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label "> گەڕان بەدوای ناوی باخیرە</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای مەنەفێست باخیرە</label>
                            <input type="text" className="form-control" onChange={e => setFiltertemp(e.target.value)} id="cc-number" placeholder="" />
                        </div> */}
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>

                                <th scope="col">ژمارەی سەیارەکان</th>


                                {/* <th scope="col">کاتی دفع</th> */}
                                <th scope="col">تێچو</th>

                                <th scope="col">کات</th>
                                <th scope="col"> تێبینی</th>
                                {/* <th scope="col">پارە بدە</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {customer.filter((e => {
                                if (filterSearch === "" && filtertemp === "") {
                                    return e
                                } if (e.boatname.includes(filterSearch) && filtertemp === "") {
                                    return e
                                } if (e.vinnum.includes(filtertemp) && filterSearch === "") {
                                    return e
                                } if (e.vinnum.includes(filtertemp) && e.boatname.includes(filterSearch)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr
                                // className={
                                // e.daf3 === e.totalall ? "table-success" : e.madfw3 > 0 ? "table-warning" : "table-danger"
                                // }
                                >
                                    <th scope="row">{e.id}</th>
                                    <td >{e.carqty}</td>
                                    {/* <td>{e.daf3date}</td> */}
                                    <td>{e.total}</td>
                                    <td>{e.datePick}</td>
                                    <td>{e.tebini}</td>
                                    {/* <td className="notPrint">
                                        {e.daf3 === e.totalall ? null :
                                            <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                                onClick={() =>
                                                    axios.get(`https://car-api.redcode.app/baxira/pay/${e.RAND}/`).then((response) => {
                                                        setpays(response.data)
                                                    })
                                                }>پارە بدە</button>
                                        }
                                    </td> */}

                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">COCدەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            COCژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/coc/deletecoc/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    {/* <td className="notPrint" style={{ textAlign: "center" }}> <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.id)} ><GrDocumentDownload /></button>

                                    </td> */}
                                </tr>
                            })}




                            <div className="modal fade print-container" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-fullscreen">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>
                                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body" >
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <div style={{ paddingTop: "20px", textAlign: "center" }}>
                                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.datePick}</h5>

                                                        </div>
                                                        <div style={{ marginRight: "150px", paddingTop: "50px", paddingBottom: "50px" }}>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم : {e.payer}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">About: {e.about}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">نقل : {e.naql}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.name}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">گومرگ : {e.gumrg}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.phonenum}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} /> */}
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> ئیمزای وەرگر</label>

                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}


                                                            </div>

                                                            <div className="form-group">

                                                                <label htmlFor="exampleInputEmail1">ئیمزای ژمێریار</label>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">{e.m7asb}</label>

                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                            </div>



                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer notPrint">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
                                            <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>

                                        </div>
                                    </div>
                                </div>
                            </div>






                            <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>COC تازە کردنەوە</h5>

                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body">
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <form>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.carqty} onChange={e => setCarPick(e.target.value)} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">تێچو</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.total} onChange={e => setdriver(e.target.value)} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کات</label>
                                                                <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => setdaf3date(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> تێبینی</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => setcoc(e.target.value)} />
                                                            </div>


                                                        </form>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                        </div>
                                        <div className="modal-body">
                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>
                                                    <form style={{ color: "black" }}>


                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                            <h2>{carPick}</h2>
                                                        </div>


                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> تێچو</label>
                                                            <h2>{driver}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                            <h2>{coc}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> کات</label>
                                                            <h2>{daf3date}</h2>                                                        </div>



                                                    </form>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </tbody>
                    </table>


                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}


                        <div className="row">

                            <h6 className="">کۆی گشتی تێچوو</h6>
                            {/* <small className="text-muted"> ڕۆژ و کات</small> */}

                            <span className="">${customer.reduce((a, v) => a = a + v.total, 0)}</span>
                        </div>

                        <div className="row">

                            <h6 className="">کۆی گشتی دفع</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {trans.reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی گشتی ماوە</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {customer.reduce((a, v) => a = a + v.total, 0) - trans.reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>

                        <button type="button" className="btn btn-primary  p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppayall"
                        >پارە بدە</button>
                    </div>



                    <div className="modal fade" id="staticBackdroppayall" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                                $ {customer.reduce((a, v) => a = a + v.total, 0) - trans.reduce((a, v) => a = a + v.amount, 0)}
                                            </label>
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                                $ {trans.reduce((a, v) => a = a + v.amount, 0)}
                                            </label>
                                        </div>
                                        <hr className="my-4" />

                                        <div >
                                            <form>

                                                <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
                                                    <div className='pb-3' style={{ flex: "2", display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> بڕی پارە</label>

                                                        <input style={{ width: "80%" }} type="number"
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> کات</label>

                                                        <input style={{ width: "80%" }} type="date"
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setpaydate(e.target.value)} />
                                                    </div>
                                                    <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                        onClick={() => {
                                                            axios.post("https://car-api.redcode.app/trans/gwmrg/", {
                                                                transtype: 7,
                                                                amount: newmoney,
                                                                RANDID: 99,
                                                                datePick: paydate

                                                            }).then((res) => {
                                                                window.location.reload(false)
                                                            })
                                                        }}

                                                    >پارە بدە</button>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + trans.reduce((a, v) => a = a + v.amount, 0)}</h5>
                                                <h5>پارەی ماوە تازە  :  {customer.reduce((a, v) => a = a + v.total, 0) - trans.reduce((a, v) => a = a + v.amount, 0) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    < div style={{}}>
                                                        <div className="col-md-12 col-lg-12" dir="rtl">
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <h4 className="mb-1">پارەدانی پێشوو</h4>

                                                            </div>

                                                            <table className="table table-striped print-container">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>

                                                                        <th scope="col">کۆی گشتی دۆلار</th>

                                                                        <th scope="col">کات</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {trans.map(e => {
                                                                        return <tr key={e.id}>
                                                                            <th scope="row"></th>
                                                                            <td>{e.amount} $</td>


                                                                            <td>{e.datePick}</td>
                                                                            <td className="notPrint"><button type="button" className="btn btn-danger btn-sm p-1 " data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={() => setID(e.id)}><AiFillDelete /></button>


                                                                            </td>
                                                                        </tr>
                                                                    })}

                                                                </tbody>

                                                            </table>


                                                        </div>
                                                    </div >
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                            <div className="modal fade" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalToggleLabel23" aria-hidden="true">
                                <div className="modal-dialog modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalToggleLabel23">بڕی پارەدان دەسڕیتەوە؟</h5>

                                        </div>
                                        <div className="modal-body">
                                            <h5>

                                                بڕی پارەدان  دەسڕێتەوە
                                            </h5>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/trans/deletetrans/${ID}`).then((res) => {
                                                window.location.reload(false)
                                            })}
                                            >سڕینەوە</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>









                </div>
            </div>
        </div>
    )
}

export default Readcoc
