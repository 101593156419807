import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { GrDocumentDownload } from "react-icons/gr";
import img from "../../components/aa.jpg"
import back from "../../components/aa.png"

function Readariza() {
    const [customer, SetCustomer] = useState([])
    const [ID, setID] = useState("")

    useEffect(() => {
        axios.get("https://car-api.redcode.app/expense/allariza/").then((response) => {
            SetCustomer(response.data)
        })

    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }} className="prone">
            <div className="col-md-12 col-lg-12" dir="rtl">
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="mb-1">عریضة</h4>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">بۆ</th>
                            <th scope="col">بابەت</th>
                            <th scope="col">ناوەڕۆک</th>
                            <th scope="col">کات</th>
                            <th scope="col"> تێبینی</th>

                        </tr>
                    </thead>
                    <tbody>
                        {customer.map(e => {
                            return <tr
                            // className={
                            // e.daf3 === e.totalall ? "table-success" : e.madfw3 > 0 ? "table-warning" : "table-danger"
                            // }
                            >
                                <th scope="row">{e.id}</th>
                                <td >{e.bo}</td>
                                {/* <td>{e.daf3date}</td> */}
                                <td>{e.babat}</td>
                                <td>{e.nawarok}</td>
                                <td>{e.datePick}</td>
                                <td>{e.tebini}</td>
                                {/* <td className="notPrint">
                                        {e.daf3 === e.totalall ? null :
                                            <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                                onClick={() =>
                                                    axios.get(`https://car-api.redcode.app/baxira/pay/${e.RAND}/`).then((response) => {
                                                        setpays(response.data)
                                                    })
                                                }>پارە بدە</button>
                                        }
                                    </td> */}
                                <td className="notPrint" style={{ textAlign: "center" }}> <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.id)} ><GrDocumentDownload /></button>
                                </td>
                                <td className="notPrint">
                                    {/* <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button> */}
                                    <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>

                                    <div className="modal fade" id="exampleModalll" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">عریضة دەسڕیتەوە؟</h5>

                                                </div>
                                                <div className="modal-body">
                                                    <h5>
                                                        عریضة ژمارە ( {ID} ) دەسڕێتەوە
                                                    </h5>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/expense/deleteariza/${ID}/`).then((res) => {
                                                        window.location.reload(false)
                                                    })}
                                                    >سڕینەوە</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })}

                        <div className="modal fade print-containerpay" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-fullscreen">
                                <div className="modal-content">
                                    {/* <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div> */}
                                    <div className="modal-body" style={{ color: "black" }}>
                                        <div className="modal-body" >
                                            <img style={{ width: "100%" }} className='imgp' src={img} alt='print' />
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>

                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>

                                                    <div style={{ paddingTop: "20px", textAlign: "end" }}>
                                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.datePick}</h5>

                                                    </div>

                                                    <div style={{ paddingTop: "20px", textAlign: "center" }}>
                                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>بۆ / {e.bo}</h5>

                                                    </div>

                                                    <div style={{ paddingTop: "20px", textAlign: "center" }}>

                                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>بابەت / {e.babat}</h5>

                                                    </div>
                                                    <img src={back} alt='print' className='backprintcarariza' />

                                                    <div style={{ paddingTop: "20px", textAlign: "center", margin: "0 100px" }}>
                                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}> {e.nawarok}</h5>

                                                    </div>
                                                    <div >

                                                        {/* <div style={{ paddingTop: "200px", textAlign: "center" }}>
                                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}> هاوکاریتان جێگەی ڕێزە</h5>
                                                        </div> */}

                                                        <div style={{ textAlign: "end" }} className="botpr">
                                                            <p id="staticBackdropLabel" style={{ color: "black" }}>کۆمپانیای مضفر عبدلرحمان نجم
                                                            </p>
                                                            <p id="staticBackdropLabel" style={{ color: "black" }}>بۆ بازرگانی ئۆتۆمبێل
                                                            </p>
                                                            <p id="staticBackdropLabel" style={{ color: "black" }}>Email : muthafarcompany@gmail.com - Phone : 07514494343
                                                            </p>
                                                            {/* <p id="staticBackdropLabel" style={{ color: "black" }}>
                                                               </p> */}
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

                                                        <div className="form-group">
                                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> ئیمزای وەرگر</label>

                                                            <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} />
                                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                                        </div>

                                                        <div className="form-group">
                                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ئیمزای ژمێریار</label>
                                                            <div className="form-group">
                                                                <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">{e.m7asb}</label>

                                                                <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
                                                            </div>
                                                            <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} />
                                                        </div>

                                                        <img src={back} alt='print' className='backprint' />
                                                    </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="modal-footer notPrint">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
                                        <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="modal fade print-container" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-fullscreen">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>
                                    </div>
                                    <div className="modal-body" style={{ color: "black" }}>
                                        <div className="modal-body" >
                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>
                                                    <div style={{ paddingTop: "20px", textAlign: "center" }}>
                                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.datePick}</h5>

                                                    </div>
                                                    <div style={{ marginRight: "150px", paddingTop: "50px", paddingBottom: "50px" }}>
                                                        <div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم : {e.payer}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">About: {e.about}</label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">نقل : {e.naql}</label>
                                                                <label htmlFor="exampleInputEmail1">{e.name}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">گومرگ : {e.gumrg}</label>
                                                                <label htmlFor="exampleInputEmail1">{e.phonenum}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> ئیمزای وەرگر</label>
                                                        </div>
                                                        <div className="form-group">

                                                            <label htmlFor="exampleInputEmail1">ئیمزای ژمێریار</label>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">{e.m7asb}</label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="modal-footer notPrint">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
                                        <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                        {/* <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>عریضة تازە کردنەوە</h5>
                                    </div>
                                    <div className="modal-body" style={{ color: "black" }}>
                                        <div className="modal-body">
                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                            <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.carqty} onChange={e => setCarPick(e.target.value)} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">تێچو</label>
                                                            <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.total} onChange={e => setdriver(e.target.value)} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کات</label>
                                                            <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => setdaf3date(e.target.value)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> تێبینی</label>
                                                            <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => setcoc(e.target.value)} />
                                                        </div>
                                                    </form>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                    </div>
                                    <div className="modal-body">
                                        {customer.filter(e => e.id === ID).map(e =>
                                        (
                                            <div key={e.id}>
                                                <form style={{ color: "black" }}>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                        <h2>{carPick}</h2>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1"> تێچو</label>
                                                        <h2>{driver}</h2>                                                        </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                        <h2>{coc}</h2>                                                        </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1"> کات</label>
                                                        <h2>{daf3date}</h2>                                                        </div>
                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                        <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Readariza
