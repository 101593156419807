import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home';
import Navbar from './components/Navbar';
import CreateCustomer from './pages/customer/CreateCustomer';

import ReadCustomer from './pages/customer/ReadCustomer';
// import CreateInvoice from './pages/invoice/CreateInvoice';
import CreateExpenses from './pages/expenses/CreateExpenses';
import CreateCar from './pages/car/CreateCar';
import ReadCar from './pages/car/ReadCar';
// import CreateOption from './pages/options/CreateOption';
import ReadExpenses from './pages/expenses/ReadExpenses';
// import Createqabz from './pages/qabz/Createqabz';
// import ReadQabz from './pages/qabz/ReadQabz';
import Createbaxira from './pages/baxira/Createbaxira';
import Createdriver from './pages/drivers/Createdriver';
import Readbaxira from './pages/baxira/Readbaxira';
import Report from './pages/report/Report';
// import Createpshtgiri from './pages/pshtgiri/Createpshtgiri';

// import Readpshtgiri from './pages/pshtgiri/Readpshtgiri';

import Creategwmrg from './pages/gwmrg/Creategwmrg';
import Readgwmrg from './pages/gwmrg/Readgwmrg';
import Createiran from './pages/iran/Createiran';
import Readiran from './pages/iran/Readiran';
import CreateOrder from './components/CreateOrder';
import Printdoc from './components/Printdoc';
import Login from './pages/login/Login';
import Createwakalat from './pages/wakalat/Createwakalat';
import Readwakalat from './pages/wakalat/Readwakalat';
import Readsanawi from './pages/wakalat/Readsanawi';
import Createrasmal from './pages/rasmal/Createrasmal';
import Readrasmal from './pages/rasmal/Readrasmal';
import Createcoc from './pages/coc/Createcoc';
import Readcoc from './pages/coc/Readcoc';
import Readinvest from './pages/rasmal/Readinvest';
import Createariza from './pages/ariza/Createariza';
import Readariza from './pages/ariza/Readariza';
import Createkrin from './pages/krin/Createkrin';
import Readkrin from './pages/krin/Readkrin';
import Readfroshtn from './pages/krin/Readfroshtn';
import Kashf from './pages/kashf/Kashf';


function App() {


  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    setLoggedIn(JSON.parse(localStorage.getItem("loggedIn")))
  }, [])



  if (localStorage.getItem("dinar" === null || undefined)) {
    localStorage.setItem("dinar", "0")
  }



  return (
    <>
      {loggedIn ? <>

        <Navbar />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/createcustomer" component={CreateCustomer} />
          <Route exact path="/readcustomer" component={ReadCustomer} />

          {/* <Route exact path="/createinvoice" component={CreateInvoice} /> */}

          <Route exact path="/createexpenses" component={CreateExpenses} />
          <Route exact path="/readexpenses" component={ReadExpenses} />

          <Route exact path="/createcar" component={CreateCar} />
          <Route exact path="/readcar" component={ReadCar} />

          {/* <Route exact path="/createoption" component={CreateOption} />
          <Route exact path="/createqabz" component={Createqabz} />
          <Route exact path="/readqabz" component={ReadQabz} /> */}

          <Route exact path="/createdriver" component={Createdriver} />



          <Route exact path="/createbaxira" component={Createbaxira} />
          <Route exact path="/readbaxira" component={Readbaxira} />

          <Route exact path="/createiran" component={Createiran} />
          <Route exact path="/readiran" component={Readiran} />

          {/* <Route exact path="/createpshtgiri" component={Createpshtgiri} />
        <Route exact path="/readpshtgiri" component={Readpshtgiri} /> */}


          <Route exact path="/createwakalat" component={Createwakalat} />
          <Route exact path="/readwakalat" component={Readwakalat} />
          <Route exact path="/readsanawi" component={Readsanawi} />


          <Route exact path="/report" component={Report} />


          <Route exact path="/creategwmrg" component={Creategwmrg} />
          <Route exact path="/readgwmrg" component={Readgwmrg} />


          <Route exact path="/createrasmal" component={Createrasmal} />
          <Route exact path="/readrasmal" component={Readrasmal} />
          <Route exact path="/readinvest" component={Readinvest} />

          <Route exact path="/createcoc" component={Createcoc} />
          <Route exact path="/readcoc" component={Readcoc} />

          <Route exact path="/createariza" component={Createariza} />
          <Route exact path="/readariza" component={Readariza} />


          <Route exact path="/data" component={Report} />
          <Route exact path="/printdoc" component={Printdoc} />
          <Route exact path="/createorder" component={CreateOrder} />


          <Route exact path="/createkrin" component={Createkrin} />
          <Route exact path="/readkrin" component={Readkrin} />
          <Route exact path="/readfroshtn" component={Readfroshtn} />


          <Route exact path="/kashf" component={Kashf} />







          <Route exact path="/login" component={Login} />





        </Switch>


      </> : <Login />}

    </>
  );
}

export default App;
