
import React, { useState } from 'react';
import axios from "axios"


function Createbaxira() {



    const [carPick, setCarPick] = useState("");
    // const [daf3, setdaf3] = useState("");

    // const [daf3date, setdaf3date] = useState("");

    const [techw, settechw] = useState("");
    const [baxiranum, setbaxiranum] = useState("");

    const [datepick, setdatepick] = useState("");


    // const [baqi, setbaqi] = useState("");
    const [total, settotal] = useState("");
    const [tebini, settebini] = useState("");


    const [boatname, setboatname] = useState("");
    const [boatnum, setboatnum] = useState("");

    // const [customerList, setCustomerList] = useState([])
    // const [customerID, setCustomerID] = useState("");

    // const [, set] = useState("");
    // const [, set] = useState("");

    // const [option, setOptions] = useState([]);


    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
    //         setOptions(response.data)
    //         // setCustomid(props.asman)
    //     });
    // }, []);

    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
    //         setCustomerList(response.data)
    //     });
    // }, []);



    const createBaxira = () => {
        axios.post("https://car-api.redcode.app/baxira/createbaxira/", {
            carPick: carPick,
            daf3: "0",
            daf3date: "0",
            techw: techw,
            baxiranum: baxiranum,
            boatname: boatname,
            boatnum: boatnum,
            baqi: "0",
            total: total,
            datepick: datepick,
            tebini: tebini,
            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            // customerID: customerID,

        }).then((res) => {
            window.location.reload(false)
            alert("باخیرە زیاد کرا")
        })
        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)

    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-11 col-lg-11" dir="rtl">
                <h4 className="mb-3">باخیرە زیاد بکە</h4>
                <form className="needs-validation">
                    <div className="row g-3">

                        {/* <div className="">
                            <label htmlFor="customer" >کڕیار دیاری بکە</label>
                            <select onChange={e => setCustomerID(e.target.value)} >
                                <option value="">تکایە هەڵبژێرە</option>
                                {customerList.map((e) => {
                                    return <option key={e.ID} value={e.ID}>{e.name}</option>
                                })}
                            </select>
                        </div> */}

                        {/* <div style={{ display: "flex", flexDirection: "row" }}>



                            <td>
                                <select name="car" onChange={e => setenginePick(e.target.value)} >
                                    <option value="">مەکینە</option>
                                    {option.filter(e => e.OPkey === 6).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="car" onChange={e => setmodelpick(e.target.value)} >
                                    <option value="">مۆدێل</option>
                                    {option.filter(e => e.OPkey === 3).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>

                            <td>
                                <select name="car" onChange={e => setcityPick(e.target.value)} >
                                    <option value="">شار</option>
                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                        </div> */}
                        <div className="col-md-4">

                            <label htmlFor="cc-number" className="form-label">ژمارەی سەیارەکان</label>
                            <input type="number" onChange={e => setCarPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-sm-4">
                            <label htmlFor="lastName" className="form-label">تێچوو</label>
                            <input type="number" onChange={e => settechw(e.target.value)} className="form-control" id="lastName" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">ناوی باخیرە</label>
                            <input type="text" onChange={e => setboatname(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">ژمارە باخیرە</label>
                            <input type="number" onChange={e => setbaxiranum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">مەنەفێست باخیرە</label>
                            <input type="text" onChange={e => setboatnum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="cc-number" className="form-label">تێبینی</label>
                            <textarea onChange={e => settebini(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>











                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">کۆی گشتی</label>
                            <input type="number" onChange={e => settotal(e.target.value)} className="form-control" id="address" />

                        </div>
                        {/* <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label"> مدفوع</label>
                            <input type="number" onChange={e => setdaf3(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">بەرواری دفع</label>
                            <input type="date" onChange={e => setdaf3date(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">باقی (ماوە)</label>
                            <input type="number" onChange={e => setbaqi(e.target.value)} className="form-control" id="address" />

                        </div> */}
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">کاتی بارکردن</label>
                            <input type="date" onChange={e => setdatepick(e.target.value)} className="form-control" id="address" />

                        </div>

                        <button className="w-100 btn btn-dark btn-lg" onClick={createBaxira} type="button">باخیرە زیاد بکە</button>


                        <hr className="my-4" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Createbaxira
