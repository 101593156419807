import React from 'react'

function Card(props) {
    return (
        <>
            <div className="card"
                style={{ width: "15rem" }}>
                <div className="card-body">
                    <h5 className="card-title mb-4"  >{props.title}</h5>
                    <h5 className="card-subtitle mb-2 ">{props.parag}</h5>
                </div>
            </div>
        </>
    )
}

export default Card