import React, { useState, useEffect } from 'react'
import axios from 'axios';
function CreateOrder(props) {

    const [form, setForm] = useState([]);
    const [options, setOptions] = useState([]);
    const [datepick, setdatepick] = useState("");

    const prevIsValid = () => {
        if (form.length === 0) {
            return true
        }
        const someEmpty = form.some((item) => item.carPick === "" || item.colorPick === "")
        if (someEmpty) {
            // eslint-disable-next-line
            form.map((item, index) => {
                const allPrev = [...form];
                if (form[index].Platform === '') {
                    allPrev[index].errors.Product = "Platform Is Required!"
                }
                if (form[index].Username === '') {
                    allPrev[index].errors.Qty = "Username Is Required!"
                }
                setForm(allPrev)
            })
        }
        return !someEmpty;
    }

    const send = (e) => {

        axios.post("https://car-api.redcode.app/customer/sendallinvoice", {
            today: localStorage.getItem("webinter") === "1" ? JSON.parse(localStorage.getItem("dinar")) : "daxl bka",
            form: form,
            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            customerID: props.customerID,
            datePick: datepick,

        }).then((res) => {
            window.location.reload(false)
            alert("سەیارەکان زیاد کرا")
            // setForm([])
        });

        setTimeout(() => {
            alert("سەیارەکان زیاد کرا")
            window.location.reload(false)
        }, 4000);
    }

    const handleAddLink = (e) => {

        const inputState = {
            engine: '',
            model: '',
            gwmrg: '',
            carPick: '',
            colorPick: '',
            vinNum: '',
            tempNum: '',
            tebini: '',

            priceUS: 0,
            tajr: 0,
            balance: 0,
            qazanj: 0,
            naql: 0,
            coc: 0,
            ixraj: 0,
            total: 0,
            totalUS: 0,
            madfw3: 0,
            baqi: 0,
            RANDpay: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),

            errors: {
                engine: '',
                model: '',
                gwmrg: '',
                carPick: '',
                colorPick: '',
                vinNum: '',
                tempNum: '',
                tebini: '',

                priceUS: 0,
                tajr: 0,
                balance: 0,
                qazanj: 0,
                naql: 0,
                coc: 0,
                ixraj: 0,
                total: 0,
                totalUS: 0,
                madfw3: 0,
                baqi: 0,
                RANDpay: Math.floor(Math.random() * (9999999 - 1 + 1) + 1),

            }
        }

        if (prevIsValid()) {
            setForm((prev) => [...prev, inputState])
        }
    }
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions").then((response) => {
            setOptions(response.data)
            // eslint-disable-next-line
            // setCustomid(props.asman)
        });
    }, []);



    const onChange = (index, event) => {
        // event.preventDefault();
        // event.persist()
        // setCustomid(props.asman);
        setForm(prev => {
            return prev.map((item, i) => {
                if (i !== index) {
                    return item;
                }
                return {
                    ...item, [event.target.name]: isNaN(event.target.value) ? event.target.value : +event.target.value,


                }
            })
        })
    }
    const handleRemoveField = (e, index) => {
        e.preventDefault();

        setForm((prev) => prev.filter((item) => item !== prev[index]));
    }

    return (



        <div className="" >

            <div className="col-md-12 col-lg-12" style={{ height: "100%", display: 'flex', flexDirection: "column", justifyContent: 'space-between' }} dir="rtl" >
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <table className="table table-bordered table-hover" id="tab_logic">
                            <thead>
                                <tr>
                                    <th > # </th>
                                    <th > مەکینە </th>

                                    <th > جۆری سەیارە </th>

                                    <th > ژمارە شانصی </th>

                                    <th > نرخی گومرگ  </th>


                                    <th > باڵانس </th>

                                    <th > گواستنەوە(نقل) </th>

                                    <th > اخراج </th>
                                    <th > کۆی دۆلار </th>
                                    {/* <th > مدفوع </th> */}
                                    <th > تێبینی </th>


                                </tr>
                            </thead>
                            <tbody>

                                {form.map((item, index) => {
                                    return <tr key={`item-${index}`}>
                                        <td>{index + 1}</td>

                                        <td>
                                            <select style={{ width: "60px" }} name="engine" onChange={(e) => onChange(index, e)} >
                                                <option value="">مەکینە</option>
                                                {options.filter(e => e.OPkey === 6).map((e) => {
                                                    return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                })}
                                            </select>


                                            <select style={{ width: "80px" }} name="model" onChange={(e) => onChange(index, e)} >
                                                <option value="">مۆدێل</option>
                                                {options.filter(e => e.OPkey === 3).map((e) => {
                                                    return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
                                            </select>



                                            <select style={{ width: "90px" }} name="gwmrg" onChange={(e) => onChange(index, e)} >
                                                <option value="">گومرگ</option>
                                                {options.filter(e => e.OPkey === 5).map((e) => {
                                                    return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                })}
                                            </select>
                                        </td>

                                        <td>


                                            <input type="text" onChange={(e) => onChange(index, e)} value={item.carPick} name="carPick" className="form-control" id="cc-number" />
                                            <th > ڕەنگ </th>

                                            <input type="text" onChange={(e) => onChange(index, e)} value={item.colorPick} name="colorPick" className="form-control" id="cc-number" placeholder="" />

                                        </td>


                                        <td>

                                            <input type="text" onChange={(e) => onChange(index, e)} value={item.vinNum} name="vinNum" className="form-control" id="lastName" placeholder="" />

                                            <th > ژمارە کاتی </th>

                                            <input type="text" onChange={(e) => onChange(index, e)} value={item.tempNum} name="tempNum" className="form-control" id="cc-number" placeholder="" />
                                        </td>
                                        <td>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.priceUS} name="priceUS" className="form-control" id="email" />

                                            <th > خەرجی </th>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.tajr} name="tajr" className="form-control" id="address" />
                                        </td>

                                        <td>

                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.balance} name="balance" className="form-control" id="address" />
                                            <th > قازانج </th>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.qazanj} name="qazanj" className="form-control" id="address" />

                                        </td>

                                        <td>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.naql} name="naql" className="form-control" id="address" />
                                            <th > COC </th>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.coc} name="coc" className="form-control" id="address" />
                                        </td>
                                        <td>
                                            <input type="number" onChange={(e) => onChange(index, e)} value={item.ixraj} name="ixraj" className="form-control" id="address" />
                                        </td>
                                        <td>
                                            <input type="number" onMouseOver={(e) => onChange(index, e)} name="totalUS"
                                                value={
                                                    (item.priceUS + item.tajr + item.balance + item.qazanj + item.naql + item.coc + item.ixraj)
                                                }
                                                className="form-control" id="address" />

                                            <th > کۆی  دینار </th>

                                            <input type="number" onMouseOver={(e) => onChange(index, e)}
                                                value={
                                                    localStorage.getItem("webinter") === "1" ? (item.priceUS + item.tajr + item.balance + item.qazanj + item.naql + item.coc + item.ixraj) * localStorage.getItem("dinar") : "daxl bka"
                                                }
                                                name="total" className="form-control" id="address" />
                                        </td>

                                        <td>

                                            <input type="text" onChange={(e) => onChange(index, e)} value={item.tebini} name="tebini" className="form-control" id="address" />
                                        </td>


                                        <button id='delete_row' onClick={(e) => handleRemoveField(e, index)} style={{ width: "30px", height: "55px", backgroundColor: "red" }} className="btn btn-danger">X</button>
                                    </tr>
                                })}

                                <tr id='addr1'></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                    <button id="add_row" onClick={handleAddLink} className="btn btn-success">+</button>
                </div>
                <div className="col-md-6">
                    <label htmlFor="address" className="form-label">کات</label>
                    <input type="date" onChange={e => setdatepick(e.target.value)} className="form-control" id="address" />
                </div>
                <div style={{ padding: "10px 0px" }}>
                    <button id="add_row" onClick={send} className="btn btn-primary" type="button">سەیارەکان زیاد بکە</button>
                </div>
            </div>
        </div>
    )
}
export default CreateOrder
