import React, { useEffect, useState } from 'react';
import axios from "axios"

function Creategwmrg() {
    const [usedFor, setusedFor] = useState("");

    // const [priceUS, setpriceUS] = useState("");
    const [datePick, SetDatePick] = useState("");

    // const [paraleft, setparaleft] = useState("");
    // const [madfw3, setmadfw3] = useState("");
    const [note, setnote] = useState("");
    const [briq, setbriq] = useState(0);
    const [xarji, setxarji] = useState(0);
    const [dolar, setdolar] = useState(0);
    // const [brus, setbrus] = useState("");


    const [gumrgPick, setgumrgPick] = useState("");
    const [option, setOptions] = useState([]);
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);


    const createQabzData = () => {
        axios.post("https://car-api.redcode.app/gwmrg/creategwmrg/", {
            amount: briq,
            amountus: localStorage.getItem("webinter") === "1" ? (briq / dolar).toFixed(2) : "daxl bka",
            sarfFor: usedFor,
            amountAll: ((xarji * dolar) + briq).toFixed(2),
            amountAllUS: ((briq / dolar) + xarji).toFixed(2),
            datePick: datePick,
            paraLeft: ((briq / dolar) + xarji).toFixed(2),
            // - madfw3
            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            note: note,
            madfw3: "0",
            xarji: xarji,
            gumrgPick: gumrgPick,
        }).then((res) => {
            window.location.reload(false)
            alert("گومرگ دروست کرا")
        })
        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)

    }



    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-12 col-lg-12" dir="rtl">
                <div style={{ display: "flex", justifyContent: "space-between", width: "70%", paddingBottom: "20px" }}>
                    <h4 className="mb-3">گومرگ دروست بکە</h4>


                    {/* <p htmlFor="lastName" className="form-label">دۆلاری ئەمڕۆ</p> */}
                    <div className="col-sm-7">
                        <input onChange={e => setdolar(+e.target.value)} type="text" className="form-control" id="lastName" placeholder="دۆلاری ئەمڕۆ" required />
                    </div>
                </div>
                <div className="col-sm-3 mb-3">
                    <label htmlFor="lastName" className="form-label">ناونیشانی گومرگ</label>

                    <select name="car" onChange={e => setgumrgPick(e.target.value)} >
                        <option value="">تکایە هەڵبژێرە</option>
                        {option.filter(e => e.OPkey === 5).map((e) => {
                            return <option value={e.OPvalue}>{e.OPvalue}</option>
                        })}
                    </select>

                </div>
                <form className="needs-validation" >
                    <div className="row g-3">

                        <div className="col-sm-3">
                            <label htmlFor="lastName" className="form-label">ژمارەی سەیارە</label>
                            <input onChange={e => setusedFor(e.target.value)} type="text" className="form-control" id="lastName" placeholder="" required />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="firstName" className="form-label">بە بڕی دینار</label>
                            <input onChange={e => setbriq(+e.target.value)} type="number" className="form-control" id="firstName" placeholder="" required />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="address" className="form-label">بە بڕی دۆلار</label>
                            <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? (briq / dolar).toFixed(2) : "daxl bka"}

                                className="form-control" id="address" disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="address" className="form-label"> خەرجی دۆلار</label>
                            <input type="number"
                                onChange={e => setxarji(+e.target.value)}
                                className="form-control" id="address" />
                        </div>



                        <div className="col-md-6">
                            {/* <span className="text-muted"></span> */}
                            <label htmlFor="email" className="form-label">کۆی گشتی دینار</label>
                            <input type="number" placeholder={((xarji * dolar) + briq).toFixed(2)} className="form-control" id="email" disabled />
                        </div>
                        {/* <div className="col-md-6">
                            <span className="text-muted"></span>
                            <label htmlFor="email" className="form-label">نرخ دینار</label>
                            <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} onChange={e => setpriceIQ(e.target.value)} className="form-control" id="email" />
                        </div> */}
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کۆی گشتی دۆلار</label>
                            <input type="number" placeholder={((briq / dolar) + xarji).toFixed(2)}

                                className="form-control" id="address" disabled />
                        </div>

                        {/* <div className="col-md-6">
                            <label htmlFor="address" className="form-label">مدفوع</label>
                            <input type="number" onChange={e => setmadfw3(e.target.value)} className="form-control" id="address" placeholder="" required />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">پارەی ماوە</label>
                            <input type="number" placeholder={((briq / dolar) + xarji).toFixed(2) - madfw3} className="form-control" id="address" disabled />

                        </div> */}
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">تێبینی</label>
                            <textarea onChange={e => setnote(e.target.value)} className="form-control" id="address" placeholder="" required />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کات</label>
                            <input type="date" onChange={e => SetDatePick(e.target.value)} className="form-control" id="address" placeholder="" required />

                        </div>

                        <button className="w-100 btn btn-dark btn-lg" onClick={createQabzData} type="button">دروست بکە</button>


                        <hr className="my-4" />


                    </div>
                </form>

            </div>
        </div>


    )
}

export default Creategwmrg
