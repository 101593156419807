import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Card from '../../components/Card'


function Kashf() {
    const [fdate, setfdate] = useState("")
    const [ldate, setldate] = useState("")

    const [carlist, setCarList] = useState([])
    const [gwmrg, setGwmrg] = useState([])
    const [baxira, setBaxira] = useState([])
    const [iran, setiran] = useState([])
    const [coc, setcoc] = useState([])
    const [trans, settrans] = useState([])

    // useEffect(() => {

    //     axios.get("https://car-api.redcode.app/customer/alldetail/").then((response) => {
    //         setCarList(response.data)
    //     })


    // }, [])

    const filterDate = () => {

        axios.get("https://car-api.redcode.app/customer/alldetail/").then((response) => {
            setCarList(response.data)
        })

        axios.get("https://car-api.redcode.app/gwmrg/allgwmrg/").then((response) => {
            setGwmrg(response.data)
        })

        axios.get("https://car-api.redcode.app/baxira/allbaxira/").then((response) => {
            setBaxira(response.data)
        })

        axios.get("https://car-api.redcode.app/baxira/alliran/").then((response) => {
            setiran(response.data)
        })

        axios.get("https://car-api.redcode.app/coc/allcoc/").then((response) => {
            setcoc(response.data)
        })


        axios.get("https://car-api.redcode.app/trans/getalltrans/").then((response) => {
            settrans(response.data)
        })

    }
    const getDate = (arr) => {
        return arr.filter(e => (e.datePick >= fdate && e.datePick <= ldate))
    }
    const getGumrgDate = (arr, loc) => {
        const aa = arr.filter(e => (e.datePick >= fdate && e.datePick <= ldate)).filter(e => e.gwmrgloc === loc)
        return aa
    }
    return (
        <div >
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                        <h4 className="mb-1">کشف حساب</h4>
                        <div style={{ display: "flex", flexDirection: "row" }}>


                            <div className="col-md-8">
                                <label htmlFor="address" className="form-label"> کات</label>
                                <input type="date" onChange={e => setfdate(e.target.value)} className="form-control" id="address" required />
                            </div>
                            <div className="col-md-2" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                                <h3>بۆ</h3>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => setldate(e.target.value)} className="form-control" id="address" required />
                            </div>

                            <div style={{ width: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "2vw" }}>
                                <button type="button" onClick={filterDate} className="btn btn-dark">فلتەر</button>

                            </div>

                        </div>
                    </div>
                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "50px auto" }}>
                        <div className="card text-white bg-success"
                            //     e.aa === e.bb ? "card text-white bg-success" : e.bb > 0 || null ? "card text-white bg-warning" : "card text-white bg-danger"
                            //     }) === e.totalUS ? "card text-white bg-success" : "card text-white bg-danger"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">ئۆتۆمبێل</h5>
                                <h6 className="card-subtitle mb-2 ">test</h6>
                                <p className="card-text">test</p>
                                {/* <h1>{carlist.reduce((a, v) => a = a + v.totalUS, 0)}</h1> */}
                            </div>
                        </div>
                        <Card title={"کۆی گشتی"} parag={getDate(carlist).reduce((a, v) => a = a + v.totalUS, 0)} />
                        <Card title={"کۆی باڵانس"} parag={getDate(carlist).reduce((a, v) => a = a + v.balance, 0)} />
                        <Card title={"کۆی قازانج"} parag={getDate(carlist).reduce((a, v) => a = a + v.qazanj, 0)} />
                        <Card title={"کۆی واصڵ"} parag={getDate(trans).filter(e => e.transtype === 1).reduce((a, v) => a = a + v.amount, 0)} />
                    </div>
                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "10px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">گومرگ</h5>
                                <h6 className="card-subtitle mb-2 ">حاجی ئۆمەران</h6>
                            </div>
                        </div>
                        <Card title={"کۆی دینار"} parag={getGumrgDate(gwmrg, "حاجی ئۆمەران").reduce((a, v) => a = a + v.amountAll, 0)} />
                        <Card title={"کۆی دۆلار"} parag={getGumrgDate(gwmrg, "حاجی ئۆمەران").reduce((a, v) => a = a + v.amountAllUS, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 3 && e.RANDID === "حاجی ئۆمەران").reduce((a, v) => a = a + v.amount, 0)} />
                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "10px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">گومرگ</h5>
                                <h6 className="card-subtitle mb-2 ">باشماخ</h6>
                            </div>
                        </div>

                        <Card title={"کۆی دینار"} parag={getGumrgDate(gwmrg, "باشماخ").reduce((a, v) => a = a + v.amountAll, 0)} />
                        <Card title={"کۆی دۆلار"} parag={getGumrgDate(gwmrg, "باشماخ").reduce((a, v) => a = a + v.amountAllUS, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 3 && e.RANDID === "باشماخ").reduce((a, v) => a = a + v.amount, 0)} />

                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "10px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">گومرگ</h5>
                                <h6 className="card-subtitle mb-2 ">ئیبراهیم خلیل</h6>

                            </div>
                        </div>
                        <Card title={"کۆی دینار"} parag={getGumrgDate(gwmrg, "ئیبراهیم خلیل").reduce((a, v) => a = a + v.amountAll, 0)} />
                        <Card title={"کۆی دۆلار"} parag={getGumrgDate(gwmrg, "ئیبراهیم خلیل").reduce((a, v) => a = a + v.amountAllUS, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 3 && e.RANDID === "ئیبراهیم خلیل").reduce((a, v) => a = a + v.amount, 0)} />
                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "10px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">گومرگ</h5>
                                <h6 className="card-subtitle mb-2 ">پەروێزخان</h6>
                            </div>
                        </div>
                        <Card title={"کۆی دینار"} parag={getGumrgDate(gwmrg, "پەروێزخان").reduce((a, v) => a = a + v.amountAll, 0)} />
                        <Card title={"کۆی دۆلار"} parag={getGumrgDate(gwmrg, "پەروێزخان").reduce((a, v) => a = a + v.amountAllUS, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 3 && e.RANDID === "پەروێزخان").reduce((a, v) => a = a + v.amount, 0)} />
                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "50px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">باخرە</h5>
                                {/* <h6 className="card-subtitle mb-2 ">test</h6> */}
                                {/* <p className="card-text">test</p> */}
                            </div>
                        </div>
                        <Card title={"کۆی گشتی"} parag={getDate(baxira).reduce((a, v) => a = a + v.totalall, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 4).reduce((a, v) => a = a + v.amount, 0)} />
                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "50px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">ئێران</h5>
                            </div>
                        </div>
                        <Card title={"کۆی دۆلار"} parag={getDate(iran).reduce((a, v) => a = a + v.dolar, 0)} />
                        <Card title={"کۆی خسارە"} parag={getDate(iran).reduce((a, v) => a = a + v.xasara, 0)} />
                    </div>

                    <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "70px", margin: "50px auto" }}>
                        <div className="card text-white bg-success"
                            style={{ width: "18rem" }}>
                            <div className="card-body">
                                <h5 className="card-title">COC</h5>
                            </div>
                        </div>
                        <Card title={"کۆی تێچوو"} parag={getDate(coc).reduce((a, v) => a = a + v.total, 0)} />
                        <Card title={"کۆی مدفوع"} parag={getDate(trans).filter(e => e.transtype === 7).reduce((a, v) => a = a + v.amount, 0)} />
                    </div>

                    {/* <button type="button" onClick={() => window.print()} className="btn btn-secondary">print</button> */}

                </div>
            </div>
        </div>
    )
}

export default Kashf




















