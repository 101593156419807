import React, { useState } from 'react'
import "./Navbar.css"

function Navbar() {
    const [price, setPrice] = useState("")

    const setPRICE = () => {
        localStorage.setItem("dinar", price)
        localStorage.setItem("webinter", "1")
        window.location.reload()
    }
    return (
        <div dir="rtl">
            <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">کۆمپانیای مظفر عبدالرحمن نجم</a>
                {/* <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="عرض/إخفاء لوحة التنقل">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                <input style={{ textAlign: 'center' }} onChange={e => setPrice(e.target.value)} className="form-control form-control-dark w-50" type="text" placeholder=" دینار بنووسە بەرامبەر دۆلار" aria-label="بحث" />

                {price === "" ? null :
                    <button className="btn btn-light" onClick={setPRICE}>جێگیر بکە</button>
                }
                <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                        <a className="nav-link px-3" href="#!"> $1 = {localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"} IQD</a>
                    </div>
                </div>
                {/* <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                        <a className="nav-link px-3" href="#">تسجيل الخروج</a>
                    </div>
                </div> */}
            </header>
            <header className="p-2 bg-dark text-white" dir='rtl'>
                <div className="container">
                    <div className=" align-items-center navStyle">
                        {/* <a href="/" className=" bd d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                            <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"></svg>
                        </a> */}

                        <ul className=" nav mb-2 mb-md-0">
                            <li> <div className="btn-group " dir="ltr">
                                <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    کڕیار
                                </button>
                                <ul className="dropdown-menu" >
                                    <li><a className="dropdown-item text-center" href="/createcustomer">زیاد کردنی کڕیار</a></li>

                                    <li><a className="dropdown-item text-center" href="/readcustomer">بینینی کڕیار</a></li>
                                </ul>
                            </div></li>

                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        ئۆتۆمبێل
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createcar">زیاد کردنی ئۆتۆمبێل</a></li>

                                        <li><a className="dropdown-item text-center" href="/readcar">بینینی ئۆتۆمبێل</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        قبظ
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createqabz">زیاد کردنی قبظ</a></li>

                                        <li><a className="dropdown-item text-center" href="/readqabz">بینینی قبظ</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li>
                                <div className="btn-group" dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                        گومرگ
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="creategwmrg">زیاد کردنی گومرگ</a></li>

                                        <li><a className="dropdown-item text-center" href="readgwmrg">بینینی گومرگ</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group" dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                                        باخیرە
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createbaxira">زیاد کردنی باخیرە</a></li>

                                        <li><a className="dropdown-item text-center" href="/readbaxira">بینینی باخیرە</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                                        ئێران
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createiran">زیاد کردنی ئێران</a></li>

                                        <li><a className="dropdown-item text-center" href="/readiran">بینینی ئێران</a></li>
                                    </ul>
                                </div>

                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                                        COC
                                    </button>
                                    <ul className="dropdown-menu" dir='rtl'>
                                        <li><a className="dropdown-item text-center" href="/createcoc">زیاد کردنی COC</a></li>

                                        <li><a className="dropdown-item text-center" href="/readcoc">بینینی COC</a></li>
                                    </ul>
                                </div>

                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                        پشتگیری
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createwakalat">زیاد کردنی پشتگیری </a></li>

                                        <li><a className="dropdown-item text-center" href="/readwakalat">بینینی پشتگیری هاتووچۆ</a></li>
                                        <li><a className="dropdown-item text-center" href="/readsanawi">بینینی پشتگیری سەنەوی</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                        رَسْمال
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createrasmal">زیاد کردنی رَسْمال </a></li>

                                        <li><a className="dropdown-item text-center" href="/readinvest">بینینی وەبەرهێنانی کۆمپانیا</a></li>
                                        <li><a className="dropdown-item text-center" href="/readrasmal">بینینی قەرزداری کۆمپانیا</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        خەرجی
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createexpenses">زیاد کردنی خەرجی</a></li>

                                        <li><a className="dropdown-item text-center" href="/readexpenses">بینینی خەرجی</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        عریضة
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createariza">زیاد کردنی عریضة</a></li>

                                        <li><a className="dropdown-item text-center" href="/readariza">بینینی عریضة</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        کڕین و فرۆشتن
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-center" href="/createkrin">زیاد کردن</a></li>

                                        <li><a className="dropdown-item text-center" href="/readkrin">لیستی کڕدراو </a></li>
                                        <li><a className="dropdown-item text-center" href="/readfroshtn">لیستی فرۆشراو </a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="btn-group " dir="ltr">
                                    <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        کشف حساب
                                    </button>
                                    <ul className="dropdown-menu">
                                        {/* <li><a className="dropdown-item text-center" href="/createkrin">زیاد کردن</a></li> */}

                                        <li><a className="dropdown-item text-center" href="/kashf">بینین </a></li>
                                        {/* <li><a className="dropdown-item text-center" href="/readfroshtn">لیستی فرۆشراو </a></li> */}
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <a href="#" className="nav-link px-2 text-white">About</a>
                            </li> */}
                        </ul>

                        {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <input type="search" className="form-control form-control-dark" placeholder="Search..." aria-label="Search" />
                        </form> */}

                        <div className="">
                            <div className="btn-group dropstart " dir="ltr">
                                <button type="button" className="btn btn-danger" onClick={() => { return localStorage.setItem("loggedIn", false), window.location.reload(false) }} >
                                    دەرچوون
                                </button>

                            </div>
                            {/* <button type="button" className="btn btn-outline-light me-2">Login</button>
                            <button type="button" className="btn btn-warning">Sign-up</button> */}
                        </div>
                    </div>
                </div>
            </header>


            {/* <nav id="sidebarMenu" className="col-md-1 col-lg-1 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">

                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                کڕیار
                            </button>
                            <ul className="dropdown-menu" >
                                <li><a className="dropdown-item text-center" href="/createcustomer">زیاد کردن</a></li>

                                <li><a className="dropdown-item text-center" href="/readcustomer">ڕاپۆرت</a></li>
                            </ul>
                        </div>

                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                ئۆتۆمبێل
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createcar">ئۆتۆمبێل زیاد بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readcar">ڕاپۆرت ئۆتۆمبێل</a></li>
                            </ul>
                        </div>

                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                قبظ
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createqabz">قبظ دروست بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readqabz">ڕاپۆرت</a></li>
                            </ul>
                        </div>
                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                گومرگ
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="creategwmrg">دروست بکە</a></li>

                                <li><a className="dropdown-item text-center" href="readgwmrg">ڕاپۆرت</a></li>
                            </ul>
                        </div>
                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                                باخیرە
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createbaxira">دروست بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readbaxira">ڕاپۆرت</a></li>
                            </ul>
                        </div>
                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                                ئێران
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createiran">دروست بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readiran">ڕاپۆرت</a></li>
                            </ul>
                        </div>

                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                خەرجی
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createexpenses">خەرجی زیاد بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readexpenses">ڕاپۆرت</a></li>
                            </ul>
                        </div> */}


            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                پشتگیری
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createpshtgiri"> زیاد بکە</a></li>

                                <li><a className="dropdown-item text-center" href="/readpshtgiri">ڕاپۆرت</a></li>
                            </ul>
                        </div>
                        <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                سەنەوی
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createsanawi">زیاد بکە </a></li>

                                <li><a className="dropdown-item text-center" href="/readsanawi">ڕاپۆرت</a></li>
                            </ul>
                        </div> */}
            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" style={{ fontSize: "12px" }} className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                پشتگیری هاتووچۆ
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/printdoc">دروست بکە</a></li>

                                <li><a className="dropdown-item text-center" href="#!">View All</a></li>
                            </ul>
                        </div> */}
            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-danger" onClick={() => { return localStorage.setItem("loggedIn", false), window.location.reload(false) }} >
                                دەرچوون
                            </button>

                        </div> */}
            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                ‌هەڵبژاردنەکان
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createoption">create</a></li>

                                <li><a className="dropdown-item text-center" href="#!">Action</a></li>
                            </ul>
                        </div> */}
            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                شۆفێرەکان
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/createdriver">Action</a></li>

                                <li><a className="dropdown-item text-center" href="#!">Action</a></li>
                            </ul>
                        </div> */}


            {/* <div className="btn-group dropstart m-1" dir="ltr">
                            <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                ڕاپۆرتەکان
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item text-center" href="/data">rep</a></li>

                                <li><a className="dropdown-item text-center" href="#!">Action</a></li>
                            </ul>
                        </div> */}


            {/* <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#!">
                                <span data-feather="home"></span>
                                لوحة القيادة
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="users"></span>
                                الزبائن
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="bar-chart-2"></span>
                                التقارير
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="layers"></span>
                                التكاملات
                            </a>
                        </li> */}
            {/* </ul> */}

            {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>التقارير المحفوظة</span>
                        <a className="link-secondary" href="#!" aria-label="إضافة تقرير جديد">
                            <span data-feather="plus-circle"></span>
                        </a>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="file-text"></span>
                                الشهر الحالي
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="file-text"></span>
                                الربع الأخير
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="file-text"></span>
                                التفاعل الإجتماعي
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#!">
                                <span data-feather="file-text"></span>
                                مبيعات نهاية العام
                            </a>
                        </li>
                    </ul> */}
            {/* </div>
            </nav> */}


        </div>
    )
}

export default Navbar
