import React, { useEffect, useState } from 'react'
import axios from 'axios'



function Reportpay(props) {
    const [wasl, setwasl] = useState([])
    const [mad, setmad] = useState([])

    useEffect(() => {
        axios.get("https://car-api.redcode.app/report/totalwasl/").then((response) => {
            setwasl(response.data)

        })

    }, [])
    useEffect(() => {
        axios.get("https://car-api.redcode.app/report/totalmad/").then((response) => {
            setmad(response.data)

        })

    }, [])
    return (<>

        <div style={{ display: 'flex' }}>
            <div className="col-md-12 col-lg-12" dir="rtl"  >
                <h4 className="mb-3">ڕاپۆرتی پارەدان</h4>
                <hr className="my-4" />

                <div className='reportscroll' style={{ display: 'flex' }}>
                    <div className=" col-md-6 col-lg-6" dir="ltr">

                        <table className="table table-striped " dir="rtl">
                            <thead className='reportsctick'>
                                <tr class="table-dark">

                                    <th scope="col">ناوی کڕیار</th>
                                    <th scope="col">کۆی گشتی هەموو وەصڵەکان</th>


                                </tr>
                            </thead>
                            <tbody>
                                {wasl.map(e => {
                                    return <tr key={e.ID}>

                                        <td>{e.name}</td>
                                        <td>{e.aa}</td>


                                    </tr>
                                })}

                            </tbody>

                        </table>


                    </div>

                    <div className=" col-md-6 col-lg-6 " dir="ltr">

                        <table className="table table-striped print-container" dir="rtl">
                            <thead className='reportsctick'>
                                <tr class="table-dark">

                                    <th scope="col">ناوی کڕیار</th>
                                    <th scope="col">کۆی گشتی واصڵ</th>
                                    <th scope="col">کۆی گشتی هەموو داشکاندن</th>



                                </tr>
                            </thead>
                            <tbody>
                                {mad.map(e => {
                                    return <tr key={e.ID}>

                                        <td>{e.name}</td>
                                        <td>{e.bb}</td>
                                        <td>{e.cc}</td>

                                    </tr>
                                })}

                            </tbody>

                        </table>


                    </div>
                </div>

            </div>

        </div>
    </>
    )
}

export default Reportpay


{/* <div class="card border-primary mb-3" style={{ maxWidth: '100%' }}>
                    <div class="card-header" style={{ fontSize: "20px" }}>ڕاپۆرتی خەرجیەکان</div>
                    <div class="card-body text-primary">

                        <h5 class="card-title">کۆی گشتی خەرجی</h5>
                        <p class="card-text">$ {carlist.reduce((a, v) => a = a + v.usd, 0)}</p>
                        <h5 class="card-title">Primary card title</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <h5 class="card-title">Primary card title</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>

                </div> */}