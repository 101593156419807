import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { GrDocumentDownload } from "react-icons/gr";



function Readgwmrg() {

    const [customer, SetCustomer] = useState([])
    const [ID, setID] = useState("")


    const [gwmloc, setgwmloc] = useState("");
    const [carqty, setcarqty] = useState("");
    const [xarji, setxarji] = useState("");
    const [briq, setbriq] = useState("");
    const [brus, setbrus] = useState("");

    const [totaliq, settotaliq] = useState("");
    const [totalus, settotalus] = useState("");
    const [tebini, settebini] = useState("");
    const [datePick, SetDatePick] = useState("");



    // const [gumrg, setgumrg] = useState("");
    // const [naql, setnaql] = useState("");
    // const [m7asb, setm7asb] = useState("");


    // const [RAND, setRAND] = useState("");
    const [newmoney, setnewmoney] = useState("");
    const [paydate, setpaydate] = useState("");
    const [trans, settrans] = useState([]);

    // const [pays, setpays] = useState([1]);


    const [gumrgPick, setgumrgPick] = useState("");
    const [option, setOptions] = useState([]);
    useEffect(() => {
        axios.get("https://car-api.redcode.app/trans/getgwmrg/").then((response) => {
            settrans(response.data)
            // setCustomid(props.asman)
        });
    }, []);
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const updateQabz = (id) => {
        if (gwmloc === "") {
            customer.filter(e => e.id === ID).map(e => (setgwmloc(e.gwmrgloc)))
        }
        if (carqty === "") {
            customer.filter(e => e.id === ID).map(e => (setcarqty(e.sarfFor)))
        }
        if (xarji === "") {
            customer.filter(e => e.id === ID).map(e => (setxarji(e.xarji)))
        }
        if (briq === "") {
            customer.filter(e => e.id === ID).map(e => (setbriq(e.amount)))
        }
        if (datePick === "") {
            customer.filter(e => e.id === ID).map(e => (SetDatePick(e.datePick)))
        }
        if (tebini === "") {
            customer.filter(e => e.id === ID).map(e => (settebini(e.tebini)))
        }
        if (totaliq === "") {
            customer.filter(e => e.id === ID).map(e => (settotaliq(e.amountAll)))
        }
        if (brus === "") {
            customer.filter(e => e.id === ID).map(e => (setbrus(e.totalUS)))
        }
        if (totalus === "") {
            customer.filter(e => e.id === ID).map(e => (settotalus(e.amountAllUS)))
        }

    }

    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/gwmrg/updategwmrg/", {
            gwmloc: gwmloc,
            carqty: carqty,
            xarji: xarji,
            briq: briq,
            brus: brus,
            tebini: tebini,
            datePick: datePick,
            totaliq: totaliq,
            totalus: totalus,
            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }

    useEffect(() => {
        axios.get("https://car-api.redcode.app/gwmrg/allgwmrg/").then((response) => {
            SetCustomer(response.data)

        })

    }, [])

    const filterRows = () => {

        if (gumrgPick !== "") {
            axios.get(`https://car-api.redcode.app/gwmrg/select/${gumrgPick}/`).then((response) => {
                SetCustomer(response.data)
            })
        }

        else if (gumrgPick === "") {
            alert("تکایە گومرگ هەڵبژێرە")
        }
        // axios.delete(`https://car-api.redcode.app/deletecustomer/${e.customerId}`)

    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h4 className="mb-1">گومرگ</h4>
                        <div style={{ display: "flex", flexDirection: "row" }}>

                            <div className="">
                                <select name="car" onChange={e => setgumrgPick(e.target.value)} >
                                    <option value=""> ناونیشانی گومرگ</option>
                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </div>

                            <button type="button" onClick={filterRows} className="btn btn-dark me-3 ms-3">فلتەر</button>
                            <button type="button" onClick={() => window.location.reload(false)} disabled={gumrgPick === "" ? true : false} className="btn btn-success">هەموو گومرگەکان</button>

                        </div>
                    </div>
                    <table className="table table-striped mt-1">
                        <thead className='reportsctick'>
                            <tr class="table-dark">
                                <th scope="col">#</th>
                                <th scope="col">ناونیشانی گومرگ</th>
                                <th scope="col">ژمارەی سەیارە</th>
                                <th scope="col">خەرجی</th>
                                <th scope="col">بڕ دینار</th>
                                <th scope="col">بڕ دۆلار</th>
                                <th scope="col">کۆی گشتی دینار</th>
                                <th scope="col">کۆی گشتی دۆلار</th>
                                <th scope="col">تێبینی</th>
                                <th scope="col">کات</th>
                                <th scope="col"></th>


                                {/* <th scope="col">پارە بدە</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {customer.map(e => {
                                return <tr
                                // className={
                                // e.madfw3 === e.amountAllUS ? "table-success" : e.madfw3 > 0 ? "table-warning" : "table-danger"
                                // }
                                >
                                    <th scope="row">{e.id}</th>
                                    <td>{e.gwmrgloc}</td>
                                    <td>{e.sarfFor}</td>
                                    <td>{e.xarji}</td>
                                    <td>{e.amount}</td>
                                    <td>{e.totalUS}</td>
                                    <td>{e.amountAll}</td>
                                    <td>{e.amountAllUS}</td>
                                    <td>{e.tebini}</td>
                                    <td>{e.datePick}</td>

                                    {/* <td className="notPrint">
                                        <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                            onClick={() =>
                                                axios.get(`https://car-api.redcode.app/gwmrg/pay/${e.RAND}/`).then((response) => {
                                                    setpays(response.data)
                                                })
                                            }>پارە بدە</button>
                                        onClick={() => setID(e.id)}

                                    </td> */}

                                    {/* <td className="notPrint">
                                        <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay" onClick={() => setID(e.id)}>پارە بدە</button>

                                    </td> */}
                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>

                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">گومرگ دەسڕیتەوە؟</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            گومرگ ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/gwmrg/deletegwmrg/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    {/* <td className="notPrint" style={{ textAlign: "center" }}> <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.id)} ><GrDocumentDownload /></button>

                                    </td> */}
                                </tr>
                            })}

                            {/* <div className="modal fade" id="staticBackdroppay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body">
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label>

                                                    <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label>
                                                    <label htmlFor="exampleInputEmail1">پارەی ماوە : {
                                                        pays !== [1] ? pays[0].paraLeft : null
                                                    }</label>
                                                    <label htmlFor="exampleInputEmail1">پارەی مدفوع : {
                                                        pays !== [1] ? pays[0].madfw3 : null
                                                    }</label>
                                                </div>
                                                <hr className="my-4" />

                                                <div >
                                                    <form>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> پارە بدە</label>
                                                            <div className='pb-3' style={{ display: "flex" }}>
                                                                <input style={{ width: "80%" }} type="number" onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)} className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                                <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                                    onClick={() => {
                                                                        axios.post("https://car-api.redcode.app/gwmrg/creategwmrg/", {
                                                                            amount: pays !== [1] ? pays[0].amount : null,
                                                                            amountus: pays !== [1] ? pays[0].totalUS : null,
                                                                            sarfFor: pays !== [1] ? pays[0].sarfFor : null,
                                                                            amountAll: pays !== [1] ? pays[0].amountAll : null,
                                                                            amountAllUS: pays !== [1] ? pays[0].amountAllUS : null,
                                                                            datePick: pays !== [1] ? pays[0].datePick : null,
                                                                            paraLeft: (pays !== [1] ? pays[0].paraLeft : null) - (newmoney),
                                                                            RAND: RAND,
                                                                            xarji: pays !== [1] ? pays[0].xarji : null,
                                                                            note: pays !== [1] ? pays[0].tebini : null,
                                                                            madfw3: (newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null),

                                                                        }).then((res) => {
                                                                            window.location.reload(false)
                                                                        })
                                                                    }}



                                                                >پارە بدە</button>
                                                            </div>
                                                        </div>
                                                        <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null)}</h5>
                                                        <h5>پارەی ماوە تازە  :  {(pays !== [1] ? pays[0].paraLeft : null) - (newmoney)}</h5>

                                                        <div>
                                                            <hr className="my-4" />

                                                            < div style={{}}>
                                                                <div className="col-md-12 col-lg-12" dir="rtl">
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                        <h4 className="mb-1">پارەدانی پێشوو</h4>

                                                                    </div>

                                                                    <table className="table table-striped print-container">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">کۆی گشتی دینار</th>
                                                                                <th scope="col">کۆی گشتی دۆلار</th>
                                                                                <th scope="col">مدفوع</th>
                                                                                <th scope="col">ماوە</th>

                                                                                <th scope="col">کات</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {pays.slice(0).reverse().map(e => {
                                                                                return <tr key={e.id}>
                                                                                    <th scope="row"></th>
                                                                                    <td>{e.amountAll}</td>
                                                                                    <td>{e.amountAllUS} $</td>
                                                                                    <td>{e.madfw3}</td>
                                                                                    <td>{e.paraLeft}</td>

                                                                                    <td>{e.dateSql}</td>

                                                                                </tr>
                                                                            })}

                                                                        </tbody>

                                                                    </table>


                                                                </div>
                                                            </div >
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                        </div>
                                    </div>
                                </div>
                            </div> */}




                            <div className="modal fade print-container" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-fullscreen">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>
                                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body" >
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <div style={{ paddingTop: "20px", textAlign: "center" }}>
                                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.datePick}</h5>

                                                        </div>
                                                        <div style={{ marginRight: "150px", paddingTop: "50px", paddingBottom: "50px" }}>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم : {e.payer}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">About: {e.about}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">نقل : {e.naql}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.name}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">گومرگ : {e.gumrg}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.phonenum}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} /> */}
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> ئیمزای وەرگر</label>

                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}


                                                            </div>

                                                            <div className="form-group">

                                                                <label htmlFor="exampleInputEmail1">ئیمزای ژمێریار</label>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">{e.m7asb}</label>

                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                            </div>



                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer notPrint">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
                                            <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>

                                        </div>
                                    </div>
                                </div>
                            </div>






                            <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>گومرگ تازە کردنەوە</h5>

                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body">
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <form>
                                                            <div className="">

                                                                <select name="car" onChange={e => setgwmloc(e.target.value)} >
                                                                    <option value=""> ناونیشانی گومرگ</option>
                                                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ناونیشانی گومرگ</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.gwmrgloc} onChange={e => setgwmloc(e.target.value)} />
                                                            </div> */}
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ژمارەی سەیارە</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.sarfFor} onChange={e => setcarqty(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">
                                                                    خەرجی</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.xarji} onChange={e => setxarji(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">بڕ دینار</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.amount} onChange={e => setbriq(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">بڕ دۆلار</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.totalUS} onChange={e => setbrus(e.target.value)} />
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کۆی گشتی دینار</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.amountAll} onChange={e => settotaliq(e.target.value)} />
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.amountAllUS} onChange={e => settotalus(e.target.value)} />
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">
                                                                    تێبینی</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => settebini(e.target.value)} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کات</label>
                                                                <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => SetDatePick(e.target.value)} />
                                                            </div>


                                                        </form>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                        </div>
                                        <div className="modal-body">
                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>
                                                    <form style={{ color: "black" }}>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ناونیشانی گومرگ</label>
                                                            <h2>{gwmloc}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ژمارەی سەیارە</label>
                                                            <h2>{carqty}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">خەرجی</label>
                                                            <h2>{xarji}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">
                                                                بڕ دینار</label>
                                                            <h2>{briq}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">بڕ دۆلار</label>
                                                            <h2>{brus}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">
                                                                کۆی گشتی دینار</label>
                                                            <h2>{totaliq}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                            <h2>{totalus}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کات</label>
                                                            <h2>{datePick}</h2>                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1"> تێبینی</label>
                                                            <h2>{tebini}</h2>                                                        </div>


                                                    </form>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* 
                            <div className="modal fade" id="staticBackdroppay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body">
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <form>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                                                                <label htmlFor="exampleInputEmail1">وەصڵ : {e.waslNum}</label>

                                                                <label htmlFor="exampleInputEmail1">ناو : {e.payer}</label>
                                                                <label htmlFor="exampleInputEmail1">پارەی ماوە : {e.paraLeft}</label>
                                                            </div>
                                                            <hr className="my-4" />


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> پارە بدە</label>
                                                                <input type="number" onClick={() => setRAND(e.RAND)} className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.total_us} onChange={e => setbriq(e.target.value)} />
                                                            </div>

                                                        </form>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                axios.post("https://car-api.redcode.app/qabz/createqabz/", {
                                                    gwmloc: gwmloc,
                                                    carqty: carqty,
                                                    xarji: xarji,
                                                    briq: briq,
                                                    datePick: datePick,
                                                    gumrg: gumrg,
                                                    naql: naql,
                                                    brus: brus,
                                                    m7asb: m7asb,
                                                    waslNum: "",
                                                    paraleft: newmoney,
                                                    RAND: RAND,

                                                }).then((res) => {
                                                    window.location.reload(false)
                                                })
                                            }} >تازە کردنەوە</button>

                                        </div>
                                    </div>
                                </div>
                            </div> */}




                        </tbody>
                    </table>

                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}
                        <div className="row">

                            <h6 className="">کۆی خەرجی </h6>
                            {/* <small className="text-muted">١ دۆلار بەرامبەر دینار</small> */}

                            <span className="">{customer.reduce((a, v) => a = a + v.xarji, 0)}</span>
                            {/* <span className="">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span> */}
                        </div>

                        <div className="row">

                            <h6 className="">بڕی گومرگ دینار</h6>
                            {/* <small className="text-muted"> ڕۆژ و کات</small> */}

                            <span className="">{customer.reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>

                        <div className="row">

                            <h6 className="">بڕی گومرگ دۆلار</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {customer.reduce((a, v) => a = a + v.totalUS, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی دینار</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className=""> {customer.reduce((a, v) => a = a + v.amountAll, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی دۆلار </h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {customer.reduce((a, v) => a = a + v.amountAllUS, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی گشتی مدفوع</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {trans.filter((e => {
                                if (gumrgPick === "") {
                                    return e
                                } else if (e.RANDID.includes(gumrgPick)) {
                                    return e
                                }
                            })).reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی گشتی ماوە</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {customer.reduce((a, v) => a = a + v.amountAllUS, 0) - trans.filter((e => {
                                if (gumrgPick === "") {
                                    return e
                                } else if (e.RANDID.includes(gumrgPick)) {
                                    return e
                                }
                            })).reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>
                        {/* <div className="row">

                            <h6 className="">کۆی گشتی دینار</h6>
                            <small>دينار</small>

                            <span className="text-success">
                                {localStorage.getItem("webinter") === "1" ? (customer.reduce((a, v) => a = a + v.priceUSD + v.coc + v.naql, 0) * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"}
                            </span>
                        </div>

                        <div className="row">
                            <span>مجموع (USD)</span>
                            <strong>$20</strong>
                        </div> */}


                        {/* <form className="card p-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="رمز ترويجي" />
                                        <button type="submit" className="btn btn-secondary">تحقق</button>
                                    </div>
                                </form> */}

                        <button type="button" className="btn btn-primary  p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppayall"
                            disabled={gumrgPick === "" ? true : false}
                        // onClick={() =>
                        //     axios.get(`https://car-api.redcode.app/gwmrg/pay`).then((response) => {
                        //         setpays(response.data)
                        //     })
                        // }
                        >پارە بدە</button>
                    </div>


                    <div className="modal fade" id="staticBackdroppayall" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            {/* <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label> */}

                                            {/* <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label> */}
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                                $ {customer.reduce((a, v) => a = a + v.amountAllUS, 0) - trans.filter((e => {
                                                    if (gumrgPick === "") {
                                                        return e
                                                    } else if (e.RANDID.includes(gumrgPick)) {
                                                        return e
                                                    }
                                                })).reduce((a, v) => a = a + v.amount, 0)}
                                            </label>
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                                $ {trans.filter((e => {
                                                    if (gumrgPick === "") {
                                                        return e
                                                    } else if (e.RANDID.includes(gumrgPick)) {
                                                        return e
                                                    }
                                                })).reduce((a, v) => a = a + v.amount, 0)}
                                            </label>
                                        </div>
                                        <hr className="my-4" />

                                        <div >
                                            <form>


                                                {/* {(newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null)} <p></p>
                                                        {(pays !== [1] ? pays[0].paraLeft : null) - (newmoney)
                                                        } */}

                                                <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>

                                                    <div className='pb-3' style={{ flex: "2", display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> پارە بدە</label>

                                                        <input style={{ width: "80%" }} type="number"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> کات</label>

                                                        <input style={{ width: "80%" }} type="date"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setpaydate(e.target.value)} />

                                                        <div className="pt-2">

                                                            <select style={{ fontWeight: "700", fontSize: "16px" }} name="car" onChange={e => setgumrgPick(e.target.value)} >
                                                                <option value=""> ناونیشانی گومرگ</option>
                                                                {option.filter(e => e.OPkey === 5).map((e) => {
                                                                    return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                                })}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <button style={{ width: "20%" }} type="button" className="btn btn-dark" disabled={gumrgPick === "" ? true : false}
                                                        onClick={() => {
                                                            axios.post("https://car-api.redcode.app/trans/gwmrg/", {
                                                                transtype: 3,
                                                                amount: newmoney,
                                                                RANDID: gumrgPick,
                                                                datePick: paydate

                                                            }).then((res) => {
                                                                window.location.reload(false)
                                                            })
                                                        }}

                                                    >پارە بدە</button>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + trans.filter((e => {
                                                    if (gumrgPick === "") {
                                                        return e
                                                    } else if (e.RANDID.includes(gumrgPick)) {
                                                        return e
                                                    }
                                                })).reduce((a, v) => a = a + v.amount, 0)}</h5>
                                                <h5>پارەی ماوە تازە  :  {customer.reduce((a, v) => a = a + v.amountAllUS, 0) - trans.filter((e => {
                                                    if (gumrgPick === "") {
                                                        return e
                                                    } else if (e.RANDID.includes(gumrgPick)) {
                                                        return e
                                                    }
                                                })).reduce((a, v) => a = a + v.amount, 0) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    < div style={{}}>
                                                        <div className="col-md-12 col-lg-12" dir="rtl">
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <h4 className="mb-1">پارەدانی پێشوو</h4>

                                                            </div>

                                                            <table className="table table-striped print-container">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>

                                                                        <th scope="col">کۆی گشتی دۆلار</th>

                                                                        <th scope="col">کات</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {trans.filter((e => {
                                                                        if (gumrgPick === "") {
                                                                            return e
                                                                        } else if (e.RANDID.includes(gumrgPick)) {
                                                                            return e
                                                                        }
                                                                    })).map(e => {
                                                                        return <tr key={e.id}>
                                                                            <th scope="row"></th>
                                                                            <td>{e.amount} $</td>


                                                                            <td>{e.datePick}</td>
                                                                            <td className="notPrint"><button type="button" className="btn btn-danger btn-sm p-1 " data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={() => setID(e.id)}><AiFillDelete /></button>


                                                                            </td>
                                                                        </tr>
                                                                    })}

                                                                </tbody>

                                                            </table>


                                                        </div>
                                                    </div >
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalToggleLabel23" aria-hidden="true">
                        <div className="modal-dialog modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalToggleLabel23">بڕی پارەدان دەسڕیتەوە؟</h5>

                                </div>
                                <div className="modal-body">
                                    <h5>

                                        بڕی پارەدان  دەسڕێتەوە
                                    </h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/trans/deletetrans/${ID}`).then((res) => {
                                        window.location.reload(false)
                                    })}
                                    >سڕینەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="col-md-5 col-lg-4 order-md-last print-containerr">

                        <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Today Dollar</h6>
                                    <small className="text-muted">١ دۆلار بەرامبەر دینار</small>
                                </div>
                                <span className="text-muted">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span>
                            </li>

                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Date / Time</h6>
                                    <small className="text-muted"> ڕۆژ و کات</small>
                                </div>
                                <span className="text-muted">{new Date().toLocaleString() + ""}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Total Dollar</h6>
                                    <small className="text-muted"></small>
                                </div>
                                <span className="text-muted">{customer.reduce((a, v) => a = a + v.total_us + v.naql, 0)}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between bg-light">
                                <div className="text-success">
                                    <h6 className="my-0">Total Dinar</h6>
                                    <small>دينار</small>

                                </div>
                                <span className="text-success">-$5</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span>مجموع (USD)</span>
                                <strong>$20</strong>
                            </li>
                        </ul>

                        <form className="card p-2">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="رمز ترويجي" />
                                <button type="submit" className="btn btn-secondary">تحقق</button>
                            </div>
                        </form>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Readgwmrg
