import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";


function Readrasmal() {
    const [filterSearch, setFilterSearch] = useState("")
    const [carlist, setCarList] = useState([])

    const [ID, setID] = useState("")

    const [option, setOptions] = useState([]);



    const [enginePick, setenginePick] = useState("");


    const [datePick, setDatePick] = useState("");
    const [priceUS, setpriceUS] = useState("");

    const [customerID, setCustomerID] = useState("");
    const [customerphone, setCustomerphone] = useState("");


    const [newmoney, setnewmoney] = useState("");

    const [gumrgPick, setgumrgPick] = useState([1, 2]);

    const [paydate, setpaydate] = useState("");

    const [paylist, setpaylist] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
        });
    }, []);

    const [trans, settrans] = useState([]);
    useEffect(() => {
        axios.get("https://car-api.redcode.app/trans/getqarz/").then((response) => {
            settrans(response.data)
        });
    }, []);

    const updateQabz = (id) => {
        if (customerID === "") {
            carlist.filter(e => e.id === ID).map(e => (setCustomerID(e.name)))
        }
        if (customerphone === "") {
            carlist.filter(e => e.id === ID).map(e => (setCustomerphone(e.tebini)))
        }
        if (enginePick === "") {
            carlist.filter(e => e.id === ID).map(e => (setenginePick(e.qarztype)))
        }
        if (priceUS === "") {
            carlist.filter(e => e.id === ID).map(e => (setpriceUS(e.total)))
        }
        if (datePick === "") {
            carlist.filter(e => e.id === ID).map(e => (setDatePick(e.datePick)))
        }
    }

    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/coc/updaterasmal/", {
            customerID: customerID,
            customerphone: customerphone,
            enginePick: enginePick,
            priceUS: priceUS,
            datePick: datePick,
            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }
    useEffect(() => {
        axios.get("https://car-api.redcode.app/coc/allqarz/").then((response) => {
            setCarList(response.data)



        })

    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "5px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h4 className="mb-1">ڕاپۆرت قەرزداری کۆمپانیا</h4>

                        <div className="col-md-2 pe-4">

                            <label htmlFor="cc-number" className="form-label "> گەڕان بەدوای ناو</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>


                    </div>

                    <table className="table table-striped print-container">
                        <thead>
                            <tr>
                                <th scope="col">#</th>


                                <th scope="col">ناو</th>

                                <th scope="col">بڕی دۆلار</th>


                                <th scope="col"> تێبینی</th>



                                <th scope="col">کات </th>
                                <th scope="col" className="notPrint"></th>
                                <th scope="col" className="notPrint"></th>



                                {/* <th scope="col" className="notPrint"></th>
                                <th scope="col" className="notPrint">دۆکیومێنت</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {carlist.filter((e => {
                                if (filterSearch === "") {
                                    return e
                                } if (e.name.includes(filterSearch)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr className={

                                    trans.filter((eve => {
                                        if (eve.RANDID === "") {
                                            return 0
                                        } else if (eve.RANDID.includes(e.id)) {
                                            return eve
                                        }
                                    })).reduce((a, v) => a = a + v.amount, 0) === e.total ? "table-success" : trans.filter((eve => {
                                        if (eve.RANDID === "") {
                                            return 0
                                        } else if (eve.RANDID.includes(e.id)) {
                                            return eve
                                        }
                                    })).reduce((a, v) => a = a + v.amount, 0) > 0 ? "table-warning" : "table-danger"

                                }>
                                    <th scope="row">{e.id}</th>

                                    <td>{e.name}</td>


                                    <td>{e.total}</td>

                                    <td>{e.tebini}</td>

                                    <td>{e.datePick}</td>

                                    <td className="notPrint">
                                        {/* // {e.madfw3 === e.total ? null : */}
                                        <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                            onClick={() =>
                                                axios.get(`https://car-api.redcode.app/coc/payselect/${e.id}/`).then((response) => {
                                                    setgumrgPick(response.data)
                                                })
                                            }>پارە بدە</button>


                                    </td>
                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">قەرزداری کۆمپانیا دەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            قەرزداری کۆمپانیا ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/coc/deleterasmal/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>



                                </tr>
                            })}

                        </tbody>
                    </table>

                    <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>وەبەرهێنانی کۆمپانیا تازە کردنەوە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        {carlist.filter(e => e.id === ID).map(e =>
                                        (
                                            <div key={e.id}>
                                                <form>
                                                    <td>
                                                        <select name="car" onChange={e => setenginePick(e.target.value)} >
                                                            <option value="">جۆری رَسْمال بگۆڕە</option>
                                                            {option.filter(e => e.OPkey === 8).map((e) => {
                                                                return <option value={e.ID}>{e.OPvalue}</option>
                                                            })}
                                                        </select>
                                                    </td>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            ناو </label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.name} onChange={e => setCustomerID(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            بڕی دۆلار	</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.total} onChange={e => setpriceUS(e.target.value)} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">تێبینی	</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => setCustomerphone(e.target.value)} />
                                                    </div>


                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کات</label>
                                                        <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => setDatePick(e.target.value)} />
                                                    </div>




                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                </div>
                                <div className="modal-body">
                                    {carlist.filter(e => e.id === ID).map(e =>
                                    (
                                        <div key={e.id}>
                                            <form style={{ color: "black" }}>

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ناو</label>
                                                    <h2>{customerID}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">بڕی دۆلار		</label>
                                                    <h2>{priceUS}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">تێبینی	</label>
                                                    <h2>{customerphone}</h2>                                                        </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">کاتی دەرچوون</label>
                                                    <h2>{datePick}</h2>                                                        </div>
                                            </form>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="modal fade" id="staticBackdroppay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            {/* <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label> */}

                                            {/* <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label> */}
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                                $  {gumrgPick.map(e => e.aa)}
                                            </label>
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                                $ {gumrgPick.map(e => e.bb)}
                                            </label>

                                        </div>
                                        <hr className="my-4" />
                                        <div >
                                            <form>


                                                <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>

                                                    <div className='pb-3' style={{ flex: "2", display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> بڕی پارە</label>

                                                        <input style={{ width: "80%" }} type="number"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> کات</label>

                                                        <input style={{ width: "80%" }} type="date"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setpaydate(e.target.value)} />


                                                    </div>
                                                    <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                        onClick={() => {
                                                            axios.post("https://car-api.redcode.app/trans/gwmrg/", {
                                                                transtype: 8,
                                                                amount: newmoney,
                                                                RANDID: gumrgPick.map(e => e.id),
                                                                datePick: paydate

                                                            }).then((res) => {
                                                                window.location.reload(false)
                                                            })
                                                        }}

                                                    >پارە بدە</button>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + (newmoney !== "" ? JSON.parse(gumrgPick.map(e => e.bb)) : null)}
                                                </h5>
                                                <h5>پارەی ماوە تازە  :   {gumrgPick.map(e => e.aa) - gumrgPick.map(e => e.bb) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    <button type='button' class="btn btn-success" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" onClick={() => axios.get(`https://car-api.redcode.app/trans/qarzlist/${gumrgPick.map(e => e.id)}/`).then((response) => {
                                                        setpaylist(response.data)
                                                    })}>لیستی پارەدان ببینە</button>




                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalToggleLabel2">لیستی پارەدان </h5>

                                </div>
                                <div class="modal-body">
                                    <table className="table table-striped print-container">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>

                                                <th scope="col">کۆی گشتی دۆلار</th>

                                                <th scope="col">کات</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paylist.map(e => {
                                                return <tr key={e.id}>
                                                    <th scope="row"></th>
                                                    <td>{e.amount} $</td>


                                                    <td>{e.datePick}</td>
                                                    <td className="notPrint"><button type="button" className="btn btn-danger btn-sm p-1 " data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={() => setID(e.id)}><AiFillDelete /></button>


                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>

                                    </table>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" data-bs-target="#staticBackdroppayall" data-bs-toggle="modal">گەڕانەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalToggleLabel23" aria-hidden="true">
                        <div className="modal-dialog modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalToggleLabel23">بڕی پارەدان دەسڕیتەوە؟</h5>

                                </div>
                                <div className="modal-body">
                                    <h5>

                                        بڕی پارەدان  دەسڕێتەوە
                                    </h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/trans/deletetrans/${ID}`).then((res) => {
                                        window.location.reload(false)
                                    })}
                                    >سڕینەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}


                        <div className="row">

                            <h6 className="">کۆی گشتی مدفوع</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {trans.reduce((a, v) => a = a + v.amount, 0)}</span>
                        </div>
                        <div className="row">

                            <h6 className="">کۆی گشتی ماوە</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$
                                {carlist.reduce((a, v) => a = a + v.total, 0) - trans.reduce((a, v) => a = a + v.amount, 0)}
                            </span>
                        </div>

                        <div className="row">

                            <h6 className="">کۆی دۆلار </h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {carlist.reduce((a, v) => a = a + v.total, 0)}</span>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Readrasmal



