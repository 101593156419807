import React, { useState } from 'react';
import axios from "axios"


function Createariza() {
    const [bo, setbo] = useState("");
    const [babat, setbabat] = useState("");
    const [nawarok, setnawarok] = useState("");
    const [tebini, settebini] = useState("");
    const [date, setDate] = useState("");


    const createCoc = async () => {
        axios.post("https://car-api.redcode.app/expense/createariza/", {
            bo: bo,
            babat: babat,
            nawarok: nawarok,
            tebini: tebini,
            date: date,
        }).then((res) => {
            window.location.reload(false)
            alert(" دروست کرا")
        })
        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-10 col-lg-10" dir="rtl">
                <h4 className="mb-3">عریضة زیاد بکە</h4>
                <form className="needs-validation" >
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <label htmlFor="firstName" className="form-label">بۆ</label>
                            <input onChange={e => setbo(e.target.value)} type="text" className="form-control" id="firstName" placeholder="" />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="lastName" className="form-label">
                                بابەت</label>
                            <input onChange={e => setbabat(e.target.value)} type="text" className="form-control" id="lastName" placeholder="" />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="lastName" className="form-label">
                                ناوەڕۆک</label>
                            <textarea onChange={e => setnawarok(e.target.value)} type="text" className="form-control" id="lastName" placeholder="" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">تێبینی </label>
                            <textarea onChange={e => settebini(e.target.value)} type="number" className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کات</label>
                            <input type="date" onChange={e => setDate(e.target.value)} className="form-control" id="address" placeholder="" />
                        </div>
                        <hr className="my-4" />
                        <button className="w-100 btn btn-dark btn-lg" onClick={createCoc} type="button">عریضة زیاد بکە</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Createariza
