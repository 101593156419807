import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
// import { GrDocumentDownload } from "react-icons/gr";


function Readiran() {
    const [filterSearch, setFilterSearch] = useState("")

    const [customer, SetCustomer] = useState([])
    const [ID, setID] = useState("")

    const [carPick, setCarPick] = useState("");

    const [priceIQ, setpriceIQ] = useState("");
    const [priceUS, setpriceUS] = useState("");
    const [driver, setdriver] = useState("");
    const [drivermob, setdrivermob] = useState("");
    const [driverprice, setdriverprice] = useState("");
    const [coc, setcoc] = useState("");
    const [baqi, setbaqi] = useState("");

    const [datepick, setdatepick] = useState("");


    const updateQabz = (id) => {
        if (carPick === "") {
            customer.filter(e => e.id === ID).map(e => (setCarPick(e.carqty)))
        }
        if (driver === "") {
            customer.filter(e => e.id === ID).map(e => (setdriver(e.drivername)))
        }
        if (drivermob === "") {
            customer.filter(e => e.id === ID).map(e => (setdrivermob(e.driverphone)))
        }
        if (driverprice === "") {
            customer.filter(e => e.id === ID).map(e => (setdriverprice(e.drivermoney)))
        }
        if (priceIQ === "") {
            customer.filter(e => e.id === ID).map(e => (setpriceIQ(e.tman)))
        }
        if (priceUS === "") {
            customer.filter(e => e.id === ID).map(e => (setpriceUS(e.dolar)))
        }
        if (coc === "") {
            customer.filter(e => e.id === ID).map(e => (setcoc(e.xasara)))
        }
        if (baqi === "") {
            customer.filter(e => e.id === ID).map(e => (setbaqi(e.tebini)))
        }
        if (datepick === "") {
            customer.filter(e => e.id === ID).map(e => (setdatepick(e.datePick)))
        }

    }

    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/baxira/updateiran/", {
            carPick: carPick,
            driver: driver,
            drivermob: drivermob,
            driverprice: driverprice,
            priceIQ: priceIQ,
            priceUS: priceUS,
            coc: coc,
            baqi: baqi,
            datepick: datepick,
            ID: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }




    useEffect(() => {
        axios.get("https://car-api.redcode.app/baxira/alliran/").then((response) => {
            SetCustomer(response.data)
        })

    }, [])


    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h4 className="mb-1">ئێران</h4>
                        <div className="col-md-4" style={{ padding: "0px 50px" }}>

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای ناو</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>

                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ژمارەی سەیارە</th>
                                <th scope="col">ناوی شۆفێر</th>
                                <th scope="col">ژمارەی شۆفێر</th>
                                <th scope="col">پارەی شۆفێر</th>
                                <th scope="col">تمن</th>
                                <th scope="col">دۆلار</th>
                                <th scope="col">خسارە</th>


                                <th scope="col">کات</th>
                                <th scope="col">تێبینی</th>

                            </tr>
                        </thead>
                        <tbody>
                            {customer.filter((e => {
                                if (filterSearch === "") {
                                    return e
                                } else if (e.drivername.includes(filterSearch)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr>
                                    <th scope="row">{e.id}</th>
                                    <td>{e.carqty}</td>
                                    <td>{e.drivername}</td>
                                    <td>{e.driverphone}</td>
                                    <td>{e.drivermoney}</td>

                                    <td>{e.tman}</td>
                                    <td>{e.dolar}</td>


                                    <td>{e.xasara}</td>


                                    <td>{e.datePick}</td>
                                    <td>{e.tebini}</td>
                                    <td className="notPrint">
                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.id)}><BiEditAlt /></button>

                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.id)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">ئێران دەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            ئێران ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/baxira/deleteiran/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    {/* <td className="notPrint" style={{ textAlign: "center" }}> <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.id)} ><GrDocumentDownload /></button>

                                    </td> */}
                                </tr>
                            })}







                            <div className="modal fade print-container" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-fullscreen">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>
                                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body" >
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <div style={{ paddingTop: "20px", textAlign: "center" }}>
                                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.datePick}</h5>

                                                        </div>
                                                        <div style={{ marginRight: "150px", paddingTop: "50px", paddingBottom: "50px" }}>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم : {e.payer}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">About: {e.about}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">نقل : {e.naql}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.name}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">گومرگ : {e.gumrg}</label>
                                                                    <label htmlFor="exampleInputEmail1">{e.phonenum}</label>
                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} /> */}
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> ئیمزای وەرگر</label>

                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} /> */}
                                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}


                                                            </div>

                                                            <div className="form-group">

                                                                <label htmlFor="exampleInputEmail1">ئیمزای ژمێریار</label>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">{e.m7asb}</label>

                                                                    {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} /> */}
                                                                </div>
                                                                {/* <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} /> */}
                                                            </div>



                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer notPrint">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
                                            <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>

                                        </div>
                                    </div>
                                </div>
                            </div>






                            <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ئێران تازە کردنەوە</h5>

                                        </div>
                                        <div className="modal-body" style={{ color: "black" }}>
                                            <div className="modal-body">
                                                {customer.filter(e => e.id === ID).map(e =>
                                                (
                                                    <div key={e.id}>
                                                        <form>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.carqty} onChange={e => setCarPick(e.target.value)} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">ناوی شۆفێر</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.drivername} onChange={e => setdriver(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> ژمارەی شۆفێر</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.driverphone} onChange={e => setdrivermob(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> پارەی شۆفێر</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.drivermoney} onChange={e => setdriverprice(e.target.value)} />
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">
                                                                    تمن</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.tman} onChange={e => setpriceIQ(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">دۆلار</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.dolar} onChange={e => setpriceUS(e.target.value)} />
                                                            </div>



                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"> خسارە</label>
                                                                <input type="number" className="form-control" aria-describedby="emailHelp" placeholder={e.xasara} onChange={e => setcoc(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                                <input type="text" className="form-control" aria-describedby="emailHelp" placeholder={e.tebini} onChange={e => setbaqi(e.target.value)} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">کات</label>
                                                                <input type="date" className="form-control" aria-describedby="emailHelp" placeholder={e.datePick} onChange={e => setdatepick(e.target.value)} />
                                                            </div>

                                                        </form>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateQabz} >تازە کردنەوە</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                        </div>
                                        <div className="modal-body">
                                            {customer.filter(e => e.id === ID).map(e =>
                                            (
                                                <div key={e.id}>
                                                    <form style={{ color: "black" }}>
                                                        <div className='yousure'>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">ژمارەی سەیارەکان</label>
                                                                    <h2>{carPick}</h2>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">
                                                                        تمن</label>
                                                                    <h2>{priceIQ}</h2>                                                        </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">دۆلار</label>
                                                                    <h2>{priceUS}</h2>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">ناوی شۆفێر</label>
                                                                    <h2>{driver}</h2>                                                        </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1"> کات</label>
                                                                    <h2>{datepick}</h2>                                                        </div>
                                                            </div>
                                                            <div>

                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1"> ژمارەی شۆفێر</label>
                                                                    <h2>{drivermob}</h2>                                                        </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1"> پارەی شۆفێر</label>
                                                                    <h2>{driverprice}</h2>                                                        </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1"> خسارە</label>
                                                                    <h2>{coc}</h2>                                                        </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1"> تێبینی</label>
                                                                    <h2>{baqi}</h2>                                                        </div>


                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                            <button type="button" className="btn btn-primary" onClick={updateQuery} >تازە کردنەوە</button>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </tbody>
                    </table>

                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                        {/* bg-dark text-white */}
                        <div className="row">

                            <h6 className="">کۆی ژمارەی سەیارە</h6>
                            {/* <small className="text-muted">١ دۆلار بەرامبەر دینار</small> */}

                            <span className="">{customer.reduce((a, v) => a = a + v.carqty, 0)}</span>
                            {/* <span className="">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span> */}
                        </div>

                        <div className="row">

                            <h6 className="">کۆی پارەی شۆفێر</h6>
                            {/* <small className="text-muted"> ڕۆژ و کات</small> */}

                            <span className="">${customer.reduce((a, v) => a = a + v.drivermoney, 0)}</span>
                        </div>

                        <div className="row">

                            <h6 className="">کۆی خسارە</h6>
                            {/* <small className="text-muted"></small> */}

                            <span className="">$ {customer.reduce((a, v) => a = a + v.xasara, 0)}</span>
                        </div>

                        {/* <div className="row">

                            <h6 className="">کۆی گشتی دینار</h6>
                            <small>دينار</small>

                            <span className="text-success">
                                {localStorage.getItem("webinter") === "1" ? (customer.reduce((a, v) => a = a + v.priceUSD + v.coc + v.naql, 0) * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"}
                            </span>
                        </div>

                        <div className="row">
                            <span>مجموع (USD)</span>
                            <strong>$20</strong>
                        </div> */}


                        {/* <form className="card p-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="رمز ترويجي" />
                                        <button type="submit" className="btn btn-secondary">تحقق</button>
                                    </div>
                                </form> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Readiran
