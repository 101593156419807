import React, { useState, useEffect } from 'react'
import 'react-clock/dist/Clock.css';
import 'react-calendar/dist/Calendar.css';
import Reportpay from './report/Reportpay';

function Home() {
    const [priceIQ, setpriceIQ] = useState("");
    const [priceUS, setpriceUS] = useState("");
    const [value, setValue] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(
            () => setValue(new Date()),
            1000
        );

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div style={{ padding: "20px" }} dir='rtl'>
            <h1 >بەخێربێیت!</h1>
            <div dir='rtl' style={{ display: 'flex', justifyContent: "space-between", padding: "20px", paddingRight: "50px", height: "70vh" }}>
                <br />
                <div className="col-md-12 col-lg-12 homee" dir="rtl" >
                    <div style={{ width: "60%" }}>
                        <Reportpay />
                    </div>
                    <div style={{ width: "30%" }} >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <h5 className="mb-3">دینار بەرامبەر دۆلار</h5>
                            <div className="col-md-12">
                                <label className="form-label">نرخ دینار</label>
                                <input type="number" onChange={e => setpriceIQ(e.target.value)} className="form-control" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">نرخ دۆلار</label>
                                <input type="number" onChange={e => setpriceUS(e.target.value)} className="form-control" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
