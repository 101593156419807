import React, { useState, useEffect } from 'react';
import axios from "axios"

function Createkrin() {



    const [loc, setloc] = useState("");
    const [price, setprice] = useState(0);
    const [masraf, setmasraf] = useState(0);
    const [partner, setpartner] = useState("");
    const [car, setcar] = useState("");
    const [vinnum, setvinnum] = useState("");
    const [tebini, settebini] = useState("");
    const [date, setDate] = useState("");





    const createCustomer = async () => {
        axios.post("https://car-api.redcode.app/krin/createkrin/", {
            loc: loc,
            price: price,
            masraf: masraf,
            partner: partner,
            car: car,
            vinnum: vinnum,
            date: date,
            tebini: tebini,

        }).then((res) => {
            window.location.reload(false)
            alert(" زیاد کرا")

        })


        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)

    }

    const [option, setOptions] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);



    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-10 col-lg-10" dir="rtl">
                <h4 className="mb-3">کڕینی کۆمپانیا </h4>
                <div style={{ display: "flex", flexDirection: "row" }}>



                    <td>
                        <select name="car" onChange={e => setloc(e.target.value)} >
                            <option value="">ناونیشان</option>
                            {option.filter(e => e.OPkey === 10).map((e) => {
                                return <option value={e.OPvalue}>{e.OPvalue}</option>
                            })}
                        </select>
                    </td>


                </div>
                <form className="needs-validation" >
                    <div className="row g-3">

                        <div className="col-sm-6">
                            <label htmlFor="firstName" className="form-label">نرخی سەیارە</label>
                            <input onChange={e => setprice(+e.target.value)} type="text" className="form-control" id="firstName" placeholder="" />
                        </div>


                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">مصرف </label>
                            <input onChange={e => setmasraf(+e.target.value)} type="number" className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">کۆی گشتی </label>
                            <input type="number" className="form-control" id="cc-number" value={price + masraf} disabled />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">هاوبەش </label>
                            <input onChange={e => setpartner(e.target.value)} type="text" className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">جۆری سەیارە </label>
                            <input onChange={e => setcar(e.target.value)} type="text" className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="cc-number" className="form-label">رقم شانصی </label>
                            <input onChange={e => setvinnum(e.target.value)} type="text" className="form-control" id="cc-number" placeholder="" />
                        </div>





                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کات</label>
                            <input type="date" onChange={e => setDate(e.target.value)} className="form-control" id="address" placeholder="" />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">تێبینی</label>
                            {/* <span className="text-muted">(اختياري)</span> */}
                            <textarea onChange={e => settebini(e.target.value)} className="form-control" id="email" />
                        </div>
                        <button className="w-100 btn btn-dark btn-lg" onClick={createCustomer} type="button"> زیاد بکە</button>


                        <hr className="my-4" />
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Createkrin
