import React, { useEffect, useState } from 'react';
import axios from "axios"

function CreateExpenses() {
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const [option, setOptions] = useState([]);
    const [usedFor, setUsedFor] = useState("");
    const [desc, setDesc] = useState("");
    const [priceIQ, setpriceIQ] = useState("");
    const [priceUS, setpriceUS] = useState("");
    const [datePick, SetDatePick] = useState("");
    const [optionnn, setOptionnn] = useState("");

    const createExpense = () => {
        axios.post("https://car-api.redcode.app/expense/createexpense/", {
            desc: desc,
            usedFor: usedFor,
            datePick: datePick,
            priceIQ: priceIQ === "" ? localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka" : priceIQ,
            priceUS: priceUS === "" ? localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka" : priceUS,
        })
            .then((res) => {
                window.location.reload(false)
                alert(" دروست کرا")

            })
    };
    const createUsedFor = () => {
        axios.post("https://car-api.redcode.app/expense/createoptionnn/", {
            optionnn: optionnn,
        })
            .then((res) => {
                window.location.reload(false)
            })
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
                <div className="col-md-10 col-lg-10" dir="rtl" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>

                    <form className="needs-validation" >
                        <h4 className="mb-3">خەرجی</h4>
                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <td>
                                <select onChange={e => setUsedFor(e.target.value)} name="car" required>
                                    <option value="">بەکارهاتووە بۆ</option>
                                    {option.filter(e => e.OPkey === 4).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <div className="col-lg-12">
                                <label htmlFor="firstName" className="form-label">تێبینی</label>
                                <input type="text" onChange={e => setDesc(e.target.value)} className="form-control" id="firstName" placeholder="" required />
                            </div>

                            <div className="col-sm-12">
                                <label htmlFor="firstName" className="form-label">نرخ دینار</label>
                                <input type="number" onChange={e => setpriceIQ(e.target.value)} className="form-control" id="firstName" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} required />
                            </div>
                            <div className="col-sm-12">
                                <label htmlFor="firstName" className="form-label">نرخ دۆلار</label>
                                <input type="number" onChange={e => setpriceUS(e.target.value)} className="form-control" id="firstName" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} required />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => SetDatePick(e.target.value)} className="form-control" id="address" placeholder="1234 الشارع الأول" required />

                            </div>
                            <button type="button" onClick={createExpense} className="btn btn-dark mt-3">خەرجی زیاد بکە</button>

                        </div>
                    </form>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4 className="mb-3">بابەت زیاد بکە</h4>
                        <div className="col-sm-12">
                            {/* <label htmlFor="firstName" className="form-label">used htmlFor</label> */}
                            <input type="text" onChange={e => setOptionnn(e.target.value)} className="form-control" id="firstName" placeholder="" required />
                            <button type="button" onClick={createUsedFor} className="btn btn-dark mt-3"> زیاد بکە</button>

                        </div>
                    </div>




                </div>







            </div>
        </div>
    )
}

export default CreateExpenses
