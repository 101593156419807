import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import img from "./aa.jpg"
import back from "./aa.png"
function Printdoc() {
    const location = useLocation()
    const { datas } = location.state
    // console.log(datas)

    const [carPick, setCarPick] = useState(datas.carPick);
    const [colorPick, setColorPick] = useState(datas.colorPick);
    const [enginePick, setenginePick] = useState(datas.enginePick);
    const [modelpick, setmodelpick] = useState(datas.modelPick);
    const [vinNumber, setvinNumber] = useState(datas.vinNum);
    const [tempNum, settempNum] = useState(datas.carNum);
    const [tajr, settajr] = useState("کۆمپانیای مظفر عبدالرحمن نجم");
    const [balance, setbalance] = useState("07501264596");
    const [qazanj, setqazanj] = useState(datas.cname);
    const [naql, setnaql] = useState(datas.cphone);
    const [loc, setloc] = useState(datas.cloc);
    const [gwmrg, setgwmrg] = useState(datas.gwmrgPick);
    const [gwmnum, setgwmnum] = useState(datas.gwmrgNum);
    const [dategwm, setdategwm] = useState(datas.gwmrgDate);
    const [customerID, setCustomerID] = useState(datas.id);
    const [datePick, setDatePick] = useState(datas.datePick);

    return (<>
        <div dir="rtl" className='notPrint' style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>

            <div className="row">


                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ناوی لایەنی یەکەم</label>

                    <input type="text" onChange={e => settajr(e.target.value)} className="form-control" id="address" placeholder={tajr} />

                </div>
                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ژمارەی تەلەفۆن</label>

                    <input type="number" onChange={e => setbalance(e.target.value)} className="form-control" id="address" placeholder={balance} />

                </div>
                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ناوی لایەنی دووەم</label>

                    <input type="text" onChange={e => setqazanj(e.target.value)} className="form-control" id="address" placeholder={qazanj} />

                </div>
                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ژمارەی تەلەفۆن</label>

                    <input type="number" onChange={e => setnaql(e.target.value)} className="form-control" id="address" placeholder={naql} />

                </div>
                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ناونیشانی کڕیار</label>

                    <input type="text" onChange={e => setloc(e.target.value)} className="form-control" id="address" placeholder={loc} />

                </div>

                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">جۆری سەیارە</label>


                    <input type="text" onChange={e => setCarPick(e.target.value)} className="form-control" id="cc-number" placeholder={carPick} />
                </div>
                <div className="col-md-1">

                    <label htmlFor="lastName" className="form-label">ڕەنگ</label>



                    <input type="text" onChange={e => setColorPick(e.target.value)} className="form-control" id="cc-number" placeholder={colorPick} />
                </div>

                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">ژمارە شانصی</label>

                    <input type="text" onChange={e => setvinNumber(e.target.value)} className="form-control" id="lastName" placeholder={vinNumber} />
                </div>
                <div className="col-md-1">
                    <label htmlFor="lastName" className="form-label">ژمارە کاتی</label>

                    <input type="text" onChange={e => settempNum(e.target.value)} className="form-control" id="cc-number" placeholder={tempNum} />
                </div>

                <div className="col-md-1">
                    <label htmlFor="lastName" className="form-label">مۆدێل</label>

                    <input type="number" onChange={e => setmodelpick(e.target.value)} className="form-control" id="address" placeholder={modelpick} />

                </div>
                <div className="col-md-1">
                    <label htmlFor="lastName" className="form-label">پستۆن</label>

                    <input type="number" onChange={e => setenginePick(e.target.value)} className="form-control" id="address" placeholder={enginePick} />
                </div>
                <div className="col-md-2" >
                    <label style={{ paddingLeft: "4px" }} htmlFor="address" className="form-label">كات</label>
                    <input type="date" onChange={e => setDatePick(e.target.value)} className="form-control" id="address" />
                </div>
                <div className="col-md-2">
                    <label htmlFor="lastName" className="form-label">گومرگ</label>

                    <input type="text" onChange={e => setgwmrg(e.target.value)} className="form-control" id="address" placeholder={gwmrg} />
                </div>
                <div className="col-md-2 ">
                    <label htmlFor="lastName" className="form-label">ژمارەی گومرگ</label>

                    <input type="number" onChange={e => setgwmnum(e.target.value)} className="form-control" id="address" placeholder={gwmnum} />
                </div>
                <div className="col-md-2 pb-2">
                    <label style={{ paddingLeft: "4px" }} htmlFor="address" className="form-label">کاتی گومرگ</label>
                    <input type="date" onChange={e => setdategwm(e.target.value)} className="form-control" id="address" />
                </div>

            </div>
        </div>





        <div className=" modal-content print-containerpdf mb-5" style={{ width: "50vw", margin: "0 auto" }} dir="rtl">
            <img className='imgp' src={img} alt='print' />

            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {customerID}<input style={{ width: "100px" }} type="text" placeholder='ژمارەی وەصڵ' className="form-control notPrint" aria-describedby="emailHelp" onChange={e => setCustomerID(e.target.value)} />
                </h5>
                <h5 className="modal-title pb-4" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {datePick} </h5>

            </div>
            <div className="modal-body" style={{ color: "black" }}>
                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <div>
                        <h4 style={{ fontSize: "22px" }}>بۆ/ بەڕێوەبەرایەتی هاتووچۆی / هەولێر - سلێمانی- دهۆک</h4>
                        <h5 style={{ textAlign: "center", fontSize: "20px" }}>ب/ گرێبەستی فرۆشتن (عقد)</h5>
                    </div>


                </div>


                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <div>
                        <div className="form-group pb-2">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم :   {tajr}</label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ژمارەی تەلەفۆن   :  {balance} </label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ناونیشان   :  هەولێر شەقامی ١٠٠ مەتری </label>
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">ناوی لایەنی دووەم :</label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{qazanj}</label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">ژمارەی تەلەفۆن : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{naql}</label>
                        </div>
                        <div className="form-group pb-3">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ناونیشانی کڕیار  : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{loc}</label>
                        </div>
                    </div>

                </div>

                <div className='docColor' style={{ padding: "5px 0px", textAlign: "center", color: "white" }}>
                    <h5>ژمارەی کاتی : ({tempNum})</h5>
                </div>
                <div style={{ padding: "5px 0px", display: 'flex', justifyContent: "space-around" }}>
                    <div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">جۆری ئۆتۆمبێل : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{carPick}</label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">ڕەنگ : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{colorPick}</label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ژمارەی لاشە : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{vinNumber}</label>
                        </div>
                    </div>
                    <img src={back} alt='print' className='backprint' />
                    <div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">مۆدێل : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{modelpick}</label>
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='pb-2' htmlFor="exampleInputEmail1">پستۆن : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2' htmlFor="exampleInputEmail1">{enginePick}</label>
                        </div>
                        <div className="form-group pb-3">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">ڕێکەوتی دەرچوون لە کۆمپانیا : </label>
                            <label style={{ fontWeight: "700", fontSize: "16px" }} className='me-2 pb-3' htmlFor="exampleInputEmail1">{datePick}</label>
                        </div>
                    </div>

                </div>

                <div className='docColor' style={{ padding: "5px 5px", textAlign: "center", color: "white" }}>
                    <h5 style={{ fontSize: "16px" }}>ئەبێت لە دوای واژوو کردنی ئەم گرێبەستە سەردانی بەڕێوەبەرایەتی هاتووچۆ بکرێت بە مەبەستی تۆمارکردنی وە ئەگەر لە ماوەی ٦٠ ڕۆژ سالیانە و تابلۆ وەرنەگیرێت سزا دەدرێت بە بڕی ١,٠٠٠,٠٠٠ یەک ملیۆن دینار</h5>
                </div>

                <div className='pb-4' style={{ paddingTop: "10px", margin: "0px 50px", letterSpacing: "1px", textAlign: "center", fontWeight: "700", fontSize: "17px" }}>
                    <p>تکایە ڕەزامەندی بفەرموون لەسەر تۆمارکردنی ئەم ئۆتۆمبێلەی کە خەسلەتەکانی دیاری کراوە بە ناوی ({qazanj}) بەپێی گرێبەستی کۆمپانیاکەمان ژمارە ({customerID}) لە ڕێکەوتی ({datePick}) وە نوسراوی بەڕێوەبەرایەتی گومرگی ({gwmrg}) ژمارە ({gwmnum}) لە ڕێکەوتی ({dategwm})</p>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

                    <div className="form-group">
                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">بەڵین و ڕەزامەندی لایەنی یەکەم</label>


                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">{tajr}</label>

                        </div>
                    </div>

                    <div className="form-group">

                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">مۆر و واژووی نوسەری کۆمپانیا</label>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">خبیر رشید احمد</label>

                        </div>
                    </div>
                    <div className="form-group">
                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">بەڵێن و ڕەزامەندی لایەنی دووەم</label>
                        <div className="form-group">
                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">{qazanj}</label>

                        </div>
                    </div>


                </div>



            </div>
            <div className="modal-footer notPrint">

                <button type="button" className="btn btn-primary" onClick={() => window.print()}>Print</button>

            </div>
        </div>



    </>
    )
}

export default Printdoc















