import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import back from "../../components/aa.png"
import img from "../../components/aa.jpg"

function ReadCar() {

    const [carlist, setCarList] = useState([])
    const [date, setDate] = useState("")
    const [customerList, setCustomerList] = useState([])
    const [customerId, setCustomerId] = useState("")
    const [ID, setID] = useState("")
    const [filterSearch, setFilterSearch] = useState("")
    const [filtertemp, setFiltertemp] = useState("")

    // const [RAND, setRAND] = useState("");
    // const [newmoney, setnewmoney] = useState("");

    // const [pays, setpays] = useState([1]);

    const [trans, settrans] = useState([]);
    // const [paydate, setpaydate] = useState("");


    useEffect(() => {
        axios.get("https://car-api.redcode.app/trans/getcar/").then((response) => {
            settrans(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    const [customerID, setCustomerID] = useState("");
    const [enginePick, setenginePick] = useState("");
    const [carPick, setcarPick] = useState("");
    const [colorPick, setcolorPick] = useState("");
    const [modelpick, setmodelpick] = useState("");
    const [gumrgPick, setgumrgPick] = useState("");
    const [vinNumber, setvinNumber] = useState("");
    const [tempNum, settempNum] = useState("");
    const [tajr, settajr] = useState("");
    const [balance, setbalance] = useState("");
    const [qazanj, setqazanj] = useState("");
    const [gwmrg, setgwmrg] = useState("");
    const [naql, setnaql] = useState("");
    const [coc, setcoc] = useState("");
    const [ixraj, setixraj] = useState("");
    const [totalUS, settotalUS] = useState("");
    const [total, settotal] = useState("");
    const [tebini, settebini] = useState("");
    const [datePick, setdatePick] = useState("");

    // const [, set] = useState("");
    // const [, set] = useState("");

    const [option, setOptions] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);


    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/cars/updatecarss", {
            customerID: customerID,
            enginePick: enginePick,
            modelpick: modelpick,
            gumrgPick: gumrgPick,
            carPick: carPick,
            colorPick: colorPick,
            vinNumber: vinNumber,
            tempNum: tempNum,
            tajr: tajr,
            balance: balance,
            qazanj: qazanj,
            gwmrg: gwmrg,
            naql: naql,
            coc: coc,
            ixraj: ixraj,
            totalUS: totalUS,
            total: total,
            tebini: tebini,
            datePick: datePick,
            id: ID
        })
            .then((res) => {
                window.location.reload(false)
            })
    }







    const updateCustomer = (id) => {
        if (customerID === "") {
            carlist.filter(e => e.ID === ID).map(e => (setCustomerID(e.customerID)))
        }
        if (enginePick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setenginePick(e.enginePick)))
        }
        if (modelpick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setmodelpick(e.modelPick)))
        }
        if (gumrgPick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setgumrgPick(e.gumrgPick)))
        }
        if (carPick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setcarPick(e.carPick)))
        }
        if (colorPick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setcolorPick(e.colorPick)))
        }
        if (vinNumber === "") {
            carlist.filter(e => e.ID === ID).map(e => (setvinNumber(e.vinNum)))
        }
        if (tempNum === "") {
            carlist.filter(e => e.ID === ID).map(e => (settempNum(e.tepNum)))
        }
        if (tajr === "") {
            carlist.filter(e => e.ID === ID).map(e => (settajr(e.tajrPrice)))
        }
        if (balance === "") {
            carlist.filter(e => e.ID === ID).map(e => (setbalance(e.balance)))
        }
        if (qazanj === "") {
            carlist.filter(e => e.ID === ID).map(e => (setqazanj(e.qazanj)))
        }
        if (gwmrg === "") {
            carlist.filter(e => e.ID === ID).map(e => (setgwmrg(e.priceUSD)))
        }
        if (naql === "") {
            carlist.filter(e => e.ID === ID).map(e => (setnaql(e.naql)))
        }
        if (coc === "") {
            carlist.filter(e => e.ID === ID).map(e => (setcoc(e.coc)))
        }
        if (ixraj === "") {
            carlist.filter(e => e.ID === ID).map(e => (setixraj(e.ixraj)))
        }
        if (totalUS === "") {
            carlist.filter(e => e.ID === ID).map(e => (settotalUS(e.totalUS)))
        }

        if (total === "") {
            carlist.filter(e => e.ID === ID).map(e => (settotal(e.total)))
        }

        if (tebini === "") {
            carlist.filter(e => e.ID === ID).map(e => (settebini(e.tebini)))
        }

        if (datePick === "") {
            carlist.filter(e => e.ID === ID).map(e => (setdatePick(e.datePick)))
        }




    }




    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
            setCustomerList(response.data)
        });
    }, []);




    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/alldetail/").then((response) => {
            setCarList(response.data)


        })

    }, [])

    // useEffect(() => {


    //     const chaki = carlist.filter(e => {
    //         return e.tepNum = filterSearch
    //     })

    //     console.log(chaki)
    // }, [filterSearch])

    const filterRows = () => {

        if (date !== "" || customerId === "") {
            axios.get(`https://car-api.redcode.app/cars/dateselect/${date}/`).then((response) => {
                setCarList(response.data)
            })
        }
        if (date === "" || customerId !== "") {
            axios.get(`https://car-api.redcode.app/cars/customerselect/${customerId}/`).then((response) => {
                setCarList(response.data)
            })
        }
        if (date !== "" || customerId !== "") {
            axios.get(`https://car-api.redcode.app/cars/bothselect/${customerId}/${date}/`).then((response) => {
                setCarList(response.data)
            })
        }
        else if (date === "" || customerId === "") {
            alert("تکایە ناو یان کڕیار هەڵبژێرە")
        }
        // axios.delete(`https://car-api.redcode.app/deletecustomer/${e.customerId}`)

    }



    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "10px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h4 className="mb-1">ڕاپۆرت ئۆتۆمبێل</h4>
                        <div className="col-md-2">

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای ژمارەی شانصی</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-2">

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای ژمارەی کاتی</label>
                            <input type="text" className="form-control" onChange={e => setFiltertemp(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "450px", paddingBottom: "10px" }}>


                            <div className="col-md-5">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => setDate(e.target.value)} className="form-control" id="address" placeholder="1234 الشارع الأول" required />
                            </div>
                            <div className="">
                                <label htmlFor="address" className="form-label">کریار هەڵبژێرە</label>
                                <div >
                                    <select className='selfil' onChange={e => setCustomerId(e.target.value)} required>
                                        <option style={{ width: "700px" }} value="">تکایە هەڵبژێرە</option>
                                        {customerList.map((e) => {
                                            return <option key={e.ID} value={e.ID}>{e.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <button type="button" style={{ marginTop: "15px" }} onClick={filterRows} className="btn btn-dark fltar">فلتەر</button>


                        </div>
                    </div>
                    <img src={img} alt='print' className='klesha' />

                    <table style={{ fontSize: "12px" }} className="table table-striped print-container ">
                        <img src={back} alt='print' className=' backprintcar' />

                        <thead className='reportsctick'>
                            <tr class="table-dark">
                                <th scope="col">#</th>
                                <th scope="col">کڕیار</th>


                                <th scope="col">جۆری سەیارە</th>

                                <th scope="col">ژمارە شانصی</th>
                                <th scope="col">ژمارە کاتی</th>
                                <th scope="col">مۆدێل</th>
                                <th scope="col">گومرگ</th>
                                <th scope="col">ڕەنگ</th>
                                <th scope="col">پستۆن</th>
                                <th scope="col">خەرجی</th>
                                <th scope="col" className="notPrint">باڵانس</th>
                                <th scope="col" className="notPrint">قازانج</th>
                                <th scope="col"> گومرگ </th>
                                <th scope="col">نقل</th>

                                <th scope="col">COC</th>
                                <th scope="col" className="notPrint">اخراج</th>
                                <th scope="col"> کۆی باڵانس و اخراجی</th>
                                <th scope="col">کۆی گشتی دۆلار</th>
                                {/* <th scope="col">کۆی واصڵ</th>
                                <th scope="col">کۆی ماوە</th> */}
                                <th scope="col">تێبینی</th>
                                <th scope="col">کات</th>
                                {/* <th scope="col" className="notPrint">پارە بدە</th> */}

                                <th scope="col" className="notPrint"></th>
                                {/* <th scope="col" className="notPrint">دۆکیومێنت</th> */}

                            </tr>

                        </thead>


                        <tbody className='tboy'>

                            {carlist.filter((e => {
                                if (filterSearch === "" && filtertemp === "") {
                                    return e
                                } if (e.vinNum.includes(filterSearch) && filtertemp === "") {
                                    return e
                                } if (e.tepNum.includes(filtertemp) && filterSearch === "") {
                                    return e
                                } if (e.tepNum.includes(filtertemp) && e.vinNum.includes(filterSearch)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr
                                // className={

                                //     e.aa === e.bb ? "table-success" : e.bb > 0 || null ? "table-warning" : "table-danger"

                                // }
                                >
                                    <th scope="row">{e.ID}</th>
                                    <td>{e.name}</td>

                                    <td>{e.carPick}</td>


                                    <td>{e.vinNum}</td>
                                    <td>{e.tepNum}</td>
                                    <td>{e.modelPick}</td>
                                    <td>{e.gumrgPick}</td>
                                    <td>{e.colorPick}</td>
                                    <td>{e.enginePick}</td>
                                    <td>{e.tajrPrice}</td>
                                    <td className="notPrint">{e.balance}</td>
                                    <td className="notPrint">{e.qazanj}</td>
                                    <td>{e.priceUSD}</td>

                                    <td>{e.naql}</td>
                                    <td>{e.coc}</td>
                                    <td className="notPrint">{e.ixraj}</td>
                                    <td>{e.balance + e.ixraj + e.qazanj}</td>
                                    <td>{e.totalUS}</td>
                                    {/* <td>{e.madfw3}</td>
                                    <td>{e.baqi}</td> */}
                                    <td>{e.tebini}</td>
                                    <td>{e.datePick}</td>

                                    {/* <td className="notPrint">
                                        {e.madfw3 === e.totalUS ? null :}
                                        <button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppay"
                                            onClick={() =>
                                                axios.get(`https://car-api.redcode.app/customer/pay/${e.RANDpay}/`).then((response) => {
                                                    setpays(response.data)
                                                })
                                            }>پارە بدە</button>


                                    </td> */}

                                    <td className="notPrint">

                                        <button type="button" className="btn btn-warning btn-sm p-1 " data-bs-toggle="modal" data-bs-target="#staticBackdroppp" onClick={() => setID(e.ID)}><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1 " data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.ID)}><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">ڕاپۆرت دەسڕیتەوە؟</h5>

                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            ڕاپۆرت ژمارە ( {ID} ) دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/cars/deletecar/${ID}`).then((res) => {
                                                            window.location.reload(false)
                                                        })}
                                                        >سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    {/* <td className="notPrint" style={{ textAlign: "center" }}>

                                        <Link to={{
                                            pathname: "/printdoc",
                                            state: {
                                                datas: e
                                            }
                                        }}>
                                            <GrDocumentDownload />
                                        </Link>

                                        <button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.ID)} >
                                            <GrDocumentDownload />
                                        </button>

                                    </td> */}

                                </tr>
                            })}



                        </tbody>




                    </table>




                    <button type="button" onClick={() => window.print()} className="btn btn-secondary">print</button>






                    {/* 
                    <div className="modal fade" id="staticBackdroppay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label>

                                            <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label>
                                            <label htmlFor="exampleInputEmail1">پارەی ماوە : {
                                                pays !== [1] ? pays[0].baqi : null
                                            }</label>
                                            <label htmlFor="exampleInputEmail1">پارەی مدفوع : {
                                                pays !== [1] ? pays[0].madfw3 : null
                                            }</label>
                                        </div>
                                        <hr className="my-4" />

                                        <div >
                                            <form>


                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1"> پارە بدە</label>
                                                    <div className='pb-3' style={{ display: "flex" }}>
                                                        <input style={{ width: "80%" }} type="number" onClick={() => setRAND(pays !== [1] ? pays[0].RANDpay : null)} className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />
                                                        <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                            onClick={() => {
                                                                axios.post("https://car-api.redcode.app/customer/createcar/", {
                                                                    carPick: pays !== [1] ? pays[0].carPick : null,
                                                                    colorPick: pays !== [1] ? pays[0].colorPick : null,
                                                                    enginePick: pays !== [1] ? pays[0].enginePick : null,
                                                                    modelpick: pays !== [1] ? pays[0].modelPick : null,
                                                                    gumrgPick: pays !== [1] ? pays[0].gumrgPick : null,
                                                                    vinNumber: pays !== [1] ? pays[0].vinNum : null,
                                                                    tempNum: pays !== [1] ? pays[0].tepNum : null,
                                                                    pricegumrg: pays !== [1] ? pays[0].priceUSD : null,
                                                                    tajr: pays !== [1] ? pays[0].tajr : null,
                                                                    balance: pays !== [1] ? pays[0].balance : null,
                                                                    qazanj: pays !== [1] ? pays[0].qazanj : null,
                                                                    naql: pays !== [1] ? pays[0].naql : null,
                                                                    coc: pays !== [1] ? pays[0].coc : null,
                                                                    tebini: pays !== [1] ? pays[0].tebini : null,
                                                                    customerID: pays !== [1] ? pays[0].customerID : null,
                                                                    datePick: pays !== [1] ? pays[0].datePick : null,
                                                                    RAND: pays !== [1] ? pays[0].RAND : null,
                                                                    ixraj: pays !== [1] ? pays[0].ixraj : null,


                                                                    total: pays !== [1] ? pays[0].total : null,
                                                                    totalUS: pays !== [1] ? pays[0].totalUS : null,


                                                                    madfw3: (newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null),
                                                                    baqi: (pays !== [1] ? pays[0].baqi : null) - (newmoney),
                                                                    RANDpay: RAND,

                                                                }).then((res) => {
                                                                    window.location.reload(false)
                                                                })
                                                            }}





                                                        >پارە بدە</button>
                                                    </div>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null)}</h5>
                                                <h5>پارەی ماوە تازە  :  {(pays !== [1] ? pays[0].baqi : null) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    < div style={{}}>
                                                        <div className="col-md-12 col-lg-12" dir="rtl">
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <h4 className="mb-1">پارەدانی پێشوو</h4>

                                                            </div>

                                                            <table className="table table-striped -container">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">کۆی گشتی دینار</th>
                                                                        <th scope="col">کۆی گشتی دۆلار</th>
                                                                        <th scope="col">مدفوع</th>
                                                                        <th scope="col">ماوە</th>

                                                                        <th scope="col">کات</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {pays.slice(0).reverse().map(e => {
                                                                        return <tr key={e.ID}>
                                                                            <th scope="row"></th>
                                                                            <td>{e.total}</td>
                                                                            <td> {e.totalUS} $</td>
                                                                            <td>{e.madfw3}</td>
                                                                            <td>{e.baqi}</td>

                                                                            <td>{e.dateSQL}</td>

                                                                        </tr>
                                                                    })}

                                                                </tbody>

                                                            </table>


                                                        </div>
                                                    </div >
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                        </div>
                    </div>

 */}







                    <div className="modal fade" id="staticBackdroppp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>کڕیار تازە کردنەوە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        {carlist.filter(e => e.ID === ID).map(e =>
                                        (
                                            <div key={e.ID}>
                                                <form>
                                                    <div className="form-group">

                                                        <div className="">
                                                            <label className='ps-2' htmlFor="customer" >کڕیار بگۆڕە</label>
                                                            <select onChange={e => setCustomerID(e.target.value)} >
                                                                <option value={e.ID}>{e.name}</option>
                                                                {customerList.map((e) => {
                                                                    return <option key={e.ID} value={e.ID}>{e.name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">

                                                        <div style={{ display: "flex", flexDirection: "row" }}>



                                                            <td>
                                                                <select name="car" onChange={e => setenginePick(e.target.value)} >
                                                                    <option value={e.enginePick}>{e.enginePick}</option>
                                                                    {option.filter(e => e.OPkey === 6).map((e) => {
                                                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select name="car" onChange={e => setmodelpick(e.target.value)} >
                                                                    <option value={e.modelPick}>{e.modelPick}</option>
                                                                    {option.filter(e => e.OPkey === 3).map((e) => {
                                                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                                    }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
                                                                </select>
                                                            </td>


                                                        </div>
                                                        <td>
                                                            <select name="car" onChange={e => setgumrgPick(e.target.value)} >
                                                                <option value={e.gumrgPick}>{e.gumrgPick}</option>
                                                                {option.filter(e => e.OPkey === 5).map((e) => {
                                                                    return <option value={e.OPvalue}>{e.OPvalue}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">جۆری سەیارە	</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setcarPick(e.target.value)} placeholder={e.carPick} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ڕەنگ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setcolorPick(e.target.value)} placeholder={e.colorPick} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ژمارە شانصی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setvinNumber(e.target.value)} placeholder={e.vinNum} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ژمارە کاتی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => settempNum(e.target.value)} placeholder={e.tepNum} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">خەرجی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => settajr(e.target.value)} placeholder={e.tajrPrice} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">باڵانس</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setbalance(e.target.value)} placeholder={e.balance} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">قازانج</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setqazanj(e.target.value)} placeholder={e.qazanj} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">گومرگ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setgwmrg(e.target.value)} placeholder={e.priceUSD} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">نقل</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setnaql(e.target.value)} placeholder={e.naql} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">COC</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setcoc(e.target.value)} placeholder={e.coc} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">اخراج</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setixraj(e.target.value)} placeholder={e.ixraj} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => settotalUS(e.target.value)} placeholder={e.totalUS} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کۆی گشتی دینار</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => settotal(e.target.value)} placeholder={e.total} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => settebini(e.target.value)} placeholder={e.tebini} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کات</label>
                                                        <input type="date" className="form-control" aria-describedby="emailHelp" onChange={e => setdatePick(e.target.value)} placeholder={e.datePick} />
                                                    </div>

                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>

                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" onClick={updateCustomer} className="btn btn-primary" >تازە کردنەوە</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>

                                </div>
                                <div className="modal-body">
                                    {carlist.filter(e => e.ID === ID).map(e =>
                                    (
                                        <div key={e.ID}>
                                            <form style={{ color: "black" }}>

                                                <div className='yousure'>
                                                    <div>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کڕیار</label>
                                                            <h2>{customerID}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">جۆری سەیارە</label>
                                                            <h2>{carPick}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ڕەنگ</label>
                                                            <h2>{colorPick}</h2>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">مۆدێل</label>
                                                            <h2>{modelpick}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">پستۆن</label>
                                                            <h2>{enginePick}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">گومرگ</label>
                                                            <h2>{gumrgPick}</h2>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ژمارە شانصی</label>
                                                            <h2>{vinNumber}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">ژمارە کاتی</label>
                                                            <h2>{tempNum}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">خەرجی</label>
                                                            <h2>{tajr}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">باڵانس</label>
                                                            <h2>{balance}</h2>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">قازانج</label>
                                                            <h2>{qazanj}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">گومرگ</label>
                                                            <h2>{gwmrg}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">نقل</label>
                                                            <h2>{naql}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">COC</label>
                                                            <h2>{coc}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">اخراج</label>
                                                            <h2>{ixraj}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کۆی گشتی دۆلار</label>
                                                            <h2>{totalUS}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کۆی گشتی دینار</label>
                                                            <h2>{total}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">تێبینی</label>
                                                            <h2>{tebini}</h2>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">کات</label>
                                                            <h2>{datePick}</h2>
                                                        </div>


                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-primary" onClick={updateQuery}>تازە کردنەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>







                </div>
            </div>

            <div className="col-md-12 col-lg-12 bg-dark text-white p-2 print-containerr" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                {/* bg-dark text-white */}


                <div className="row">

                    <h6 className="">کۆی گشتی دینار</h6>
                    {/* <small className="text-muted"> ڕۆژ و کات</small> */}

                    <span className="">{carlist.reduce((a, v) => a = a + v.total, 0)}</span>
                </div>
                <div className="row">

                    <h6 className="">کۆی گشتی دۆلار</h6>
                    {/* <small className="text-muted">١ دۆلار بەرامبەر دینار</small> */}

                    <span className="">$ {carlist.reduce((a, v) => a = a + v.totalUS, 0)}</span>
                    {/* <span className="">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span> */}
                </div>
                <div className="row">

                    <h6 className="">کۆی واصڵ</h6>

                    <span className="">$  {trans.filter((e => {
                        if (customerId === "") {
                            return e
                        } if (e.RANDID === customerId) {
                            return e
                        }
                    })).reduce((a, v) => a = a + v.amount + v.xasm, 0)}
                    </span>
                </div>

                <div className="row">

                    <h6 className="">کۆی ماوە</h6>

                    <span className="">$ {carlist.reduce((a, v) => a = a + v.totalUS, 0) - trans.filter((e => {
                        if (customerId === "") {
                            return e
                        } if (e.RANDID === customerId) {
                            return e
                        }
                    })).reduce((a, v) => a = a + v.amount + v.xasm, 0)}</span>
                </div>
                <div className="row">

                    <h6 className="">ژمارەی سەیارە </h6>

                    <span className="">
                        {carlist.length}
                    </span>
                </div>



            </div>

        </div>
    )
}

export default ReadCar

//totall all before

//     < div className = "col-md-5 col-lg-4 order-md-last -containerr" >

//         <ul className="list-group mb-3">
//             <li className="list-group-item d-flex justify-content-between lh-sm">
//                 <div>
//                     <h6 className="my-0">Today Dollar</h6>
//                     <small className="text-muted">١ دۆلار بەرامبەر دینار</small>
//                 </div>
//                 <span className="text-muted">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span>
//             </li>

//             <li className="list-group-item d-flex justify-content-between lh-sm">
//                 <div>
//                     <h6 className="my-0">Date / Time</h6>
//                     <small className="text-muted"> ڕۆژ و کات</small>
//                 </div>
//                 <span className="text-muted">{new Date().toLocaleString() + ""}</span>
//             </li>
//             <li className="list-group-item d-flex justify-content-between lh-sm">
//                 <div>
//                     <h6 className="my-0">کۆی گشتی دۆلار</h6>
//                     <small className="text-muted"></small>
//                 </div>
//                 <span className="text-muted">$ {carlist.reduce((a, v) => a = a + v.totalUS, 0)}</span>
//             </li>
//             <li className="list-group-item d-flex justify-content-between lh-sm">
//                 <div>
//                     <h6 className="my-0">کۆی ماوە</h6>
//                     <small className="text-muted"></small>
//                 </div>
//                 <span className="text-muted">$ {carlist.reduce((a, v) => a = a + v.baqi, 0)}</span>
//             </li>
//             <li className="list-group-item d-flex justify-content-between bg-light">
//                 <div className="text-success">
//                     <h6 className="my-0"> کۆی واصڵ</h6>
//                     <small>دينار</small>

//                 </div>
//                 <span className="text-success">
//                     $ {JSON.parse(carlist.reduce((a, v) => a = a + v.madfw3, 0))}
//                 </span>
//             </li>
//             <li className="list-group-item d-flex justify-content-between">
//                                 <span>مجموع (USD)</span>
//                                 <strong>$20</strong>
//                             </li>
//         </ul>

// <form className="card p-2">
//                             <div className="input-group">
//                                 <input type="text" className="form-control" placeholder="رمز ترويجي" />
//                                 <button type="submit" className="btn btn-secondary">تحقق</button>
//                             </div>
//                         </form>
//                     </div >


//// old klesha

    // < div className = "modal fade -containerpdf" id = "staticBackdrop" data - bs - backdrop="static" data - bs - keyboard="false" tabIndex = "-1" aria - labelledby="staticBackdropLabel" aria - hidden="true" >
    //     <div className="modal-dialog modal-fullscreen">
    //         <div className="modal-content">
    //             <div className="modal-header">
    //                 <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ژ / {ID}</h5>
    //                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //             </div>
    //             <div className="modal-body" style={{ color: "black" }}>
    //                 <div className="modal-body">
    //                     {carlist.filter(e => e.ID === ID).map(e =>
    //                     (
    //                         <div key={e.ID}>
    //                             <div style={{ paddingTop: "20px", textAlign: "center" }}>
    //                                 <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ڕێکەوت / {e.dateSQL}</h5>

    //                             </div>
    //                             <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
    //                                 <div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ناوی لایەنی یەکەم : کۆمپانیای مضفر</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} />
    //                                         <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ژمارەی تەلەفۆن:0750 000 00 00</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                 </div>
    //                                 <div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ناوی لایەنی دووەم :</label>
    //                                         <label htmlFor="exampleInputEmail1">{e.name}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} />
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ژمارەی تەلەفۆن : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.phonenum}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} />
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                             <div style={{ paddingTop: "20px", textAlign: "center" }}>
    //                                 <h5>ژمارەی کاتی : {e.tepNum}</h5>
    //                             </div>
    //                             <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
    //                                 <div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">جۆری ئۆتۆمبێل : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.carPick}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} />
    //                                         <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ڕەنگ : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.colorPick}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">ژمارەی لاشە : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.vinNum}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                 </div>
    //                                 <div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">مۆدێل : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.modelPick}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} />
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">پستۆن : </label>
    //                                         <label htmlFor="exampleInputEmail1">{e.enginePick}</label>
    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} />
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                             <div style={{ paddingTop: "20px", margin: "0px 130px", textAlign: "center" }}>
    //                                 <h5>ئەبێت لە دوای واژوو کردنی ئەم گرێبەستە سەردانی بەڕێوەبەرایەتی هاتووچۆ بکرێت بە مەبەستی تۆمارکردنی وە ئەگەر لە ماوەی ٦٠ ڕۆژ سالیانە و تابلۆ وەرنەگیرێت سزا دەدرێت بە بڕی ١.٠٠٠.٠٠٠ یەک ملیۆن دینار</h5>
    //                             </div>
    //                             <div style={{ paddingTop: "20px", margin: "0px 130px", letterSpacing: "1px", textAlign: "center" }}>
    //                                 <p>تکایە ڕەزامەندی بفەرموون لەسەر تۆمارکردنی ئەم ئۆتۆمبێلەی کە خەسلەتەکانی دیاری کراوە بە ناوی ({e.name}) بەپێی گرێبەستی کۆمپانیاکەمان ژمارە ({e.ID}) لە ڕێکەوتی ({e.dateSQL}) وە نوسراوی بەڕێوەبەرایەتی گومرگی حاجی ئۆمەران ژمارە (٥٥٣٢٨٣) لە ڕێکەوتی (١٤/٨/٢٠٢١)</p>
    //                             </div>
    //                             <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

    //                                 <div className="form-group">
    //                                     <label htmlFor="exampleInputEmail1">بەڵین و ڕەزامەندی لایەنی یەکەم</label>

    //                                     <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} />
    //                                     <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>

    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">کۆمپانیای مضفر</label>

    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                 </div>

    //                                 <div className="form-group">

    //                                     <label htmlFor="exampleInputEmail1">مۆر و واژووی نوسەری کۆمپانیا</label>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">خبیر رشید احمد</label>

    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                     <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} />
    //                                 </div>
    //                                 <div className="form-group">
    //                                     <label htmlFor="exampleInputEmail1">بەڵێن و ڕەزامەندی لایەنی دووەم</label>
    //                                     <div className="form-group">
    //                                         <label htmlFor="exampleInputEmail1">{e.name}</label>

    //                                         <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
    //                                     </div>
    //                                     <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} />
    //                                 </div>





    //                             </div>
    //                         </div>
    //                     ))}
    //                 </div>
    //             </div>
    //             <div className="modal-footer notPrint">
    //                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Dismiss</button>
    //                 <button type="button" className="btn btn-primary" onClick={() => window.()}>Print</button>

    //             </div>
    //         </div>
    //     </div>
    //                 </div >


