import React, { useEffect, useState } from 'react';
import axios from "axios"


function CreateCar() {

    const [carPick, setCarPick] = useState("");
    const [colorPick, setColorPick] = useState("");
    const [enginePick, setenginePick] = useState("");
    const [modelpick, setmodelpick] = useState("");
    const [gumrgPick, setgumrgPick] = useState("");
    // const [cityPick, setcityPick] = useState("");
    const [vinNumber, setvinNumber] = useState("");
    const [tempNum, settempNum] = useState("");
    // const [priceIQ, setpriceIQ] = useState("");
    const [pricegumrg, setpricegumrg] = useState(0);
    // const [gumrgLoc, setgumrgLoc] = useState("");
    const [tajr, settajr] = useState(0);
    const [balance, setbalance] = useState(0);
    const [qazanj, setqazanj] = useState(0);
    const [naql, setnaql] = useState(0);
    const [coc, setcoc] = useState(0);
    const [ixraj, setixraj] = useState(0);
    const [customerList, setCustomerList] = useState([])
    const [customerID, setCustomerID] = useState("");
    const [datePick, setDatePick] = useState("");
    const [tebini, settebini] = useState("");
    // const [total, settotal] = useState("");
    // const [madfw3, setmadfw3] = useState(0);
    // const [baqi, setbaqi] = useState("");
    // const [totalUS, settotalUS] = useState("");

    // const [, set] = useState("");
    // const [, set] = useState("");

    const [option, setOptions] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
            setCustomerList(response.data)
        });
    }, []);



    const createcar = () => {
        axios.post("https://car-api.redcode.app/customer/createcar/", {
            carPick: carPick,
            colorPick: colorPick,
            enginePick: enginePick,
            modelpick: modelpick,
            gumrgPick: gumrgPick,
            // cityPick: cityPick,
            vinNumber: vinNumber,
            tempNum: tempNum,
            // priceIQ: priceIQ,
            // priceUS: localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka",
            pricegumrg: pricegumrg,
            // gumrgLoc: gumrgLoc,
            tajr: tajr,
            balance: balance,
            qazanj: qazanj,
            naql: naql,
            coc: coc,
            customerID: customerID,
            ixraj: ixraj,
            datePick: datePick,
            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            RANDpay: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            total: localStorage.getItem("webinter") === "1" ? (pricegumrg + tajr + balance + ixraj + qazanj + naql + coc) * JSON.parse(localStorage.getItem("dinar")).toFixed(2) : "daxl bka",
            totalUS: (pricegumrg + balance + ixraj + tajr + qazanj + naql + coc),
            madfw3: "0",
            tebini: tebini,
            baqi: (pricegumrg + balance + ixraj + qazanj + tajr + naql + coc),
            // - madfw3

        }).then((res) => {
            window.location.reload(false)
            alert("سەیارە زیاد کرا")
        })

    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-11 col-lg-11" dir="rtl">
                <h4 className="mb-3">ئۆتۆمبێل زیاد بکە</h4>
                <form className="needs-validation">
                    <div className="row g-3">

                        <div className="">
                            <label htmlFor="customer" >کڕیار دیاری بکە</label>
                            <select onChange={e => setCustomerID(e.target.value)} >
                                <option value="">تکایە هەڵبژێرە</option>
                                {customerList.map((e) => {
                                    return <option key={e.ID} value={e.ID}>{e.name}</option>
                                })}
                            </select>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>



                            <td>
                                <select name="car" onChange={e => setenginePick(e.target.value)} >
                                    <option value="">پستۆن</option>
                                    {option.filter(e => e.OPkey === 6).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="car" onChange={e => setmodelpick(e.target.value)} >
                                    <option value="">مۆدێل</option>
                                    {option.filter(e => e.OPkey === 3).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
                                </select>
                            </td>

                            <td>
                                <select name="car" onChange={e => setgumrgPick(e.target.value)} >
                                    <option value="">گومرگ</option>
                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                        </div>
                        <div className="col-md-4">

                            <label htmlFor="cc-number" className="form-label"> جۆری سەیارە</label>
                            <input type="text" onChange={e => setCarPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> ڕەنگ</label>
                            <input type="text" onChange={e => setColorPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        {/* <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label"> gwmrg</label>
                            <input type="text" onChange={e => setgumrgPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div> */}
                        <div className="col-sm-4">
                            <label htmlFor="lastName" className="form-label">ژمارە شانصی</label>
                            <input otype="text" onChange={e => setvinNumber(e.target.value)} className="form-control" id="lastName" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">ژمارە کاتی</label>
                            <input type="text" onChange={e => settempNum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        {/* <div className="col-md-4">
                            <span className="text-muted"></span>
                            <label htmlFor="email" className="form-label">نرخی گومرگ دینار</label>
                            <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} onChange={e => setpriceIQ(e.target.value)} className="form-control" id="email" />
                        </div> */}
                        {/* <div className="col-md-4">
                            <span className="text-muted"></span>
                            <label htmlFor="email" className="form-label">نرخ دینار</label>
                            <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} onChange={e => setpriceIQ(e.target.value)} className="form-control" id="email" />
                        </div> */}
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">نرخی گومرگ </label>
                            <input type="number"
                                // placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka"}

                                onChange={e => setpricegumrg(+e.target.value)} className="form-control" id="address" />
                        </div>
                        {/* <div className="col-md-4">
                            <label htmlFor="address" className="form-label">گومرگ</label>
                            <input type="number" onChange={e => setgumrgLoc(e.target.value)} className="form-control" id="address" />

                        </div> */}
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">خەرجی</label>
                            <input type="number" onChange={e => settajr(+e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">باڵانس</label>
                            <input type="number" onChange={e => setbalance(+e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">قازانج</label>
                            <input type="number" onChange={e => setqazanj(+e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">گواستنەوە(نقل)</label>
                            <input type="number" onChange={e => setnaql(+e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">COC</label>
                            <input type="number" onChange={e => setcoc(+e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">اخراج</label>
                            <input type="number" onChange={e => setixraj(+e.target.value)} className="form-control" id="address" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">كات</label>
                            <input type="date" onChange={e => setDatePick(e.target.value)} className="form-control" id="address" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">کۆی گشتی دینار</label>
                            <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? (pricegumrg + tajr + balance + ixraj + qazanj + naql + coc) * JSON.parse(localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} className="form-control" id="address" disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">کۆی گشتی دۆلار</label>
                            <input type="number" placeholder={(pricegumrg + balance + ixraj + tajr + qazanj + naql + coc)} className="form-control" id="address" disabled />
                        </div>
                        {/* <div className="col-md-4">
                            <label htmlFor="address" className="form-label">مدفوع</label>
                            <input type="number" onChange={e => setmadfw3(+e.target.value)} className="form-control" id="address" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">
                                پارەی ماوە</label>
                            <input type="number" placeholder={(pricegumrg + balance + ixraj + qazanj + tajr + naql + coc) - madfw3} className="form-control" id="address" disabled />
                        </div> */}
                        <div className="col-md-12">
                            <label htmlFor="address" className="form-label">
                                تێبینی</label>
                            <textarea type="text" className="form-control" id="address" onChange={e => settebini(e.target.value)} />
                        </div>

                        <button className="w-100 btn btn-dark btn-lg" onClick={createcar} type="button">ئۆتۆمبێل زیاد بکە</button>


                        <hr className="my-4" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateCar
