import React from 'react'
import Reportpay from './Reportpay'
// import Reportexpens from './Reportexpens'

function Report() {
    return (
        <div>
            {/* <Reportexpens /> */}
            <Reportpay />
        </div>
    )
}

export default Report
