import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
// import { GrDocumentDownload } from "react-icons/gr";
import CreateOrder from '../../components/CreateOrder';
import back from "../../components/aa.png"



function ReadCustomer() {

    const [customer, SetCustomer] = useState([])
    // const [customerId, setCustomerId] = useState("")
    // const [date, setDate] = useState('')

    const [newname, setNewname] = useState('')
    const [newcompany, setNewcompany] = useState('')
    const [newaddress, setNewaddress] = useState('')
    const [newphonenum, setNewphonenum] = useState('')
    const [newemail, setNewemail] = useState('')
    const [filterSearch, setFilterSearch] = useState("")

    const [carlist, setCarList] = useState([])

    const [randlist, setrandlist] = useState(['RANDpay'])
    const [tebini, settebini] = useState('')
    const [dashkandn, setdashkandn] = useState('')

    const [ID, setID] = useState("")

    // const [carPick, setCarPick] = useState("");
    // const [colorPick, setColorPick] = useState("");
    // const [enginePick, setenginePick] = useState("");
    // const [modelpick, setmodelpick] = useState("");
    // const [gumrgPick, setgumrgPick] = useState("");
    // const [cityPick, setcityPick] = useState("");
    // const [vinNumber, setvinNumber] = useState("");
    // const [tempNum, settempNum] = useState("");
    // const [priceIQ, setpriceIQ] = useState("");
    // const [priceUS, setpriceUS] = useState("");
    // const [gumrgLoc, setgumrgLoc] = useState("");
    // const [tajr, settajr] = useState("");
    // const [balance, setbalance] = useState("");
    // const [qazanj, setqazanj] = useState("");
    // const [naql, setnaql] = useState("");
    // const [coc, setcoc] = useState("");
    // const [ixraj, setixraj] = useState("");
    // const [customerList, setCustomerList] = useState([])
    // const [customerID, setCustomerID] = useState("");
    // const [datePick, setDatePick] = useState("");


    // const [option, setOptions] = useState([]);
    const [gumrgPick, setgumrgPick] = useState([1, 2]);
    const [newmoney, setnewmoney] = useState("");


    // const [trans, settrans] = useState([]);
    const [paydate, setpaydate] = useState("");


    const [paylist, setpaylist] = useState([]);




    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/trans/getcar/").then((response) => {
    //         settrans(response.data)
    //         // setCustomid(props.asman)
    //     });
    // }, []);



    const updateQuery = () => {
        axios.put("https://car-api.redcode.app/customer/updatecustomer/", {
            newname: newname,
            newcompany: newcompany,
            newaddress: newaddress,
            newphonenum: newphonenum,
            newemail: newemail,
            id: ID
        }).then((res) => {
            window.location.reload(false)
        })

    }

    const updateCustomer = (id) => {
        if (newname === "") {
            customer.filter(e => e.ID === ID).map(e => (setNewname(e.name)))
        }
        if (newcompany === "") {
            customer.filter(e => e.ID === ID).map(e => (setNewcompany(e.company)))
        }
        if (newaddress === "") {
            customer.filter(e => e.ID === ID).map(e => (setNewaddress(e.address)))
        }
        if (newphonenum === "") {
            customer.filter(e => e.ID === ID).map(e => (setNewphonenum(e.phonenum)))
        }
        if (newemail === "") {
            customer.filter(e => e.ID === ID).map(e => (setNewemail(e.email)))
        }

    }

    // https://car-api.redcode.app
    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
            SetCustomer(response.data)

        })

    }, [])

    // const profile = () => {
    //     setTimeout(() => {
    //         axios.get(`https://car-api.redcode.app/cars/customerselect/${ID}/`).then((response) => {
    //             setCarList(response.data)
    //         })
    //     }, 3000);

    // }

    // const filterRows = () => {

    //     if (date !== "" || customerId === "") {
    //         axios.get(`https://car-api.redcode.app/customer/dateselect/${date}/`).then((response) => {
    //             SetCustomer(response.data)
    //         })
    //     }
    //     if (date === "" || customerId !== "") {
    //         axios.get(`https://car-api.redcode.app/customer/customerselect/${customerId}/`).then((response) => {
    //             SetCustomer(response.data)
    //         })
    //     }
    //     if (date !== "" || customerId !== "") {
    //         axios.get(`https://car-api.redcode.app/customer/bothselect/${customerId}/${date}/`).then((response) => {
    //             SetCustomer(response.data)
    //         })
    //     }
    //     else if (date === "" || customerId === "") {
    //         alert("select a customer or date please")
    //     }
    //     // axios.delete(`https://car-api.redcode.app/deletecustomer/${e.customerId}`)

    // }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', padding: "20px" }}>
                <div className="col-md-12 col-lg-12" dir="rtl">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h4 className="mb-1">کڕیارەکان </h4>
                        <div className="col-md-4" style={{ padding: "0px 50px" }}>

                            <label htmlFor="cc-number" className="form-label"> گەڕان بەدوای کڕیار</label>
                            <input type="text" className="form-control" onChange={e => setFilterSearch(e.target.value)} id="cc-number" placeholder="" />
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "row" }}>

                            <div className="col-md-5">
                                <label htmlFor="address" className="form-label">کات</label>
                                <input type="date" onChange={e => setDate(e.target.value)} className="form-control" id="address" placeholder="1234 الشارع الأول" required />
                            </div>
                            <div className="">
                                <label htmlFor="address" className="form-label">کریار هەڵبژێرە</label>
                                <div >
                                    <select onChange={e => setCustomerId(e.target.value)} required>
                                        <option value="">تکایە هەڵبژێرە</option>
                                        {customer.map((e) => {
                                            return <option key={e.ID} value={e.ID}>{e.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <button type="button" onClick={filterRows} className="btn btn-dark">فلتەر</button>


                        </div> */}
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ناو</th>
                                <th scope="col">کۆمپانیا</th>
                                <th scope="col">ئیمەیڵ</th>
                                <th scope="col">ناونیشان</th>
                                <th scope="col">ژمارە مۆبایل</th>

                            </tr>
                        </thead>
                        <tbody>
                            {customer.filter((e => {
                                if (filterSearch === "") {
                                    return e
                                } else if (e.name.includes(filterSearch)) {
                                    return e
                                }
                            })).map(e => {
                                return <tr>
                                    <th scope="row">{e.ID}</th>
                                    <td>{e.name}</td>
                                    <td>{e.company}</td>
                                    <td>{e.email}</td>
                                    <td>{e.address}</td>
                                    <td>{e.phonenum}</td>
                                    <td><button type="button" className="btn btn-primary btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdroppro" onClick={() => axios.get(`https://car-api.redcode.app/customer/customerprofileee/${e.ID}/`).then((response) => {
                                        setCarList(response.data)
                                    })}  >پڕۆفایل</button>
                                    </td>
                                    <td><button type="button" className="btn btn-success btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdropadd" onClick={() => setID(e.ID)}   >ئۆتۆمبێل زیاد بکە</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-outline-dark  p-1 notPrint" data-bs-toggle="modal" data-bs-target="#staticBackdroppayall"
                                            onClick={() => axios.get(`https://car-api.redcode.app/customer/payselect/${e.ID}/`).then((response) => {
                                                setgumrgPick(response.data)
                                            })}

                                        >پارە بدە</button>
                                    </td>
                                    <td>

                                        <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setID(e.ID)}  ><BiEditAlt /></button>
                                        <button type="button" className="btn btn-danger btn-sm p-1" data-bs-toggle="modal" data-bs-target="#exampleModalll" onClick={() => setID(e.ID)} ><AiFillDelete /></button>



                                        <div className="modal fade" id="exampleModalll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">کڕیار دەسڕیتەوە؟</h5>
                                                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>
                                                            کڕیار ژمارە {ID} دەسڕێتەوە
                                                        </h5>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/customer/deletecustomer/${ID}/`).then((res) => {
                                                            window.location.reload(false)
                                                        })}>سڕینەوە</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>




                                </tr>
                            })}

                        </tbody>
                    </table>










                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>کڕیار تازە کردنەوە</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        {customer.filter(e => e.ID === ID).map(e =>
                                        (
                                            <div key={e.ID}>
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ناو</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewname(e.target.value)} placeholder={e.name} />
                                                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">کۆمپانیا</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewcompany(e.target.value)} placeholder={e.company} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ئیمەیڵ</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewemail(e.target.value)} placeholder={e.email} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ژمارە مۆبایل</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewphonenum(e.target.value)} placeholder={e.phonenum} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">ناونیشان</label>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" onChange={e => setNewaddress(e.target.value)} placeholder={e.address} />
                                                    </div>
                                                </form>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateCustomer}>تازە کردنەوە</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{ color: "black" }} className="modal-title" id="staticBackdropLabel">دڵنیایت؟</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body">
                                    {customer.filter(e => e.ID === ID).map(e =>
                                    (
                                        <div key={e.ID}>
                                            <form style={{ color: "black" }}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ناو</label>
                                                    <h2>{newname}</h2>
                                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ئیمەیڵ</label>
                                                    <h2>{newemail}</h2>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">کۆمپانیا</label>
                                                    <h2>{newcompany}</h2>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ژمارە مۆبایل</label>
                                                    <h2>{newphonenum}</h2>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">ناونیشان</label>
                                                    <h2>{newaddress}</h2>
                                                </div>

                                            </form>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-primary" onClick={updateQuery}>تازە کردنەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="modal fade" id="staticBackdroppro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پڕۆفایلی کڕیار</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">

                                        <div style={{ width: "100%", display: "inline-grid", gridTemplateColumns: "230px 230px 230px 230px 230px", gap: "20px" }}>

                                            {carlist.filter((e => {
                                                if (filterSearch === "") {
                                                    return e
                                                } else if (e.vinNum.includes(filterSearch)) {
                                                    return e
                                                }
                                            })).map(e => {
                                                return <>
                                                    <div
                                                        className="card"
                                                        //     className={
                                                        //     e.aa === e.bb ? "card text-white bg-success" : e.bb > 0 || null ? "card text-white bg-warning" : "card text-white bg-danger"
                                                        // }
                                                        // className={
                                                        //     color.filter((en => {
                                                        //         if (en.RANDID.includes(e.RANDpay)) {
                                                        //             return en
                                                        //         }


                                                        //     })).map(eve => {
                                                        //         return eve.bb

                                                        //     }) === e.totalUS ? "card text-white bg-success" : "card text-white bg-danger"
                                                        // }
                                                        style={{ width: "15rem" }}>
                                                        <div className="card-body">
                                                            <h5 className="card-title">{e.name}</h5>
                                                            <h6 className="card-subtitle mb-2 ">{e.carPick}</h6>
                                                            <p className="card-text">{e.datePick}</p>

                                                            {/* <h1>{carlist.reduce((a, v) => a = a + v.totalUS, 0)}</h1> */}
                                                            <h1>

                                                                {/* {color.filter((en => {
                                                                    return (en.RANDID.includes(e.RAND))
                                                                })).map(eve => {
                                                                    return <>
                                                                        <h5> {eve.aa == eve.bb ? "yes" : eve.bb > 0? "zeeezz":"hich wala"}</h5>

           

                                                                        <h5>
                                                                            {eve.aa}

                                                                        </h5>
                                                                        <h5>پارەی واصڵ : {eve.bb}</h5>



                                                                    </>
                                                                })} */}

                                                            </h1>

                                                            <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdropRAND" onClick={() => axios.get(`https://car-api.redcode.app/customer/selectrand/${e.RAND}/`).then((response) => {
                                                                return setrandlist(response.data)
                                                                // , setgumrgPick(e.RAND)
                                                            })} >بینینی هەموو لیستەکە</button>

                                                        </div>
                                                    </div>

                                                </>
                                            })}
                                        </div>
                                        {/* <th scope="row">{e.ID}</th>
                                        <td>{e.name}</td>
                                        <td>{e.phonenum}</td>
                                        <td>{e.dateSQL}</td>

                                        <td>{e.priceIQD}</td>
                                        <td>{e.priceUSD}</td>
                                        <td>{e.vinNum}</td>
                                        <td>{e.carPick}</td>
                                        <td>{e.tepNum}</td>
                                        <td>{e.modelPick}</td>
                                        <td>{e.colorPick}</td>
                                        <td>{e.enginePick}</td>
                                        <td>{e.tajrPrice}</td>
                                        <td>{e.balance}</td>
                                        <td>{e.qazanj}</td>
                                        <td>{e.naql}</td>
                                        <td>{e.coc}</td>
                                        <td>{e.ixraj}</td> */}





                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    {/* <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateCustomer}>تازە کردنەوە</button> */}

                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="modal print-container" id="staticBackdropRAND" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پڕۆفایلی کڕیار</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">کڕیار</th>


                                                    <th scope="col">جۆری سەیارە</th>

                                                    <th scope="col">ژمارە شانصی</th>
                                                    <th scope="col">ژمارە کاتی</th>
                                                    <th scope="col">مۆدێل</th>
                                                    <th scope="col">گومرگ</th>
                                                    <th scope="col">ڕەنگ</th>
                                                    <th scope="col">مەکینە</th>
                                                    <th scope="col">خەرجی</th>
                                                    <th scope="col" className="notPrint">باڵانس</th>
                                                    <th scope="col" className="notPrint">قازانج</th>
                                                    <th scope="col"> گومرگ </th>
                                                    <th scope="col">نقل</th>
                                                    <th scope="col">COC</th>
                                                    <th scope="col" className="notPrint">اخراج</th>
                                                    <th scope="col"> کۆی باڵانس و اخراجی</th>
                                                    <th scope="col">کۆی گشتی دۆلار</th>
                                                    {/* <th scope="col">کۆی واصڵ</th>
                                <th scope="col">کۆی ماوە</th> */}
                                                    <th scope="col">تێبینی</th>
                                                    <th scope="col">کات</th>

                                                    {/* <th scope="col" className="notPrint"></th>
                                                    <th scope="col" className="notPrint">دۆکیومێنت</th> */}

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {randlist.map(e => {
                                                    return <tr
                                                    // className={

                                                    // e.madfw3 === e.totalUS ? "table-success" : e.madfw3 > 0 ? "table-warning" : "table-danger"

                                                    // }
                                                    >


                                                        {/* {() => setTimeout(() => {
                                                            setgumrgPick(e.RANDpay)
                                                        }, 400)} */}

                                                        {/* {console.log(randlist[0].RANDpay)} */}
                                                        {/* {console.log(gumrgPick)} */}
                                                        <th scope="row">{e.ID}</th>
                                                        <td>{e.name}</td>

                                                        <td>{e.carPick}</td>

                                                        <td>{e.vinNum}</td>
                                                        <td>{e.tepNum}</td>
                                                        <td>{e.modelPick}</td>
                                                        <td>{e.gumrgPick}</td>
                                                        <td>{e.colorPick}</td>
                                                        <td>{e.enginePick}</td>
                                                        <td>{e.tajrPrice}</td>
                                                        <td className="notPrint">{e.balance}</td>
                                                        <td className="notPrint">{e.qazanj}</td>
                                                        <td>{e.priceUSD}</td>

                                                        <td>{e.naql}</td>
                                                        <td>{e.coc}</td>
                                                        <td className="notPrint">{e.ixraj}</td>
                                                        <td>{e.balance + e.ixraj + e.qazanj}</td>
                                                        <td>{e.totalUS}</td>
                                                        {/* <td>{e.madfw3}</td>
                                    <td>{e.baqi}</td> */}
                                                        <td>{e.tebini}</td>
                                                        <td>{e.datePick}</td>



                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>




                                        {/* <th scope="row">{e.ID}</th>
                                        <td>{e.name}</td>
                                        <td>{e.phonenum}</td>
                                        <td>{e.dateSQL}</td>

                                        <td>{e.priceIQD}</td>
                                        <td>{e.priceUSD}</td>
                                        <td>{e.vinNum}</td>
                                        <td>{e.carPick}</td>
                                        <td>{e.tepNum}</td>
                                        <td>{e.modelPick}</td>
                                        <td>{e.colorPick}</td>
                                        <td>{e.enginePick}</td>
                                        <td>{e.tajrPrice}</td>
                                        <td>{e.balance}</td>
                                        <td>{e.qazanj}</td>
                                        <td>{e.naql}</td>
                                        <td>{e.coc}</td>
                                        <td>{e.ixraj}</td> */}





                                    </div>
                                    <div className="col-md-10 col-lg-10 bg-dark text-white p-2 me-3" style={{ position: "sticky", bottom: "0", display: "flex", justifyContent: "space-evenly" }}>
                                        {/* bg-dark text-white */}
                                        <div className="row">

                                            <h6 className="">کۆی گشتی دینار</h6>
                                            {/* <small className="text-muted"> ڕۆژ و کات</small> */}

                                            <span className="">{randlist.reduce((a, v) => a = a + v.total, 0)}</span>
                                        </div>
                                        <div className="row">

                                            <h6 className="">کۆی گشتی دۆلار</h6>
                                            {/* <small className="text-muted">١ دۆلار بەرامبەر دینار</small> */}

                                            <span className="">${randlist.reduce((a, v) => a = a + v.totalUS, 0)}</span>
                                            {/* <span className="">{localStorage.getItem("webinter") === "1" ? localStorage.getItem("dinar") : "daxl bka"}</span> */}
                                        </div>


                                        {/* <div className="row">

                                            <h6 className="">کۆی واصڵ</h6>
                                            <small className="text-muted"></small>

                                            <span className="">
                                                $ {trans.filter((e => {

                                                    return (e.RANDID.includes(gumrgPick))


                                                })).reduce((a, v) => a = a + v.amount, 0)}

                                                {console.log(trans.filter((e => {

                                                    return (e.RANDID.includes(randlist[0].RANDpay))


                                                })).reduce((a, v) => a = a + v.amount, 0))}



                                                $ {randlist.reduce((a, v) => a = a + v.madfw3, 0)}
                                            </span>
                                        </div> */}

                                        {/* <div className="row">

                                            <h6 className="">کۆی ماوە</h6>
                                            <small className="text-muted"></small>

                                            <span className="">$ {randlist.reduce((a, v) => a = a + v.baqi, 0) - trans.filter((e => {

                                                return (e.RANDID.includes(gumrgPick))


                                            })).reduce((a, v) => a = a + v.amount, 0)}</span>
                                        </div> */}



                                    </div>
                                    <button type="button" onClick={() => window.print()} className="btn btn-secondary notPrint m-3">print</button>
                                </div>

                                <div className="modal-footer notPrint">
                                    <button type="button" className="btn btn-secondary" data-bs-target="#staticBackdroppro" data-bs-toggle="modal">گەڕانەوە</button>
                                    {/* <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateCustomer}>تازە کردنەوە</button> */}

                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="modal fade" id="staticBackdroppayall" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>پارە بدە</h5>

                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            {/* <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label> */}

                                            {/* <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label> */}
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                                $  {gumrgPick.map(e => e.aa) - gumrgPick.map(e => e.bb) - gumrgPick.map(e => e.cc)}
                                            </label>
                                            <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                                $ {gumrgPick.map(e => e.bb + e.cc)}
                                            </label>



                                        </div>
                                        <hr className="my-4" />

                                        <div >
                                            <form>


                                                {/* {(newmoney !== "" ? JSON.parse(newmoney) : null) + (pays !== [1] ? pays[0].madfw3 : null)} <p></p>
                                                        {(pays !== [1] ? pays[0].paraLeft : null) - (newmoney)
                                                        } */}

                                                <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>

                                                    <div className='pb-3' style={{ flex: "2", display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> بڕی پارە</label>

                                                        <input style={{ width: "80%" }} type="number"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setnewmoney(e.target.value)} />

                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> کات</label>

                                                        <input style={{ width: "80%" }} type="date"
                                                            // onClick={() => setRAND(pays !== [1] ? pays[0].RAND : null)}
                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setpaydate(e.target.value)} />

                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> داشکاندن</label>

                                                        <input style={{ width: "80%" }} type="number"

                                                            className="form-control" aria-describedby="emailHelp" onChange={e => setdashkandn(e.target.value)} />

                                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1"> تێبینی</label>

                                                        <textarea style={{ width: "80%" }}

                                                            className="form-control" aria-describedby="emailHelp" onChange={e => settebini(e.target.value)} />

                                                    </div>
                                                    <button style={{ width: "20%" }} type="button" className="btn btn-dark"
                                                        onClick={() => {
                                                            axios.post("https://car-api.redcode.app/trans/gwmrgwxasm/", {
                                                                transtype: 1,
                                                                amount: newmoney,
                                                                RANDID: gumrgPick.map(e => e.customerID),
                                                                datePick: paydate,
                                                                tebini: tebini,
                                                                dashkandn: dashkandn

                                                            }).then((res) => {
                                                                window.location.reload(false)
                                                            })
                                                        }}

                                                    >پارە بدە</button>
                                                </div>


                                                <h5>پارەی باقی تازە  :  {(newmoney !== "" ? JSON.parse(newmoney) : null) + (newmoney !== "" ? JSON.parse(gumrgPick.map(e => e.bb)) : null)}
                                                </h5>
                                                <h5>پارەی ماوە تازە  :   {gumrgPick.map(e => e.aa) - gumrgPick.map(e => e.bb) - gumrgPick.map(e => e.cc) - (newmoney)}</h5>

                                                <div>
                                                    <hr className="my-4" />

                                                    <button type='button' class="btn btn-success" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" onClick={() => axios.get(`https://car-api.redcode.app/trans/paylist/${gumrgPick.map(e => e.customerID)}/`).then((response) => {
                                                        setpaylist(response.data)
                                                    })}>لیستی پارەدان ببینە</button>




                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade print-containerpay" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                        <div class="modal-dialog modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalToggleLabel2">لیستی پارەدان </h5>

                                </div>
                                <div class="modal-body " >
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        {/* <label htmlFor="exampleInputEmail1">وەصڵ : {
                                                        pays !== [1] ? pays[0].waslNum : null
                                                    }</label> */}

                                        {/* <label htmlFor="exampleInputEmail1">ناو : {
                                                        pays !== [1] ? pays[0].sarfFor : null
                                                    }</label> */}
                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی ماوە :
                                            $  {gumrgPick.map(e => e.aa) - gumrgPick.map(e => e.bb) - gumrgPick.map(e => e.cc)}
                                        </label>
                                        <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="exampleInputEmail1">کۆی گشتی مدفوع :
                                            $ {gumrgPick.map(e => e.bb + e.cc)}
                                        </label>



                                    </div>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>

                                                <th scope="col">مدفوع دۆلار</th>

                                                <th scope="col">داشکاندن</th>
                                                <th scope="col">تێبینی</th>
                                                <th scope="col">کات</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paylist.map(e => {
                                                return <tr key={e.id}>
                                                    <th scope="row"></th>
                                                    <td>{e.amount} $</td>
                                                    <td>{e.xasm} </td>
                                                    <td>{e.tebini} </td>


                                                    <td>{e.datePick}</td>
                                                    <td className="notPrint"><button type="button" className="btn btn-danger btn-sm p-1 " data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={() => setID(e.id)}><AiFillDelete /></button>


                                                    </td>



                                                </tr>
                                            })}


                                        </tbody>

                                    </table>
                                    <img src={back} alt='print' className='backprint' />

                                </div>
                                <div class="modal-footer notPrint" style={{ zIndex: "33" }}>
                                    <button type="button" className="btn btn-success" onClick={() => window.print()}>Print</button>

                                    <button class="btn btn-primary" data-bs-target="#staticBackdroppayall" data-bs-toggle="modal">گەڕانەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalToggleLabel23" aria-hidden="true">
                        <div className="modal-dialog modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalToggleLabel23">بڕی پارەدان دەسڕیتەوە؟</h5>

                                </div>
                                <div className="modal-body">
                                    <h5>

                                        بڕی پارەدان  دەسڕێتەوە
                                    </h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => axios.delete(`https://car-api.redcode.app/trans/deletetrans/${ID}`).then((res) => {
                                        window.location.reload(false)
                                    })}
                                    >سڕینەوە</button>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "black" }}>ئۆتۆمبێل زیاد بکە</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body" style={{ color: "black" }}>
                                    <div className="modal-body">


                                        <CreateOrder customerID={ID} />




                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">گەڕانەوە</button>
                                    {/* <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="btn btn-primary" onClick={updateCustomer}>تازە کردنەوە</button> */}

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ReadCustomer























    // < form className = "needs-validation" >
    //     <div className="row g-3">



    //         <div style={{ display: "flex", flexDirection: "row" }}>



    //             <td>
    //                 <select name="car" onChange={e => setenginePick(e.target.value)} >
    //                     <option value="">مەکینە</option>
    //                     {option.filter(e => e.OPkey === 6).map((e) => {
    //                         return <option value={e.OPvalue}>{e.OPvalue}</option>
    //                     })}
    //                 </select>
    //             </td>
    //             <td>
    //                 <select name="car" onChange={e => setmodelpick(e.target.value)} >
    //                     <option value="">مۆدێل</option>
    //                     {option.filter(e => e.OPkey === 3).map((e) => {
    //                         return <option value={e.OPvalue}>{e.OPvalue}</option>
    //                     }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
    //                 </select>
    //             </td>

    //             <td>
    //                 <select name="car" onChange={e => setgumrgPick(e.target.value)} >
    //                     <option value="">گومرگ</option>
    //                     {option.filter(e => e.OPkey === 5).map((e) => {
    //                         return <option value={e.OPvalue}>{e.OPvalue}</option>
    //                     })}
    //                 </select>
    //             </td>
    //         </div>
    //         <div className="col-md-1">

    //             <label htmlFor="cc-number" className="form-label"> جۆری سەیارە</label>
    //             <input type="text" onChange={e => setCarPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
    //         </div>
    //         <div className="col-md-1">



    //             <label htmlFor="cc-number" className="form-label"> ڕەنگ</label>
    //             <input type="text" onChange={e => setColorPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
    //         </div>
    //         <div className="col-sm-3">
    //             <label htmlFor="lastName" className="form-label">ژمارە شانصی</label>
    //             <input otype="text" onChange={e => setvinNumber(e.target.value)} className="form-control" id="lastName" placeholder="" />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="cc-number" className="form-label">ژمارە کاتی</label>
    //             <input type="number" onChange={e => settempNum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
    //         </div>
    //         <div className="col-md-1">

    //             <label htmlFor="email" className="form-label">نرخی گومرگ دینار</label>
    //             <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceUS * localStorage.getItem("dinar")).toFixed(2) : "daxl bka"} onChange={e => setpriceIQ(e.target.value)} className="form-control" id="email" />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">نرخی گومرگ دۆلار</label>
    //             <input type="number" placeholder={localStorage.getItem("webinter") === "1" ? JSON.parse(priceIQ / localStorage.getItem("dinar")).toFixed(2) : "daxl bka"}
    //                 onChange={e => console.log(e.target.placeholder)} className="form-control" id="address" disabled />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">خەرجی</label>
    //             <input type="number" onChange={e => settajr(e.target.value)} className="form-control" id="address" />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">باڵانس</label>
    //             <input type="number" onChange={e => setbalance(e.target.value)} className="form-control" id="address" />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">قازانج</label>
    //             <input type="number" onChange={e => setqazanj(e.target.value)} className="form-control" id="address" />

    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">گواستنەوە(نقل)</label>
    //             <input type="number" onChange={e => setnaql(e.target.value)} className="form-control" id="address" />

    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">COC</label>
    //             <input type="number" onChange={e => setcoc(e.target.value)} className="form-control" id="address" />

    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">اخراج</label>
    //             <input type="number" onChange={e => setixraj(e.target.value)} className="form-control" id="address" />
    //         </div>
    //         <div className="col-md-1">
    //             <label htmlFor="address" className="form-label">كات</label>
    //             <input type="date" onChange={e => setDatePick(e.target.value)} className="form-control" id="address" />
    //         </div>

    //         <button className="w-100 btn btn-dark btn-lg" onClick={createcar} type="button">ئۆتۆمبێل زیاد بکە</button>


    //         <hr className="my-4" />
    //     </div>
    //                                     </form >