import React, { useEffect, useState } from 'react';
import axios from "axios"

function Createwakalat() {

    const [carPick, setCarPick] = useState("");
    // const [waslnum, setwaslnum] = useState("");
    const [enginePick, setenginePick] = useState("");
    const [vinNumber, setvinNumber] = useState("");
    const [tempNum, settempNum] = useState("");
    const [qazanj, setqazanj] = useState("");
    const [coc, setcoc] = useState("");
    // const [customerList, setCustomerList] = useState([])
    const [customerID, setCustomerID] = useState("");
    const [customerphone, setCustomerphone] = useState("");
    const [customerloc, setCustomerloc] = useState("");
    const [datePick, setDatePick] = useState("");
    const [priceUS, setpriceUS] = useState("");


    const [colorPick, setcolorPick] = useState("");
    const [modelPick, setmodelPick] = useState("");
    const [makinaPick, setmakinaPick] = useState("");
    const [gwmrgPick, setgwmrgPick] = useState("");
    const [gwmrgNum, setgwmrgNum] = useState("");
    const [gwmrgDate, setgwmrgDate] = useState("");


    // const [, set] = useState("");
    // const [, set] = useState("");

    const [option, setOptions] = useState([]);


    useEffect(() => {
        axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
            setOptions(response.data)
            // setCustomid(props.asman)
        });
    }, []);

    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
    //         setCustomerList(response.data)
    //     });
    // }, []);



    const createwakalat = () => {
        axios.post("https://car-api.redcode.app/wakalat/createwakalat/", {

            customerID: customerID,
            customerphone: customerphone,
            customerloc: customerloc,
            enginePick: enginePick,

            carPick: carPick,
            vinNumber: vinNumber,
            tempNum: tempNum,
            tebini: qazanj,
            priceUS: priceUS,
            madfw3: coc,
            baqi: priceUS - coc,
            datePick: datePick,
            colorPick: colorPick,
            modelPick: modelPick,
            gwmrgPick: gwmrgPick,
            gwmrgDate: gwmrgDate,
            gwmrgNum: gwmrgNum,
            makinaPick: makinaPick,

            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),
            // cityPick: cityPick,

            // gumrgLoc: gumrgLoc,
            // naql: naql,

        }).then((res) => {
            window.location.reload(false)
            alert("پشتگیری زیاد کرا")

        })
        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)

    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-11 col-lg-11" dir="rtl">
                <h4 className="mb-3">پشتگیری زیاد بکە</h4>
                <form className="needs-validation">
                    <div className="row g-3">



                        <div style={{ display: "flex", flexDirection: "row" }}>



                            <td>
                                <select name="car" onChange={e => setenginePick(e.target.value)} >
                                    <option value="">جۆری پشتگیری</option>
                                    {option.filter(e => e.OPkey === 7).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="car" onChange={e => setmakinaPick(e.target.value)} >
                                    <option value="">پستۆن</option>
                                    {option.filter(e => e.OPkey === 6).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="car" onChange={e => setmodelPick(e.target.value)} >
                                    <option value="">مۆدێل</option>
                                    {option.filter(e => e.OPkey === 3).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    }).sort((a, b) => (a.OPvalue > b.OPvalue) ? 1 : -1)}
                                </select>
                            </td>

                            <td>
                                <select name="car" onChange={e => setgwmrgPick(e.target.value)} >
                                    <option value="">گومرگ</option>
                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                        return <option value={e.OPvalue}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>

                        </div>
                        {/* <div className="col-sm-4">
                            <label htmlFor="lastName" className="form-label">ژمارە وصڵ</label>
                            <input otype="text" onChange={e => setwaslnum(e.target.value)} className="form-control" id="lastName" placeholder="" />
                        </div> */}
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> ناوی کڕیار</label>
                            <input type="text" onChange={e => setCustomerID(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> ژمارە مۆبایل</label>
                            <input type="text" onChange={e => setCustomerphone(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> ناونیشان</label>
                            <input type="text" onChange={e => setCustomerloc(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> جۆری سەیارە</label>
                            <input type="text" onChange={e => setCarPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">



                            <label htmlFor="cc-number" className="form-label"> ڕەنگ</label>
                            <input type="text" onChange={e => setcolorPick(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-sm-4">
                            <label htmlFor="lastName" className="form-label">ژمارە شانصی</label>
                            <input otype="text" onChange={e => setvinNumber(e.target.value)} className="form-control" id="lastName" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">ژمارە سەیارە</label>
                            <input type="text" onChange={e => settempNum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">ژمارە گومرگ</label>
                            <input type="number" onChange={e => setgwmrgNum(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="cc-number" className="form-label">كات گومرگ</label>
                            <input type="date" onChange={e => setgwmrgDate(e.target.value)} className="form-control" id="cc-number" placeholder="" />
                        </div>


                        <div className="col-md-12">
                            <label htmlFor="address" className="form-label">تێبینی</label>
                            <textarea onChange={e => setqazanj(e.target.value)} className="form-control" id="address" />

                        </div>


                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کۆی گشتی دۆلار</label>
                            <input type="number"

                                onChange={e => setpriceUS(e.target.value)} className="form-control" id="address" />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کاتی دەرچوون</label>
                            <input type="date" onChange={e => setDatePick(e.target.value)} className="form-control" id="address" />
                        </div>

                        <button className="w-100 btn btn-dark btn-lg" onClick={createwakalat} type="button">پشتگیری زیاد بکە</button>


                        <hr className="my-4" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Createwakalat

