
import React, { useState } from 'react';
import axios from "axios"


function Createiran() {




    const [driver, setdriver] = useState("");
    const [drivermob, setdrivermob] = useState("");
    const [carqty, setcarqty] = useState("");




    const [priceIQ, setpriceIQ] = useState("");
    const [priceUS, setpriceUS] = useState("");
    const [datepick, setdatepick] = useState("");
    const [driverprice, setdriverprice] = useState("");


    const [coc, setcoc] = useState("");
    const [tebini, settebini] = useState("");

    // const [customerList, setCustomerList] = useState([])
    // const [customerID, setCustomerID] = useState("");

    // const [, set] = useState("");
    // const [, set] = useState("");

    // const [option, setOptions] = useState([]);


    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/getoptions/").then((response) => {
    //         setOptions(response.data)
    //         // setCustomid(props.asman)
    //     });
    // }, []);

    // useEffect(() => {
    //     axios.get("https://car-api.redcode.app/customer/allcustomer/").then((response) => {
    //         setCustomerList(response.data)
    //     });
    // }, []);



    const createBaxira = () => {
        axios.post("https://car-api.redcode.app/baxira/createiran/", {

            priceIQ: priceIQ,
            priceUS: priceUS,
            driver: driver,
            drivermob: drivermob,
            driverprice: driverprice,
            coc: coc,
            datepick: datepick,
            carqty: carqty,
            tebini: tebini,
            RAND: Math.floor(Math.random() * (999999999 - 1 + 1) + 1),



        }).then((res) => {
            window.location.reload(false)
            alert(" زیاد کرا")
        })
        // console.log(fullname)
        // console.log(company)
        // console.log(phonenum)
        // console.log(email)
        // console.log(address)
        // console.log(date)

    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: "20px" }}>
            <div className="col-md-11 col-lg-11" dir="rtl">
                <h4 className="mb-3">ئێران زیاد بکە</h4>
                <form className="needs-validation">
                    <div className="row g-3">

                        {/* <div className="">
                            <label htmlFor="customer" >کڕیار دیاری بکە</label>
                            <select onChange={e => setCustomerID(e.target.value)} >
                                <option value="">تکایە هەڵبژێرە</option>
                                {customerList.map((e) => {
                                    return <option key={e.ID} value={e.ID}>{e.name}</option>
                                })}
                            </select>
                        </div> */}

                        {/* <div style={{ display: "flex", flexDirection: "row" }}>



                            <td>
                                <select name="car" onChange={e => setenginePick(e.target.value)} >
                                    <option value="">مەکینە</option>
                                    {option.filter(e => e.OPkey === 6).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="car" onChange={e => setmodelpick(e.target.value)} >
                                    <option value="">مۆدێل</option>
                                    {option.filter(e => e.OPkey === 3).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>

                            <td>
                                <select name="car" onChange={e => setcityPick(e.target.value)} >
                                    <option value="">شار</option>
                                    {option.filter(e => e.OPkey === 5).map((e) => {
                                        return <option value={e.ID}>{e.OPvalue}</option>
                                    })}
                                </select>
                            </td>
                        </div> */}


                        <hr className="my-4" />





                        <div className="col-md-4">
                            {/* <span className="text-muted"></span> */}
                            <label htmlFor="email" className="form-label">تومەن</label>
                            <input type="number" onChange={e => setpriceIQ(e.target.value)} className="form-control" id="email" />
                        </div>
                        <div className="col-md-4">
                            {/* <span className="text-muted"></span> */}
                            <label htmlFor="email" className="form-label">دۆلار</label>
                            <input type="number" onChange={e => setpriceUS(e.target.value)} className="form-control" id="email" />
                        </div>


                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">ناوی شۆفێر</label>
                            <input type="text" onChange={e => setdriver(e.target.value)} className="form-control" id="address" />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">ژمارەی مۆبایلی شۆفێر</label>
                            <input type="number" onChange={e => setdrivermob(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">پارەی شۆفێر</label>
                            <input type="number" onChange={e => setdriverprice(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">ژمارەی سەیارە</label>
                            <input type="number" onChange={e => setcarqty(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">خسارە</label>
                            <input type="number" onChange={e => setcoc(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address" className="form-label">کات</label>
                            <input type="date" onChange={e => setdatepick(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-12">
                            <label htmlFor="address" className="form-label">تێبینی</label>
                            <textarea onChange={e => settebini(e.target.value)} className="form-control" id="address" />

                        </div>


                        {/* <h4 >کۆی باخیرە و ئێران</h4>


                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کۆی گشتی</label>
                            <input type="number" onChange={e => settotal(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">باقی (ماوە)</label>
                            <input type="number" onChange={e => setbaqi(e.target.value)} className="form-control" id="address" />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">کات</label>
                            <input type="date" onChange={e => setdatepick(e.target.value)} className="form-control" id="address" />

                        </div> */}

                        <button className="w-100 btn btn-dark btn-lg" onClick={createBaxira} type="button"> زیاد بکە</button>


                        <hr className="my-4" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Createiran
