import React, { useState } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom"
function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    let history = useHistory();
    const login = () => {
        axios.post("https://car-api.redcode.app/user/login", {
            username: username,
            password: password
        }).then((response) => {
            // console.log(response)
            if (response.data.loggedIn) {
                localStorage.setItem("loggedIn", true)
                localStorage.setItem("username", response.data.username)
                localStorage.setItem("userid", response.data.userid)
                history.push("/")
                window.location.reload();
            } else {
                setErrorMessage(response.data.message)
            }
        });
    };

    return (
        <div dir='rtl' style={{ borderRadius: "10px" }} className="position-absolute top-50 start-50 translate-middle p-3 mb-2 bg-dark text-white">
            <h3>تکایە داخڵ ببە</h3>
            {errorMessage ? <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div> : null}
            <form>
                <div className="form-outline mb-4">
                    <input type="email" id="form3Example3" className="form-control form-control-lg"
                        placeholder="ناو" onChange={e => setUsername(e.target.value)} />

                </div>
                <div className="form-outline mb-3">
                    <input type="password" id="form3Example4" className="form-control form-control-lg"
                        placeholder="پاسۆرد" onChange={e => setPassword(e.target.value)} />
                </div>

                <div className="text-center text-lg-start mt-4 pt-2">
                    <button onClick={login} type="button" className="btn btn-light btn-lg"
                    >داخڵ ببە</button>
                    {/* <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="/register"
                        className="link-primary">Register</a></p> */}
                </div>


            </form>
        </div>
    )
}

export default Login
